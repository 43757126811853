import { EDITAR_DADOS_USUARIO, EDITAR_FOTO_USUARIO } from 'redux/actions/DadosUsuarioActions';

const initialState = {
  dadosUsuario: {
    nome: undefined,
    idFoto: undefined,
    ente: undefined,
    uf: undefined,
    foto: undefined,
  },
};

function DadosUsuarioReducer(state = initialState, action) {
  switch (action.type) {
    case EDITAR_DADOS_USUARIO:
      return action.dadosUsuario;
    case EDITAR_FOTO_USUARIO:
      return { ...state, foto: action.foto };
    default:
      return state;
  }
}

export default DadosUsuarioReducer;
