import BaseService from './BaseService';

class DepoimentoService extends BaseService {
  carregarDepoimento(idDepoimento) {
    const requestInfo = {
      method: 'get',
      url: `/depoimentos/${idDepoimento}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDepoimentos(pagina, linhasPorPagina, like) {
    let url = `/depoimentos/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarDepoimento(depoimento) {
    const requestInfo = {
      method: 'post',
      url: '/depoimentos',
      data: JSON.stringify(depoimento),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerDepoimento(idDepoimento) {
    const requestInfo = {
      method: 'delete',
      url: `/depoimentos/${idDepoimento}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarSituacao(idDepoimento, situacao) {
    const requestInfo = {
      method: 'put',
      url: `/depoimentos/${idDepoimento}/atualizar/situacao/${situacao}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDepoimentosTimeline(quantidade, tamanhoPagina) {
    const requestInfo = {
      method: 'get',
      url: `/depoimentos/timeline/quantidade/${quantidade}/tamanhopagina/${tamanhoPagina}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTotalDepoimentosAceitos() {
    const requestInfo = {
      method: 'get',
      url: '/depoimentos/timeline/total',
      headers: {
        'Content-type': 'text/html',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDepoimentosInicio() {
    const requestInfo = {
      method: 'get',
      url: '/depoimentos/inicio',
      headers: {
        'Content-type': 'text/html',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DepoimentoService;
