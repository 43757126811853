import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  FormGroup,
} from 'reactstrap';

class GetStringModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      headerText: props.headerText,
      confirmButtonText: props.confirmButtonText,
      intructionText: props.intructionText,
      typedText: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({
        isOpen: nextProps.isOpen,
        headerText: nextProps.headerText,
        confirmButtonText: nextProps.confirmButtonText,
        intructionText: nextProps.intructionText,
        typedText: nextProps.value,
      });
    }
  }

  validarCampos() {
    if (this.state.typedText === '') {
      this.setState({ nomeState: 'has-danger' });
      return;
    }

    this.props.callback(this.state.typedText);

    this.setState({ typedText: '' });
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}>
        <ModalBody>
          <br />
          <Row>
            <Col md="12" className="text-center">
              <b>{this.state.headerText}</b>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={{ size: 10, offset: 1 }} className="text-center">
              <FormGroup className={`has-label ${this.state.nomeState}`}>
                <Input
                  value={this.state.typedText}
                  type="text"
                  maxLength="50"
                  onChange={
                    (event) => this.setState({ typedText: event.target.value, nomeState: '' })
                  }
                />
                {this.state.nomeState === 'has-danger' ? (
                  <label className="error">
                    Informe o nome do diretório.
                  </label>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <br />
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.setState({ typedText: '', nomeState: '' });
              this.props.callback(undefined);
            }}>
            Cancelar
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={() => this.validarCampos()}>
            {this.props.confirmButtonText}
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal>
    );
  }
}

export default GetStringModal;
