import BaseService from './BaseService';

class RegistroSituacaoMatriculaService extends BaseService {
  carregarPrimeiroRegistroSituacaoMatricula(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/registrosituacaomatricula/primeiro/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUltimoRegistroSituacaoMatricula(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/registrosituacaomatricula/ultimo/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarRegistrosSituacaoMatricula(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/registrosituacaomatricula/registros/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoMatriculaNoDia(idMatricula, data) {
    const requestInfo = {
      method: 'get',
      url: `/registrosituacaomatricula/registro/${idMatricula}/data/${data}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerRegistroSituacaoMatricula(idRegistro) {
    const requestInfo = {
      method: 'delete',
      url: `/registrosituacaomatricula/${idRegistro}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default RegistroSituacaoMatriculaService;
