import BaseService from './BaseService';

class DiretoriosService extends BaseService {
  carregarDiretorio(idDiretorio) {
    const requestInfo = {
      method: 'get',
      url: `/diretorio/${idDiretorio}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiretorios(idDiretorioPai) {
    let url = '/diretorio/pai';
    if (idDiretorioPai) {
      url += `/${idDiretorioPai}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiretoriosDoProfessor(idProfessor, idDiretorioPai) {
    let url = `/diretorio/professor/${idProfessor}/pai`;
    if (idDiretorioPai) {
      url += `/${idDiretorioPai}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarDiretorio(diretorio) {
    const requestInfo = {
      method: 'post',
      url: '/diretorio',
      data: JSON.stringify(diretorio),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarDiretorio(diretorio) {
    const requestInfo = {
      method: 'put',
      url: '/diretorio',
      data: JSON.stringify(diretorio),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerDiretorio(idDiretorio) {
    const requestInfo = {
      method: 'delete',
      url: `/diretorio/${idDiretorio}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  moverDiretorio(idDiretorio, idDiretorioDestino) {
    let url = `/diretorio/mover/diretorio/${idDiretorio}`;
    if (idDiretorioDestino) {
      url += `/destino/${idDiretorioDestino}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  moverAula(idAula, idDiretorioDestino) {
    let url = `/diretorio/mover/aula/${idAula}`;
    if (idDiretorioDestino) {
      url += `/destino/${idDiretorioDestino}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DiretoriosService;
