function getCamposAprendizagemCreche(curso, idMateria) {
  const obj = [
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos, experimentando marcas da cultura corporal nos cuidados pessoais, na dança, música, teatro, artes circenses, escuta de histórias e brincadeiras.\n\nBRINCAR utilizando criativamente o repertório da cultura corporal e do movimento.\n\nEXPLORAR amplo repertório de movimentos, gestos, olhares, produção de sons e de mímicas, descobrindo modos de ocupação e de uso do espaço com o corpo.\n\nPARTICIPAR de atividades que envolvem práticas corporais, desenvolvendo autonomia para cuidar de si.\n\nEXPRESSAR corporalmente emoções e representações tanto nas relações cotidianas como nas brincadeiras, dramatizações, danças, músicas e contação de histórias.\n\nCONHECER-SE nas diversas oportunidades de interações e explorações com seu corpo.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI01CG01) Movimentar as partes do corpo para exprimir corporalmente emoções, necessidades e desejos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '79A Expressar, por meio do corpo, de seus gestos e movimentos, desconforto quando está com a fralda suja, ansiedade, medo, afeição etc.',
              descricao2: '79B Expressar desagrado ao pegarem seus pertences, por exemplo, a chupeta, um paninho ou um brinquedo.',
            },
            {
              ordem: 1,
              descricao: '80A Fazer uso de gestos para indicar seus desejos.',
              descricao2: '80B Imitar atitudes e ações de crianças e adultos.',
            },
            {
              ordem: 2,
              descricao: '81A Movimentar-se ao ouvir uma música, imitar gestos, como, por exemplo, bater palmas quando observar alguém fazendo o mesmo, etc.',
              descricao2: '81B Participar de brincadeiras que envolvam seu corpo como instrumento artístico, por exemplo, fazer sons com o próprio corpo, batendo palmas, batendo os pés, etc.',
            },
            {
              ordem: 3,
              descricao: '82A Participar de situações de relaxamento corporal.',
              descricao2: '82B Participar de situações coletivas de dança, imitando e aprendendo novos gestos e movimentos.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI01CG02) Experimentar as possibilidades corporais nas brincadeiras e interações em ambientes acolhedores e desafiantes.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '83A Levantar o corpo ao estar deitado no chão, sentar com ou sem autonomia, ficar em pé com ou sem autonomia, engatinhar ou se arrastar pelo espaço.',
              descricao2: '83B Envolver-se em brincadeiras de: chutar bola, objetos de diferentes formas, texturas e cores; andar apoiando-se nos mobiliários; arrastar-se em busca de brinquedos; virar o corpo com a intenção de pegar um brinquedo; amassar, empilhar, montar, encaixar, mover e lançar objetos para longe.',
            },
            {
              ordem: 1,
              descricao: '84A Participar de brincadeiras de esconder-se e descobrir-se, por exemplo, cobrir e descobrir o rosto ou alguma outra parte do corpo.',
              descricao2: '84B Experimentar brincadeiras de esconder e achar objetos, bem como, entrar e sair de determinados espaços, como caixas, etc.',
            },
            {
              ordem: 2,
              descricao: '85A Explorar em situações de brincadeira objetos de diferentes espessuras, texturas e cores.',
              descricao2: '85B Vivenciar brincadeiras com água, areia, com folhas, gelatinas, etc.',
            },
            {
              ordem: 3,
              descricao: '86A Brincar segurando objetos com as mãos e os pés, passandoos de uma mão para outra ou arremessando-os.',
              descricao2: '86B Explorar suas possibilidades corporais como: rolar, pular, deitar, levantar, etc.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI01CG03) Imitar gestos e movimentos de outras crianças, adultos e animais.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '87A Movimentar o corpo ao som de uma música ou usar o corpo para explorar espaços e objetos, como por exemplo, manusear um brinquedo desconhecido, um bichinho, etc.',
              descricao2: '87B Movimentar-se livremente para explorar espaços e objetos.',
            },
            {
              ordem: 1,
              descricao: '88A Participar de brincadeiras de imitação, como, por exemplo, imitar animais fazendo sons e movimentos, imitar adultos e outras crianças realizando ações cotidianas.',
              descricao2: '88B Imitar gestos, movimentos e entonações de personagens de desenho animado ou de histórias; imitar o barulho de uma moto, carro ou caminhão; imitar ações como correr, voar, etc.',
            },
            {
              ordem: 2,
              descricao: '89A Movimentar-se ao som de música ou ao interagir com instrumentos sonoros.',
              descricao2: '89B Imitar gestos de dança com as outras crianças, bem como o gesto de cantar ou ler.',
            },
            {
              ordem: 3,
              descricao: ' ',
              descricao2: '90B Brincar de faz de conta.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI01CG04) Participar do cuidado do seu corpo e da promoção do seu bem-estar.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '91A Reconhecer as pessoas que lhe cuidam.',
              descricao2: '91B Reconhecer seus pertences e o local onde estão guardados.',
            },
            {
              ordem: 1,
              descricao: '92A Demonstrar desconforto, desprazer ou agrado ao ser trocado, alimentado, ou colocado para dormir, etc.',
              descricao2: '92B Comunicar ao adulto suas necessidades, como por exemplo, avisar que fez cocô, que quer água, ou alimentar-se.',
            },
            {
              ordem: 2,
              descricao: '93A Buscar objetos de conforto para si.',
              descricao2: '93B Consolar crianças que choram a partir de demonstrações de afeto ou compartilhando algum objeto com elas.',
            },
            {
              ordem: 3,
              descricao: '94A Solicitar colo ou o aconchego do adulto.',
              descricao2: '94B Solicitar auxílio em momentos como ao limpar-se, lavar-se, alimentar-se e vestir-se.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI01CG05) Utilizar os movimentos de preensão, encaixe e lançamento, ampliando suas possibilidades de manuseio de diferentes materiais e objetos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '95A Segurar objetos com as mãos, como por exemplo, frutas, a mamadeira, brinquedos, etc.',
              descricao2: '95B Explorar diferentes objetos quantos as suas funcionalidades, texturas e tamanhos, observandoos e manuseando-os.',
            },
            {
              ordem: 1,
              descricao: '96A Arremessar ou deixar cair objetos no chão ou em direção a uma pessoa.',
              descricao2: '96B Brincar de empilhar e encaixar objetos ou brinquedos diversos.',
            },
            {
              ordem: 2,
              descricao: '97A Coordenar progressivamente o movimento das mãos ao segurar objetos, como mamadeira, chupeta, copo, etc.',
              descricao2: '97B Participar de ações em que possam rasgar, amassar, pintar, rabiscar utilizando diferentes recursos.',
            },
            {
              ordem: 3,
              descricao: '98A Manipular objetos de diferentes tamanhos, texturas e tipos, transferindo-os de uma mão para outra, ou segurando-os juntamente com os pés; colocando objetos dentro de outros, etc.',
              descricao2: '98B Experimentar ações em que possam fazer uso de giz de cera, pincéis, pintura usando os dedos, em suportes diversos etc., como em papéis, no chão, na areia, etc.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos em situações comunicativas cotidianas, constituindo modos de pensar, imaginar, sentir, narrar, dialogar e conhecer.\n\nBRINCAR com parlendas, trava-línguas, adivinhas, memória, rodas, brincadeiras cantadas, jogos e textos de imagens, escritos e outros, ampliando o repertório das manifestações culturais da tradição local e de outras culturas, enriquecendo sua linguagem oral, corporal, musical, dramática, escrita, dentre outras.\n\nPARTICIPAR de rodas de conversa, de relatos de experiências, de contação e leitura de histórias e poesias, de construção de narrativas, da elaboração, descrição e representação de papéis no faz de conta, da exploração de materiais impressos e de variedades linguísticas, construindo diversas formas de organizar o pensamento.\n\nEXPLORAR gestos, expressões, sons da língua, rimas, imagens, textos escritos, além dos sentidos das palavras nas poesias, parlendas, canções e nos enredos de histórias, apropriando-se desses elementos para criar novas falas, enredos, histórias e escritas, convencionais ou não.\n\nEXPRESSAR sentimentos, ideias, percepções, desejos, necessidades, pontos de vista, informações, dúvidas e descobertas, utilizando múltiplas linguagens, considerando o que é comunicado pelos colegas e adultos.\n\nCONHECER-SE e reconhecer suas preferências por pessoas, brincadeiras, lugares, histórias, autores, gêneros linguísticos e seu interesse em produzir com a linguagem verbal.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI01EF01) Reconhecer quando é chamado por seu nome e reconhecer os nomes de pessoas com quem convive.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '202A Participar com expressões e gestos de brincadeiras cantadas envolvendo seu nome.',
              descricao2: '202B Reconhecer seu nome e os dos colegas nas canções e brincadeiras cantadas.',
            },
            {
              ordem: 1,
              descricao: '203A Manifestar-se quando for chamado por seu nome.',
              descricao2: '203B Manifestar-se quando é chamado por seu nome.',
            },
            {
              ordem: 2,
              descricao: '204A Reconhecer os nomes dos adultos com quem convive direcionando o olhar quando ouvir chamar por eles.',
              descricao2: '204B Reconhecer os nomes dos adultos e colegas quando chamados direcionando o olhar ou apontando para eles.',
            },
            {
              ordem: 3,
              descricao: ' ',
              descricao2: '205B Reconhecer sua foto, as dos colegas e pessoas próximas quando verbalizam seus nomes.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI01EF02) Demonstrar interesse ao ouvir a leitura de poemas e a apresentação de músicas.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '206A Responder a comandos de brincadeiras simples de interação por meio de gestos, movimentos ou balbucios.',
              descricao2: '206B Participar de brincadeiras simples de interação, associando gestos e movimentos às canções ouvidas.',
            },
            {
              ordem: 1,
              descricao: '207A Interessar-se pela escuta de acalantos, cantigas de roda, poemas e parlendas.',
              descricao2: '207B Desenvolver o prazer por escutar músicas e poemas, solicitando seus favoritos.',
            },
            {
              ordem: 2,
              descricao: ' ',
              descricao2: '208B Repetir acalantos, cantigas de roda, poemas e parlendas.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI01EF03) Demonstrar interesse ao ouvir histórias lidas ou contadas, observando ilustrações e os movimentos de leitura do adulto-leitor (modo de segurar o portador e de virar as páginas).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '209A Manifestar emoções a partir das histórias por meio de gestos, movimentos e balbucios.',
              descricao2: '209B Brincar com elementos da narrativa de histórias conhecidas imitando ações e falas de personagens.',
            },
            {
              ordem: 1,
              descricao: '210A Brincar de ler manuseando livros e apontando ilustrações.',
              descricao2: '210B Imitar comportamentos do leitor ao explorar livros.',
            },
            {
              ordem: 2,
              descricao: '211B Compartilhar com crianças e adultos seus interesses pelas histórias.',
              descricao2: '212B Conhecer um conjunto de histórias formando um repertório de histórias preferidas.',
            },
            {
              ordem: 3,
              descricao: ' ',
              descricao2: '213B Ampliar o conjunto de palavras conhecidas fazendo usos destas ao apontar ilustrações nos livros.',
            },
            {
              ordem: 4,
              descricao: '214A Ter prazer ao escutar histórias lidas, contadas com fantoches, representadas em encenações, escutadas em áudio, vídeos, etc.',
              descricao2: '214B Gostar de escutar histórias lidas, contadas com fantoches, representadas em encenações, escutadas em áudio, vídeos, etc.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI01EF04) Reconhecer elementos das ilustrações de histórias, apontando-os, a pedido do adulto-leitor.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '215A Expressar por meio de gestos, expressões faciais e balbucios as emoções e sentidos para as imagens representadas nos livros e sua relação as histórias narradas.',
              descricao2: '215B Nomear personagens ou objetos conhecidos em ilustrações e imagens de livros.',
            },
            {
              ordem: 1,
              descricao: '216A Interessar-se pelas ilustrações e imagens dos livros buscando atribuir a elas algum significado e expressando-se de diferentes formas ao interagir com a narrativa.',
              descricao2: '216B Reagir e expressar seus sentimentos e curiosidades frente à narrativa escutada relacionando-a as ilustrações.',
            },
            {
              ordem: 2,
              descricao: '217A Interagir com adultos nos acalantos, cantigas de roda, poemas e parlendas entoadas com diferentes ritmos.',
              descricao2: '217B Interessar-se por repetir acalantos, cantigas de roda, poemas e parlendas explorando o ritmo, a sonoridade e a conotação das palavras.',
            },
            {
              ordem: 3,
              descricao: '218A Participar da escuta de histórias lidas, contadas, representadas por fantoches, narradas por áudio, por encenações de dramatização ou dança, narrada com apoio de imagens.',
              descricao2: '218B Interessar-se por observar e imitar entonações, gestos, movimentos ou expressões ao participar de situações de leitura de história ou de exploração de livros.',
            },
            {
              ordem: 4,
              descricao: '219A Comunicar-se por meio da vocalização, gestos ou movimentos nas situações de leitura de história e brincadeiras cantadas.',
              descricao2: '219B Usar palavras acompanhadas de gestos para comunicar-se em situações de brincadeiras cantadas e leitura de histórias.',
            },
            {
              ordem: 5,
              descricao: '220A Observar adultos lendo histórias acompanhadas por gestos e movimentos, repetindoos ou criando-os.',
              descricao2: '220B Explorar livros buscando contar suas histórias, fazendo uso de diferentes entonações, gestos, expressões ou movimentos corporais.',
            },
            {
              ordem: 6,
              descricao: ' ',
              descricao2: '221B Usar palavras aprendidas nas histórias escutadas.',
            },
            {
              ordem: 7,
              descricao: ' ',
              descricao2: '222B Brincar com enredos, objetos ou adereços, tendo como referência histórias conhecidas.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI01EF05) Imitar as variações de entonação e gestos realizados pelos adultos ao ler histórias e ao cantar.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '223A Interagir com adultos nos acalantos, cantigas de roda, poesias e parlendas entoadas com diferentes ritmos.',
              descricao2: '223B Interessar-se por repetir acalantos, cantigas de roda, poesias e parlendas explorando o ritmo, a sonoridade e a conotação das palavras.',
            },
            {
              ordem: 1,
              descricao: '224A Participar da escuta de histórias lidas, contadas, representadas por fantoches, narradas por áudio, por encenações de dramatização ou dança, narrada com apoio de imagens.',
              descricao2: '224B Interessar-se por observar e imitar entonações, gestos, movimentos ou expressões ao participar de situações de leitura de história ou de exploração de livros.',
            },
            {
              ordem: 2,
              descricao: '225A Comunicar-se por meio da vocalização, gestos ou movimentos nas situações de leitura de história e brincadeiras cantadas.',
              descricao2: '225B Usar palavras acompanhadas de gestos para comunicar-se em situações de brincadeiras cantadas e leitura de histórias.',
            },
            {
              ordem: 3,
              descricao: '226A Observar os professores lendo histórias acompanhadas por gestos e movimentos, repetindo-os ou criando-os.',
              descricao2: '226B Explorar livros buscando contar suas histórias, fazendo uso de diferentes entonações, gestos, expressões ou movimentos corporais.',
            },
            {
              ordem: 4,
              descricao: ' ',
              descricao2: '227B Usar palavras aprendidas nas histórias escutadas.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI01EF06) Comunicar-se com outras pessoas usando movimentos, gestos, balbucios, fala e outras formas de expressão.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '228A Esconder parte do corpo e/ou objetos e ter prazer ao encontrar, envolvendo-se em situações de dar e receber, esconder e encontrar.',
              descricao2: '228B Interagir em situações de brincadeiras por meio de gestos e movimentos.',
            },
            {
              ordem: 1,
              descricao: '229A Expressar-se por meio de gestos, como, dizer “sim” ou “não” balançando a cabeça ou dar tchau balançando a mão.',
              descricao2: '229B Fazer uso de palavras ou frases que possam comunicar uma ideia, uma intenção ou uma necessidade.',
            },
            {
              ordem: 2,
              descricao: '230A Expressar seus desejos, ideias e necessidades por meio de vocalização, balbucios, gestos e movimentos.',
              descricao2: '230B Sinalizar, por meio de vocalização, balbucios, gestos, movimentos e expressões gráficas algo que deseja.',
            },
            {
              ordem: 3,
              descricao: '231A Brincar e interagir com seus pares buscando se fazer comunicar.',
              descricao2: '231B Interessar-se por comunicarse com professores e colegas fazendo uso de diferentes formas de expressão e buscando se fazer entender.',
            },
            {
              ordem: 4,
              descricao: '232A Responder a uma solicitação ou gesto intencional de comunicação, fazendo uso de diferentes formas de expressão.',
              descricao2: '232B Responder a uma solicitação ou gesto intencional de comunicação, fazendo uso de diferentes formas de expressão.',
            },
          ],
        },
        {
          ordem: 6,
          descricao: '(EI01EF07) Conhecer e manipular materiais impressos e audiovisuais em diferentes portadores (livro, revista, gibi, jornal, cartaz, CD, tablet, etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '233A Participar de leitura e momentos de exploração livre de livros, poemas e parlendas.',
              descricao2: '233B Explorar diferentes tipos de materiais impressos imitando ações e comportamentos típicos de um leitor, como virar a página, apontar as imagens, usar palavras, gestos ou vocalização na intenção de ler em voz alta o que está escrito.',
            },
            {
              ordem: 1,
              descricao: '234A Escutar músicas em aparelhos de som, computador, tablet ou smartphone.',
              descricao2: '234B Escutar músicas em aparelhos de som, computador, tablet ou smartphone.',
            },
            {
              ordem: 2,
              descricao: '235A Interessar-se pela exploração de diferentes materiais impressos e audiovisuais.',
              descricao2: '235B Explorar livremente diferentes materiais impressos e audiovisuais, como gravadores, filmadoras ou máquinas fotográficas e apreciar esses registros em suas brincadeiras.',
            },
          ],
        },
        {
          ordem: 7,
          descricao: '(EI01EF08) Participar de situações de escuta de textos em diferentes gêneros textuais (poemas, fábulas, contos, receitas, quadrinhos, anúncios etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '236A Divertir-se com a escuta de diferentes gêneros textuais, como parlendas, poemas, canções, histórias, receitas, etc.',
              descricao2: '236B Divertir-se ao escutar poemas, parlendas e canções brincando com tecidos, registrando suas preferidas por meio de fotografias, áudios, desenhos, modelagens, etc.',
            },
            {
              ordem: 1,
              descricao: ' ',
              descricao2: '237B Participar de situações sociais nas quais tenham contato com as finalidades de textos em diferentes gêneros, como fazer uma receita de algo para comer ou de uma tinta para misturar.',
            },
          ],
        },
        {
          ordem: 8,
          descricao: '(EI01EF09) Conhecer e manipular diferentes instrumentos e suportes de escrita.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '238A Interessar-se por explorar diferentes instrumentos e suportes de escrita em situações de brincadeira ou de pequenos grupos.',
              descricao2: '238B Explorar instrumentos e suportes de escrita em seus espaços de brincadeira, nas paredes de sua sala, nos objetos e materiais que fazem parte de seu cotidiano.',
            },
            {
              ordem: 1,
              descricao: ' ',
              descricao2: '239B Reconhecer os livros demonstrando preferência por alguns.',
            },
            {
              ordem: 2,
              descricao: ' ',
              descricao2: '240B Solicitar a leitura de um poema ou a escuta de uma canção apontando para um cartaz ou imagem.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos e com eles investigar o mundo natural e social.\n\nBRINCAR com materiais, objetos e elementos da natureza e de diferentes culturas e perceber a diversidade de formas, texturas, cheiros, cores, tamanhos, pesos e densidades que apresentam.\n\nEXPLORAR características do mundo natural e social, nomeando-as, agrupando-as e ordenando-as segundo critérios relativos às noções de espaços, tempos, quantidades, relações e transformações.\n\nPARTICIPAR de atividades de investigação de características de elementos naturais, objetos, situações, espaços, utilizando ferramentas de exploração — bússola, lanterna, lupa — e instrumentos de registro e comunicação, como máquina fotográfica, filmadora, gravador, projetor e computador.\n\nEXPRESSAR suas observações, explicações e representações sobre objetos, organismos vivos, fenômenos da natureza, características do ambiente.\n\nCONHECER-SE e construir sua identidade pessoal e cultural, reconhecendo seus interesses na relação com o mundo físico e social.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI01ET01) Explorar e descobrir as propriedades de objetos e materiais (odor, cor, sabor, temperatura).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '326A Experimentar, gradativamente, diversos alimentos com sabores, cores e texturas variados (doce, salgado, líquido, sólido, pastoso, crocante, cremoso, etc.)',
              descricao2: '326B Comunicar à professora e aos colegas, as sensações após experimentar alimentos com sabores, cores e texturas variados (doce, salgado, líquido, pastoso, sólido, cremoso, crocante, etc.).',
            },
            {
              ordem: 1,
              descricao: '327A Experimentar e alimentos em diversas temperaturas.',
              descricao2: '327B Indicar, a partir do contato, se os alimentos estão quentes, frios e gelados.',
            },
            {
              ordem: 2,
              descricao: ' ',
              descricao2: '328B Participar de refeições coletivamente.',
            },
            {
              ordem: 3,
              descricao: '329A Explorar e brincar com objetos de propriedades diferentes.',
              descricao2: '329B Explorar e brincar com objetos de propriedades diferentes.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI01ET02) Explorar relações de causa e efeito (transbordar, tingir, misturar, mover e remover etc.) na interação com o mundo físico.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '330A Brincar e observar bolhas de sabão na água, batendo com as mãos.',
              descricao2: '330B Brincar e perceber a transformação da água em bolhas de sabão.',
            },
            {
              ordem: 1,
              descricao: '331A Participar de situações onde sejam colocados e retirados objetos de determinados lugares (em cima de mesa, dentro de armários, dentro de caixas, no chão).',
              descricao2: '331B Encher e esvaziar recipientes (caixas) com água ou objetos, tais como tampas, folhas, brinquedos.',
            },
            {
              ordem: 2,
              descricao: '332A Brincar manuseando substâncias como água, areia e tintas comestíveis.',
              descricao2: '332B Envolver-se em situações onde possa manusear água, areia, farinha, promovendo uma substância diferente da original.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI01ET03) Explorar o ambiente pela ação e observação, manipulando, experimentando e fazendo descobertas.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '333A Manter contato com objetos e tipos de solo que apresentem diversas texturas como areia, argila, pedra lixa, algodão.',
              descricao2: '333B Explorar com as mãos e pés descalços, ambientes com vários tipos de solo (areia, piso com revestimento, piso molhado, etc), expressando as sensações provocadas.',
            },
            {
              ordem: 1,
              descricao: '334A Ouvir e imitar os sons produzidos pelos animais a partir da observação.',
              descricao2: '334B Indicar animais em imagens, imitando seus sons.',
            },
            {
              ordem: 2,
              descricao: '335A Observar animais de diferentes tipos e tamanhos.',
              descricao2: '335B Apontar nos animais características indicadas pelo adulto.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI01ET04) Manipular, experimentar, arrumar e explorar o espaço por meio de experiências de deslocamentos de si e dos objetos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '336A Engatinhar em busca de um objeto que não está em seu alcance.',
              descricao2: '336B Retirar objetos posicionados em lugares desafiadores (embaixo de armário, de cadeira, de lugares mais alto em que precise subir em outro suporte para alcançar o objeto).',
            },
            {
              ordem: 1,
              descricao: '337A Brincar com ajuda do adulto, em objetos que balancem, escorreguem, desçam e subam.',
              descricao2: '337B Subir, com ajuda, em brinquedos existentes no parque ou em objetos mais altos que exijam movimentos dos pés e mãos.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI01ET05) Manipular materiais diversos e variados para comparar as diferenças semelhanças entre eles.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '338A Explorar brinquedos e materiais de cores e tamanhos diversos.',
              descricao2: '338B Manipular objetos e brinquedos que possam ser encaixados e desmontados.',
            },
            {
              ordem: 1,
              descricao: '339A Retirar objetos ou brinquedos de dentro de caixas e recipientes.',
              descricao2: '339B Organizar objetos e materiais, individualmente ou em grupo, separando-os pela sua semelhança: que tem braços; que não tem cabelos.',
            },
            {
              ordem: 2,
              descricao: '340A Pegar objetos ou brinquedos a partir de uma dada indicação do adulto.',
              descricao2: '340B Juntar os objetos em caixas, com categorias diferentes.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI01ET06) Vivenciar diferentes ritmos, velocidades e fluxos nas interações e brincadeiras (em danças, balanços, escorregadores etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '341A Aprender a silenciar para ouvir os sons.',
              descricao2: '341B Identificar a voz de colegas e adultos ao seu redor.',
            },
            {
              ordem: 1,
              descricao: '342A Ouvir canções com ritmo intenso e com ritmo lento.',
              descricao2: '342B Acompanhar ritmo de músicas variadas, realizando movimentos com o corpo.',
            },
            {
              ordem: 2,
              descricao: '343A Acompanhar com palmas os ritmos cantados pelos adultos ou ouvidos em instrumentos musicais.',
              descricao2: '343B Participar de brincadeiras envolvendo modulações de voz, melodias e percepções rítmica.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos em pequenos grupos, reconhecendo e respeitando as diferentes identidades e pertencimento étnico-racial, de gênero e religião de seus parceiros.\n\nBRINCAR com diferentes parceiros, desenvolvendo sua imaginação e solidariedade.\n\nEXPLORAR diferentes formas de interagir com parceiros diversos em situações variadas, ampliando sua noção de mundo e sua sensibilidade em relação aos outros.\n\nPARTICIPAR ativamente das situações do cotidiano, tanto daquelas ligadas ao cuidado de si e do ambiente como das relativas às atividades propostas pelo(a) professor(a).\n\nEXPRESSAR às outras crianças e/ou adultos suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões e oposições.\n\nCONHECER-SE e construir uma identidade pessoal e cultural, valorizando suas características e as das outras crianças e adultos, aprendendo a identificar e combater atitudes preconceituosas e discriminatórias',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI01EO01) Perceber que suas ações têm efeitos nas outras crianças e nos adultos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '1A Interagir por meio de movimentos simples de abrir e fechar a boca repetidamente emitindo ruídos e balbucios na tentativa de imitar adultos e crianças.',
              descricao2: '1B Brincar ao lado de outras crianças, imitando ou mostrando suas ações.',
            },
            {
              ordem: 1,
              descricao: '2A Interagir com crianças e adultos a partir de caretas, palavras e gestos com intenção de comunicar-se.',
              descricao2: '2B Interagir com crianças e adultos a partir de caretas, palavras e gestos com intenção de comunicar-se.',
            },
            {
              ordem: 2,
              descricao: '3A Envolver-se em brincadeiras de aparecer e se esconder, se esconder e aparecer.',
              descricao2: ' ',
            },
            {
              ordem: 3,
              descricao: '4A Envolver-se em jogos simples de dar e receber, lançar objetos ao chão e manifestar-se ao recebê-los de volta.',
              descricao2: '4B Realizar ações de jogar e receber objetos, percebendo o efeito de suas ações nas pessoas.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI01EO02) Perceber as possibilidades e os limites de seu corpo nas brincadeiras e interações das quais participa.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '5A Segurar objetos com a mão, levando à altura dos olhos na busca por explorá-los.',
              descricao2: '5B Experienciar ações de tocar, apertar, arremessar, balançar, carregar diferentes objetos.',
            },
            {
              ordem: 1,
              descricao: '6A Conquistar gradativamente movimentos como: virar-se sozinho, levantar a cabeça quando deitado, sentar-se, mover-se engatinhando ou rastejando, ficar em pé com apoio e andar.',
              descricao2: '6B Imitar gestos e ações cotidianas dos adultos, como por exemplo, atender ao aparelho telefônico, ligar/desligar a televisão usando o controle remoto, rabiscar no papel, etc.',
            },
            {
              ordem: 2,
              descricao: '7A Desenvolver habilidades em alcançar objetos, subir em materiais sólidos e lançar objetos em determinada direção.34A Cuidar da autoimagem a partir de sua apresentação pessoal e zelo com seus pertences.',
              descricao2: '7B Ampliar e aprimorar habilidades em alcançar objetos, subir em materiais sólidos e lançar objetos em determinada direção.',
            },
            {
              ordem: 3,
              descricao: '8A Observar as pessoas ao seu redor, bem como seu reflexo no espelho.',
              descricao2: '8B Brincar diante do espelho, observando suas expressões corporais ou imitando outras crianças e adultos.',
            },
            {
              ordem: 4,
              descricao: '9A Interessar-se por experimentar novos movimentos ao explorar objetos ou brinquedos conhecidos.',
              descricao2: '9B Vivenciar situações de brincadeira que possibilitem: andar, correr, procurar, abaixar-se, empurrar objetos, escorregar, rolar, etc.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI01EO03) Interagir com crianças da mesma faixa etária e com adultos ao explorar espaços, materiais, objetos, brinquedos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '10A Experimentar o ambiente percebendo aromas, texturas e sonoridades na companhia de outras crianças.37A Vivenciar situações de explorações diversas, com diferentes materiais e em situações de interação com outras crianças e professores(as).',
              descricao2: '10B Experimentar coletivamente brincadeiras, jogos e objetos que estimulem a percepção visual, olfativa, sonora e tátil.',
            },
            {
              ordem: 1,
              descricao: '11A Brincar em situações de exploração com seu corpo e com diferentes objetos e brinquedos.',
              descricao2: '11B Explorar materiais diversos em situações de interação com outras crianças ou adultos como: caixas, brinquedos, óculos, chapéus, instrumentos sonoros, fantasias, etc.',
            },
            {
              ordem: 2,
              descricao: '12A Explorar diversos objetos em que possa apalpar, acariciar, lamber, morder, etc.',
              descricao2: '12B Brincar com diversos objetos em que possam criar, montar, empilhar, equilibrar, desmontar, e derrubar, entre outros.',
            },
            {
              ordem: 3,
              descricao: '13A Interagir com adultos e/ou crianças por meio da imitação de expressões ou gestos em situações de brincadeira.',
              descricao2: '13B Vivenciar situações de brincadeiras em que possam imitar crianças e adultos.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI01EO04) Comunicar necessidades, desejos e emoções, utilizando gestos, balbucios, palavras.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '14A Fazer uso de gestos e sinais com intenção de alcançar, receber ou pegar algo.',
              descricao2: '14B Usar gestos e fala para manifestar desejos, necessidades e vontades.',
            },
            {
              ordem: 1,
              descricao: '15A Expressar-se por meio de choro, movimentos, sons, olhares, etc.',
              descricao2: '15B Demonstrar sentimentos pelas pessoas com as quais interage.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI01EO05) Reconhecer seu corpo e expressar suas sensações em momentos de alimentação, higiene, brincadeira e descanso.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '16A Observar partes do seu corpo quando nomeadas pelo adulto.',
              descricao2: '16B Identificar partes de seu corpo em situações de brincadeira, sendo capazes de nomeá-la.',
            },
            {
              ordem: 1,
              descricao: '17A Demonstrar satisfação ao ser acolhido em momentos de choro ou desconforto.',
              descricao2: '17B Vivenciar dinâmicas de troca de afeto com adultos e crianças.',
            },
            {
              ordem: 2,
              descricao: '18A Expressar desconforto ao necessitar ser trocado, alimentarse ou estar com sono.',
              descricao2: '18B Demonstrar satisfação e insatisfação ao participar de rotinas relativas à sua alimentação, banho, escovação e descanso.',
            },
            {
              ordem: 3,
              descricao: '19A Participar junto com outras crianças de refeições saborosas, momentos de descanso diário em ambiente aconchegante e silencioso, situações de banho e higiene pessoal.',
              descricao2: '19B Assumir ações simples como segurar a mamadeira, a sua fralda, esticar braços e pernas ao se vestir.',
            },
            {
              ordem: 4,
              descricao: '19A Participar junto com outras crianças de refeições saborosas, momentos de descanso diário em ambiente aconchegante e silencioso, situações de banho e higiene pessoal.',
              descricao2: '19B Assumir ações simples como segurar a mamadeira, a sua fralda, esticar braços e pernas ao se vestir.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI01EO06) Interagir com outras crianças da mesma faixa etária e com adultos, adaptando-se ao convívio social.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '21A Relacionar-se com outras crianças e adultos em situações de brincadeira.',
              descricao2: '21B Aproximar-se de colegas com quem gosta de brincar.',
            },
            {
              ordem: 1,
              descricao: '22A Demonstrar afeição a crianças e adultos, por meio de sorrisos e olhares.',
              descricao2: '22B Comunicar-se com outras crianças por meio de gestos, palavras e imitações.',
            },
            {
              ordem: 2,
              descricao: '23A Mostrar preferência em ser acolhido por pessoas conhecidas ou acalmar-se quando acolhido por seu(sua) professor(a) de referência.',
              descricao2: '23B Relacionar-se com crianças de mesma idade ou de diferentes idades, bem como com adultos.',
            },
            {
              ordem: 3,
              descricao: '24A Oferecer algum objeto ou alimento a crianças e adultos.',
              descricao2: '24B Compartilhar objetos com outras crianças.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER e fruir com os colegas e professores manifestações artísticas e culturais da sua comunidade e de outras culturas — artes plásticas, música, dança, teatro, cinema, folguedos e festas populares.\n\nBRINCAR com diferentes sons, ritmos, formas, cores, texturas, objetos e materiais, construindo cenários e indumentárias para brincadeiras de faz de conta, encenações ou para festas tradicionais.\n\nEXPLORAR variadas possibilidades de usos e combinações de materiais, substâncias, objetos e recursos tecnológicos para criar desenhos, modelagens, músicas, danças, encenações teatrais e musicais.\n\nPARTICIPAR de decisões e ações relativas à organização do ambiente (tanto o cotidiano quanto o preparado para determinados eventos), à definição de temas e à escolha de materiais a serem usados em atividades lúdicas e artísticas.\n\nEXPRESSAR suas emoções, sentimentos, necessidades e ideias cantando, dançando, esculpindo, desenhando, encenando.\n\nCONHECER-SE no contato criativo com manifestações artísticas e culturais locais e de outras comunidades.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI01TS01) Explorar sons produzidos com o próprio corpo e com objetos do ambiente.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '132A Brincar com o próprio corpo e todos os seus sentidos experimentando e produzindo sons diversos.',
              descricao2: '132B Brincar com o próprio corpo e todos os seus sentidos experimentando e produzindo sons diversos.',
            },
            {
              ordem: 1,
              descricao: '133A Brincar com as possibilidades expressivas da própria voz.',
              descricao2: '133B Experimentar sons e tons diferentes para cantar, imitando a vocalização de adultos e outras crianças.',
            },
            {
              ordem: 2,
              descricao: '134A Experimentar a produção de sons diferentes utilizando brinquedos sonoros.',
              descricao2: '134B Experimentar instrumentos de efeito sonoro na produção de sons diversos.',
            },
            {
              ordem: 3,
              descricao: '136A Escutar e responder à música com movimentos corporais.',
              descricao2: '136B Ajustar gestos ou posições de seu corpo buscando adequar-se a outras crianças ou professores(as), acompanhando o ritmo da música.',
            },
            {
              ordem: 4,
              descricao: ' ',
              descricao2: '137B Ajustar seus movimentos corporais na escuta e resposta à música experimentando movimentos rítmicos regulares.',
            },
            {
              ordem: 5,
              descricao: ' ',
              descricao2: '138B Ampliar e aprimorar suas habilidades e descobertas sobre a música e os movimentos, dançando individualmente e em pares.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI01TS02) Traçar marcas gráficas, em diferentes suportes, usando instrumentos riscantes e tintas.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '139A Realizar marcas gráficas em seu próprio corpo experimentando diferentes tipos de tintas.',
              descricao2: '139B Explorar diferentes tintas observando as marcas que deixam e as variações das intensidades das cores.',
            },
            {
              ordem: 1,
              descricao: '140A Experimentar diferentes instrumentos riscantes e tipos de tintas para traçar marcas gráficas em suportes grandes apoiados no chão.',
              descricao2: '140B Realizar marcas gráficas explorando e reconhecendo diferentes movimentos gestuais ao tentar realizá-las em diferentes suportes.',
            },
            {
              ordem: 2,
              descricao: '141A Experimentar a utilização de diferentes materiais como folhas, flores e terras de diferentes cores para desenhar e pintar em suportes coletivos.',
              descricao2: '141B Explorar diferentes materiais, como lápis e pincéis de diferentes texturas e espessuras, brochas, carvão, carimbo etc.; utilizando, tintas, água, areia, terra, argila etc.; em vários suportes gráficos, como jornal, papel, papelão, parede, chão, caixas, madeiras etc.',
            },
            {
              ordem: 3,
              descricao: '142A Participar de experiências artísticas, utilizando partes do corpo como mãos e pés para brincar produzindo marcas gráficas em suportes coletivos.',
              descricao2: '142B Participar de experiências artísticas, mesmo que rudimentares, utilizando objetos para riscar, pintar e traçar marcas em situações de autoiniciativa e de escolha.',
            },
            {
              ordem: 4,
              descricao: ' ',
              descricao2: '143B Desenvolver atitudes de cuidado com o próprio corpo e dos colegas nas experiências com tintas e instrumentos gráficos.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI01TS03) Explorar diferentes fontes sonoras e materiais para acompanhar brincadeiras cantadas, canções, músicas e melodias.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '144A Experimentar brinquedos, objetos e materiais do mundo físico e natural para produzir sons.',
              descricao2: '144B Experimentar brinquedos, objetos e materiais do mundo físico e natural para produzir sons.',
            },
            {
              ordem: 1,
              descricao: '145A Fazer sons agitando e batendo instrumentos.',
              descricao2: '145B Explorar as qualidades sonoras de objetos e instrumentos musicais diversos, como sinos, flautas, apitos, coquinhos, etc.',
            },
            {
              ordem: 2,
              descricao: '146A Responder a sons familiares com gestos e ações.',
              descricao2: '146B Explorar fontes sonoras respondendo ao virar em direção ao som quando há mais de um estímulo sonoro presente.',
            },
            {
              ordem: 3,
              descricao: '147A Brincar e divertir-se com canções relacionadas a narrativas, festas e outros acontecimentos típicos de sua cultura.',
              descricao2: '147B Brincar e divertir-se com canções relacionadas a narrativas, festas e outros acontecimentos típicos de sua cultura.',
            },
            {
              ordem: 4,
              descricao: '148A Aprender sobre os resultados de suas ações com o corpo e com os objetos na produção de sons.',
              descricao2: '148B Criar sons com o próprio corpo ou objetos/instrumentos ao escutar uma música, buscando acompanhar o seu ritmo.',
            },
            {
              ordem: 5,
              descricao: '149A Brincar imitando os adultos no acompanhamento de brincos e parlendas cantadas.',
              descricao2: '149B Apreciar brincadeiras cantadas, participando, imitando e criando gestos, explorando movimentos, fontes sonoras e materiais.',
            },
          ],
        },
      ],
    },
    
    
  ];
  return obj[curso];
}

export default getCamposAprendizagemCreche;
