/* eslint-disable max-len */
import React from 'react';

import { connect } from 'react-redux';

import {
  Row,
  Col,
  Button,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import EntesService from 'services/EntesService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';
import AnosLetivosService from 'services/AnosLetivosService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Serie from 'assets/csv/series2.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class NotaTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.state = {
      loading: true,
    };

    this.entesService = new EntesService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
    this.anosLetivosService = new AnosLetivosService();
  }

  async componentDidMount() {
    const dadosEnte = await this.entesService
      .carregarDadosEnte();

    const anoLetivo = await this.anosLetivosService
      .carregarAnoLetivo(this.props.idAnoLetivo);

    const turma = await this.turmasService
      .carregarTurma(this.props.idTurma);

    const diarios = await this.diariosService
      .carregarDiariosDaTurma(this.props.idTurma);

    const diario = diarios.find((d) => d.idTurma === this.props.idTurma);

    const matriculas = await this.diariosService
      .carregarMatriculas(diario.id);

    const professores = await this.turmasService
      .carregarProfessores(this.props.idTurma);

    const fn = (professor) => new Promise((resolve) => resolve(
      this.professorAuxiliarService
        .carregarProfessorAuxiliar(professor.usuario.id),
    ));

    const actions = professores.map(fn);

    const professorAuxiliar = await Promise.all(actions);

    professores.forEach((professor, idx) => {
      const auxiliar = professorAuxiliar[idx].auxiliar
        ? ' (Auxiliar)' : '';
      Object.assign(professor.usuario, {
        nome: `${professor.usuario.nome}${auxiliar}`,
      });
    });

    const materias = await this.diariosService
      .carregarMateriasDoProfessor(diario.id);

    materias.forEach((materia) => {
      const professoresSelecionados = professores
        .filter((professor) => professor.materia.id === materia.id);
      Object.assign(materia, { professores: professoresSelecionados });
    });

    this.setState({
      dadosEnte,
      turma,
      materias,
      diario,
      matriculas,
      loading: false,
      avaliacaoAtelie: dadosEnte.avaliacaoAtelie && parseInt(anoLetivo.ano) >= dadosEnte.avaliacaoAtelieAno && materias.some((materia) => materia.atelie),
    });
  }

  avaliarAlunos(materia) {
    const address = `${this.props.layout}/anosletivos/${this.props.idAnoLetivo}/escola/${this.props.idEscola}/turma/multiseriado/${this.props.idTurma}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/notas/${materia.id}`);
  }

  lancarHabilidadesObjetivosAprendizagemAluno(matricula) {
    if (matricula.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
      this.lancarObjetivosAprendizagemAluno(matricula);
    } else {
      this.lancarHabilidadesAluno(matricula);
    }
  }

  lancarAvaliacaoEspecialAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/${this.props.idTurma}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/avaliacao/especial/matricula/${matricula.id}`);
  }

  lancarHabilidadesAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/multiseriado/${this.props.match.params.idTurma}/diario`;
    this.props.history.push(`${address}/objetivos/habilidades/matricula/${matricula.id}`);
  }

  lancarObjetivosAprendizagemAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/multiseriado/${this.props.match.params.idTurma}/diario`;
    this.props.history.push(`${address}/objetivos/habilidades/matricula/${matricula.id}`);
  }

  avaliarAlunoAtelie(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.idEscola}`
      + `/turma/multiseriado/${this.props.idTurma}`
      + `/diario/${this.state.diario.id}`;

    this.props.history.push(`${address}/atelie/${matricula.id}`);
  }

  conteudoPagina() {
    return this.state.matriculas.length === 0 ? <Row>
      <Col className="text-center">
        <div style={{ margin: 50 }}>
          Nenhum aluno matriculado
        </div>
      </Col>
    </Row> : <Tabs>
      <TabList>
        <Tab>Alunos</Tab>
        <Tab>
          {this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
            ? 'Campos de experiências' : 'Componentes curriculares'}
        </Tab>
      </TabList>
      <TabPanel>
        <Table hover>
          <thead className="text-primary">
            <tr>
              <th style={{ width: '40%' }}>Aluno</th>
              <th style={{ width: '40%' }}>Mãe</th>
              <th className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.matriculas.map((matricula, idx) => (
                <tr key={idx}>
                  <td>{matricula.usuario.nome} {matricula.usuario.pne && <i className="fal fa-universal-access fa-lg" />} {matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matricula.situacao].label})` : ''}</td>
                  <td>{matricula.usuario.nomeMae}</td>
                  <td className="text-center">
                    {
                      ((this.state.turma.curso.serie < Serie.ENSINO_FUNDAMENTAL_4.value &&
                        this.state.turma.tipoAvaliacao === TipoAvaliacao.RELATORIO.value)
                        || (this.state.dadosEnte.avaliacaoEspecial && matricula.usuario.pne)
                        || (this.state.avaliacaoAtelie)) &&
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup={true}
                          color="default"
                          data-toggle="collapse"
                          id={`acoesAlunoDropdownMenuLink${idx}`}
                          onClick={(evt) => evt.stopPropagation()}
                          nav>
                          <Button color="defaul">
                            Opções do aluno
                          </Button>
                        </DropdownToggle>
                        <DropdownMenu aria-labelledby="acoesAlunoDropdownMenuLink" right>
                          {
                            this.state.turma.curso.serie < Serie.ENSINO_FUNDAMENTAL_4.value &&
                            this.state.turma.tipoAvaliacao === TipoAvaliacao.RELATORIO.value && <DropdownItem
                              onClick={() => this.lancarHabilidadesObjetivosAprendizagemAluno(matricula)}>
                              {matricula.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                                ? 'Objetivos de aprendizagem adquiridos' : 'Habilidades adquiridas'}
                            </DropdownItem>
                          }
                          {
                            (this.state.avaliacaoAtelie) && <DropdownItem
                              onClick={() => this.avaliarAlunoAtelie(matricula)}>
                              Avaliação ateliê
                            </DropdownItem>
                          }
                          {
                            this.state.dadosEnte.avaliacaoEspecial && matricula.usuario.pne && <DropdownItem
                              onClick={() => this.lancarAvaliacaoEspecialAluno(matricula)}>
                              PEI
                            </DropdownItem>
                          }
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </TabPanel>
      <TabPanel>
        <Row>
          <Col>
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '30%' }}>Componentes curriculares</th>
                  <th style={{ width: '45%' }}>Professores</th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.materias.map((materia, idx) => (
                    <tr key={idx}>
                      <td>{materia.nome}</td>
                      <td>
                        {materia.professores.map((professor, idx2) => (
                          <span key={idx2}>{professor.usuario.nome}<br /></span>
                        ))}
                      </td>
                      <td className="text-center">
                        {
                          !(this.state.avaliacaoAtelie && materia.atelie) && <UncontrolledDropdown>
                            <DropdownToggle
                              aria-haspopup={true}
                              color="default"
                              data-toggle="collapse"
                              id="acoesMateriaDropdownMenuLink"
                              onClick={(evt) => evt.stopPropagation()}
                              nav>
                              <Button color="defaul">
                                Opções do componente curricular<b className="caret" />
                              </Button>
                            </DropdownToggle>
                            <DropdownMenu aria-labelledby="acoesDiarioDropdownMenuLink" right>
                              <DropdownItem
                                onClick={() => this.avaliarAlunos(materia)} >
                                Lançar notas do componente curricular
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </TabPanel>
    </Tabs>
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div> : this.conteudoPagina();
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(NotaTurmaMultiseriado);
