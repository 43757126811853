/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all
* copies or substantial portions of the Software.

*/
import React from 'react';
import { NavLink } from 'react-router-dom';

import PerfectScrollbar from 'perfect-scrollbar';
import MenuPerfil from 'components/Sidebar/MenuPerfil';

import MenuPrincipal from './MenuPrincipal';

import env from 'conf/env.json';

let ps;

class Sidebar extends React.Component {
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount = () => {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <NavLink
            to={`${this.props.layout}/dashboard`}
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={
                env.SYSNAME === 'SISAEDUC' ? 
                  'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/react-logo.png' : 
                  'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/react-logo-inovapatos.png'
              } alt="react-logo" />
            </div>
          </NavLink>
          <NavLink to={`${this.props.layout}/dashboard`} className="simple-text logo-normal">
            {env.SYSNAME}
          </NavLink>

        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <MenuPerfil {...this.props}/>
          <MenuPrincipal {...this.props}/>
        </div>
      </div>
    );
  }
}

export default Sidebar;
