import BaseService from './BaseService';

class NoticiasService extends BaseService {
  carregarNoticia(idNoticia) {
    const requestInfo = {
      method: 'get',
      url: `/noticia/${idNoticia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarNoticias(pagina, linhasPorPagina, like) {
    let url = `/noticia/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarNoticiaPublica(cidade, idNoticia) {
    const requestInfo = {
      method: 'get',
      url: `/noticia/publica/${cidade}/${idNoticia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarNoticiasPublicas(cidade, pagina, linhasPorPagina, like) {
    let url = `/noticia/publicas/${cidade}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarNoticia(noticia) {
    const requestInfo = {
      method: 'post',
      url: '/noticia',
      data: JSON.stringify(noticia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarNoticia(noticia) {
    const requestInfo = {
      method: 'put',
      url: '/noticia',
      data: JSON.stringify(noticia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerNoticia(idNoticia) {
    const requestInfo = {
      method: 'delete',
      url: `/noticia/${idNoticia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  uploadArquivo(arquivo) {
    const requestInfo = {
      method: 'post',
      url: '/noticia/upload/arquivo',
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarPapeis(idNoticia) {
    const requestInfo = {
      method: 'get',
      url: `/noticia/${idNoticia}/papeis`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default NoticiasService;
