/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';

import AdaptiveTable from 'views/components/AdaptiveTable';

import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';

import SituacaoDiario from 'assets/csv/situacoes-diario.json';

class Diarios extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-diarios-professor-multiseriado`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,

      anoLetivoSelecionado: this.props.anoLetivoSelecionado,

      dadosTabela: {
        header: [['Nome', '15%'], ['Turma', '15%'], ['Escola', '20%'], ['Cursos', '20%'], ['Situação', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-left', 'text-left'],
        keys: ['nomeDiario', 'nomeTurma', 'escola_str', 'serie_str', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    };

    this.carregarTurmasMultiseriadoDoProfessorPorAnoLetivo = this.carregarTurmasMultiseriadoDoProfessorPorAnoLetivo.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.anoLetivoSelecionado !== nextProps.anoLetivoSelecionado) {
      this.setState({
        anoLetivoSelecionado: nextProps.anoLetivoSelecionado,
      });
    }
  }

  async componentDidMount() {
    await this.carregarTurmasMultiseriadoDoProfessorPorAnoLetivo(this.state.anoLetivoSelecionado,
      this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarTurmasMultiseriadoDoProfessorPorAnoLetivo(anoLetivo, pagina, linhasPorPagina, like) {
    try {
      const data = await this.turmasService
        .carregarTurmasMultiseriadoDoProfessorPorAnoLetivo(anoLetivo.value, pagina, linhasPorPagina, like);

      data.turmas.forEach((turma) => {
        turma.turmas.sort((t1, t2) => t1.curso.serie - t2.curso.serie);
      });

      const fn = (turma) => new Promise((resolve) => resolve(
        this.diariosService.carregarDiariosDaTurmaMultiseriado(turma.id),
      ));

      const actions = data.turmas.map(fn);

      const diarios = await Promise.all(actions);

      data.turmas.forEach((turma, idx) => {
        Object.assign(turma, {
          nomeDiario: 'Diário único',
          nomeTurma: turma.nome,
          escola_str: turma.escola.nome,
          serie_str: turma.turmas.map((t) => t.curso.nome).toString().replace(',', ', '),
          situacao_str: this.situacoes[diarios[idx][0].situacao].label,
        });
      });
      this.setState({
        dadosTabela: Object.assign(
          this.state.dadosTabela,
          { rows: data.turmas, total: data.total },
        ),
      });
    } catch (e) {
      if (this.umounted) return;

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar turmas cadastradas',
      });
    }
  }

  diarioSelecionado(turma) {
    this.props.history
      .push(`${this.props.layout}/anosletivos/${this.state.anoLetivoSelecionado.value}`
        + `/escola/${turma.escola.id}`
        + `/turma/multiseriado/${turma.id}/diario`);
  }

  render() {
    return (
      <>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          clickRows
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarTurmasMultiseriadoDoProfessorPorAnoLetivo(this.state.anoLetivoSelecionado,
              this.paginaSelecionada, info.rowsPerPage, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarTurmasMultiseriadoDoProfessorPorAnoLetivo(this.state.anoLetivoSelecionado,
              this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarTurmasMultiseriadoDoProfessorPorAnoLetivo(this.state.anoLetivoSelecionado,
              this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          rowCallback={(turma) => this.diarioSelecionado(turma)}
          data={this.state.dadosTabela} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Diarios);
