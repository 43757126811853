import React from 'react';

import {
  Button,
} from 'reactstrap';

import Horario from 'assets/csv/horarios.json';

class HorariosAulasFixo extends React.Component {
  constructor(props) {
    super(props);

    this.tempos = [
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
      ['Manhã 1', 'Manhã 2', 'Manhã 3', 'Manhã 4', 'Manhã 5', 'Manhã 6', 'Tarde 1', 'Tarde 2', 'Tarde 3', 'Tarde 4', 'Tarde 5', 'Tarde 6', 'Noite 1', 'Noite 2', 'Noite 3', 'Noite 4', 'Noite 5'],
    ];

    this.horarios = [
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
        Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
        Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5],
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
        Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
        Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5],
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
        Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
        Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5],
      [Horario.MANHA_1, Horario.MANHA_2, Horario.MANHA_3, Horario.MANHA_4, Horario.MANHA_5, Horario.MANHA_6,
        Horario.TARDE_1, Horario.TARDE_2, Horario.TARDE_3, Horario.TARDE_4, Horario.TARDE_5, Horario.TARDE_6,
        Horario.NOITE_1, Horario.NOITE_2, Horario.NOITE_3, Horario.NOITE_4, Horario.NOITE_5]
    ];

    this.state = {
      horariosAulas: this.props.horariosAulas,
    };
  }

  render() {
    return (
      <>
        <table className="horarios">
          <thead>
            <tr>
              <th className="td-hr"></th>
              <th>Segunda</th>
              <th>Terça</th>
              <th>Quarta</th>
              <th>Quinta</th>
              <th>Sexta</th>
              <th>Sábado</th>
            </tr>
          </thead>
          <tbody>
            {
              this.horarios[this.props.turno].map((horario, index) => <tr key={index}>
                  <td className="td-hr">{this.tempos[this.props.turno][horario.value]}</td>
                  {
                    this.state.horariosAulas[horario.value].map((horarioAula, idx) => <td key={idx} bgcolor={idx === 5 && !this.props.sabadoLetivo ? '#EEEEEE' : null}>
                      <div>
                        {
                          horarioAula && <Button
                            size="sm">
                            {horarioAula.materia.nome}
                          </Button>
                        }
                      </div>
                    </td>)
                  }
                </tr>)
            }
          </tbody>
        </table>
      </>
    );
  }
}

export default HorariosAulasFixo;
