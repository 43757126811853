import env from 'conf/env.json';

import axios from 'axios';

axios.defaults.baseURL = env.BACKEND_URL;

class BaseService {
  abortPedingRequests = () => {}

  // eslint-disable-next-line no-async-promise-executor
  doRequest = (requestConfig) => new Promise(async (resolve, reject) => {
    try {
      const response = await axios.request(requestConfig);
      if (response.status === 200) {
        if (response.headers['fresh-token']) {
          sessionStorage.setItem('auth-token', response.headers['fresh-token']);
        }
        resolve(response.data);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403 || e.response.status === 401) {
          sessionStorage.removeItem('auth-token');
          BaseService.hist.push('/login');
        }
        reject(e.response.data);
      } else {
        reject(e);
      }
    }
  })
}

export default BaseService;
