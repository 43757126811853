/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import Quill from 'views/components/Quill';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import TarefasService from 'services/TarefasService';
import UsuarioService from 'services/UsuarioService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class Tarefa extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-envios-aluno-${this.props.match.params.idTarefa}-${this.props.match.params.idAluno}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.tarefasService = new TarefasService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      erro: false,

      dadosTabela: {
        header: [['Nome', '70%'], ['Data', '20%']],
        columnAlign: ['text-left', 'text-center'],
        keys: ['nome', 'data'],
        rows: [],
        total: undefined,
      },
    };
  }

  componentWillUnmount() {
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.tarefasService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = this.props.match.params.idAnoLetivo
        ? await this.anosLetivosService.carregarAnoLetivo(this.props.match.params.idAnoLetivo) : null;

      const escola = this.props.match.params.idAnoLetivo
        ? await this.escolasService.carregarEscola(this.props.match.params.idEscola) : null;

      const turma = this.props.match.params.idAnoLetivo
        ? await this.turmasService.carregarTurma(this.props.match.params.idTurma) : null;

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      const tarefa = await this.tarefasService
        .carregarTarefa(this.props.match.params.idTarefa);

      const conteudo = await this.tarefasService
        .carregarTextoPorAluno(this.props.match.params.idTarefa,
          this.props.match.params.idAluno);

      const aluno = await this.usuarioService
        .carregarUsuario(this.props.match.params.idAluno);

      this.carregarEnviosPorAluno(this.paginaSelecionada, this.linhasPorPagina, this.like);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materia,
        tarefa,
        conteudo,
        aluno,
      });

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarEnviosPorAluno(pagina, linhasPorPagina, like) {
    try {
      const data = await this.tarefasService
        .carregarEnviosPorAluno(this.props.match.params.idTarefa,
          this.props.match.params.idAluno,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.envios, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar envios',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            {
              this.props.match.params.idAnoLetivo ? <>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${this.props.match.params.idMateria}/turmavirtual/tarefa/${this.props.match.params.idTarefa}`}>Tarefa</Link></BreadcrumbItem>
                <BreadcrumbItem active>Envios do aluno</BreadcrumbItem>
              </> : <>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais`}>Turmas Virtuais</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais/turma/${this.props.match.params.idTurma}/diario/${this.props.match.params.idDiario}/materia/${this.props.match.params.idMateria}`}>{this.state.diario.ano} - {this.state.diario.nome} - {this.state.diario.nomeTurma} - {this.state.diario.escola} - {this.state.materia.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais/turma/${this.props.match.params.idTurma}/diario/${this.props.match.params.idDiario}/materia/${this.props.match.params.idMateria}/tarefa/${this.props.match.params.idTarefa}`}>Tarefa</Link></BreadcrumbItem>
                <BreadcrumbItem active>Envios do aluno</BreadcrumbItem>
              </>
            }

          </Breadcrumb>
          <Card>
            <CardBody>
              <Tabs>
                <TabList>
                  <Tab>Informações do aluno e tarefa</Tab>
                  <Tab>Texto do aluno</Tab>
                  <Tab>Anexos do aluno</Tab>
                </TabList>
                <TabPanel>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Nome do aluno</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.aluno.nome}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>Nome da tarefa*</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.nome}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>Data de início *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.dataInicio}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>Data de fim *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.dataFim}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Descrição *</label>
                        <Input
                          disabled
                          value={this.state.tarefa.descricao}
                          type="textarea"
                          style={{ height: '350px' }} />
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col>
                      <Quill
                        readOnly
                        value={this.state.conteudo} />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <AdaptiveTable
                    selectedPage={this.paginaSelecionada}
                    rowsPerPage={this.linhasPorPagina}
                    disableActions
                    clickRows
                    rowsPerPageCallback={(info) => {
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.linhasPorPagina = info.rowsPerPage;
                      sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                      this.carregarEnviosPorAluno(
                        this.paginaSelecionada, info.rowsPerPage, this.like,
                      );
                    }}
                    likeCallback={(text) => {
                      this.like = text;
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarEnviosPorAluno(
                        this.paginaSelecionada, this.linhasPorPagina, text,
                      );
                    }}
                    paginatorCallback={(page) => {
                      this.paginaSelecionada = page;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarEnviosPorAluno(
                        this.paginaSelecionada, this.linhasPorPagina, this.like,
                      );
                    }}
                    rowCallback={(envio) => window.open(envio.url)}
                    data={this.state.dadosTabela} />
                </TabPanel>
              </Tabs>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.goBack()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Tarefa);
