import BaseService from './BaseService';

class DependenciasService extends BaseService {
  carregarDependencias(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/dependencia/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarSituacaoDependencia(idDependencia, situacao) {
    const requestInfo = {
      method: 'put',
      url: `/dependencia/${idDependencia}/situacao/${situacao}`,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DependenciasService;
