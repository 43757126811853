import React from 'react';
import {
  Modal,
  Button,
} from 'reactstrap';

import Select from 'react-select';

import metodologias from 'assets/csv/metodologias.json'

class MetodologiaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen}>
        <div align="center" style={{ paddingTop: '40px', paddingBottom: '40px' }} className="modal-body">
          <h4>Selecione a metodologia</h4>
          <Select
            noOptionsMessage={() => 'Nenhuma entrada'}
            className={'react-select primary'}
            classNamePrefix="react-select"
            value={this.state.metodologiaSelecionada}
            onChange={(event) => {
              this.setState({ metodologiaSelecionada: event });
            }}
            options={metodologias}
            placeholder="Metodologia..."
          />
          <br />
          <Button type="button" color="primary" onClick={() => this.props.closeCallback(this.state.metodologiaSelecionada)}>
            Selecionar
          </Button>
        </div>
      </Modal>
    );
  }
}

export default MetodologiaModal;
