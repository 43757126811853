import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { encode } from 'base64-arraybuffer';

class ImageCroppModal extends React.Component {
  constructor(props) {
    super(props);

    this.cropper = React.createRef();

    this.state = {
      isOpen: props.isOpen,
      src: props.src,
      crop: {
        unit: '%',
        width: 30,
        aspect: props.aspect,
      },
    };

    this.onCropComplete = this.onCropComplete.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (this.state.src !== nextProps.src) {
      this.setState({ src: nextProps.src });
    }
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
      );
      this.setState({ croppedImage: croppedImageUrl });
    }
  }

  getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          this.props.callback(null);
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(encode(reader.result));
        };
        reader.readAsArrayBuffer(blob);
      });
    });
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}>
        <ModalBody>
          <div align="center">
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={(crop) => this.setState({ crop })}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.props.callback(undefined);
            }}>
            Cancelar
          </Button>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.props.callback(this.state.croppedImage);
            }}>
            <i className="fa fa-scissors"></i>
              Recortar
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal>
    );
  }
}

export default ImageCroppModal;
