import BaseService from './BaseService';

class OcorrenciasService extends BaseService {
  carregarOcorrencia(idocorrencia) {
    const requestInfo = {
      method: 'get',
      url: `/ocorrencia/${idocorrencia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarOcorrencias(idAnoLetivo, idEscola, pagina, linhasPorPagina, like) {
    let url = `/ocorrencia/${idAnoLetivo}/escola/${idEscola}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarOcorrencia(ocorrencia) {
    const requestInfo = {
      method: 'post',
      url: '/ocorrencia',
      data: JSON.stringify(ocorrencia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarOcorrencia(ocorrencia) {
    const requestInfo = {
      method: 'put',
      url: '/ocorrencia',
      data: JSON.stringify(ocorrencia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerOcorrencia(idocorrencia) {
    const requestInfo = {
      method: 'delete',
      url: `/ocorrencia/${idocorrencia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default OcorrenciasService;
