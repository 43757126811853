import React from 'react';
import {
  Modal,
  Spinner,
} from 'reactstrap';

class LoaderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      text: props.text,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (this.state.text !== nextProps.text) {
      this.setState({ text: nextProps.text });
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}>
        <div align="center" style={{ paddingTop: '40px', paddingBottom: '40px' }} className="modal-body">
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          <br /><br />
          {this.state.text}
        </div>
      </Modal>
    );
  }
}

export default LoaderModal;
