import BaseService from './BaseService';

class DocumentosService extends BaseService {
  validarDocumento(idDocumento) {
    const requestInfo = {
      method: 'get',
      url: `/documentos/${idDocumento}`,
      headers: {
        'Content-type': 'text/html',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  validarDocumentoEnte(idEnte, idDocumento) {
    const requestInfo = {
      method: 'get',
      url: `/documentos/${idEnte}/${idDocumento}`,
      headers: {
        'Content-type': 'text/html',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DocumentosService;
