import BaseService from './BaseService';

class DiariosService extends BaseService {
  carregarAvaliacoes(idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacao/diario/${idDiario}/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAvaliacoesDoDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacao/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAvaliacoesAlfabetizacao(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacao/alfabetizacao/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAvaliacoesPorAluno(idDiario, idMateria, idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacao/diario/${idDiario}/materia/${idMateria}/aluno/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAvaliacoesAlfabetizacaoPorAluno(idDiario, idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacao/diario/${idDiario}/aluno/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarAvaliacoes(idDiario, idMateria, avaliacoes) {
    const requestInfo = {
      method: 'put',
      url: `/avaliacao/diario/${idDiario}/materia/${idMateria}/atualizar`,
      data: JSON.stringify(avaliacoes),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarAvaliacoesAlfabetizacao(idDiario, avaliacoes) {
    const requestInfo = {
      method: 'put',
      url: `/avaliacao/diario/${idDiario}`,
      data: JSON.stringify(avaliacoes),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarExerciciosAvaliacao(idAvaliacao) {
    console.log(`/avaliacao/${idAvaliacao}/exercicios`);
    const requestInfo = {
      method: 'get',
      url: `/avaliacao/${idAvaliacao}/exercicios`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DiariosService;
