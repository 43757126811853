import BaseService from './BaseService';

class RelatoriosService extends BaseService {
  carregarRelatorios(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/relatorio/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarRelatorios(idDiario, relatorios) {
    const requestInfo = {
      method: 'put',
      url: `/relatorio/diario/${idDiario}`,
      data: JSON.stringify(relatorios),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarRelatoriosTurmaMultiseriado(idTurma, relatorios) {
    const requestInfo = {
      method: 'put',
      url: `/relatorio/turma/multiseriado/${idTurma}`,
      data: JSON.stringify(relatorios),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default RelatoriosService;
