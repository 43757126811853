import React from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import AfastamentosService from 'services/AfastamentosService';

import TipoAfastamento from 'assets/csv/tipos-afastamento.json';

class Afastamentos extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-afastamentos-${props.tipo}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.tipos = [
      TipoAfastamento.LICENCA,
      TipoAfastamento.FERIAS,
    ];

    this.state = {
      afastamentoSelecionada: null,

      dadosTabela: {
        header: [['Servidor', '50%'], ['Data início', '10%'], ['Data fim', '10%'], ['Tipo', '10%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['nome', 'dataInicio', 'dataFim', 'tipo_str'],
        rows: [],
        total: undefined,
      },
    };

    this.afastamentosService = new AfastamentosService();
  }

  componentWillUnmount() {
    this.afastamentosService.abortPedingRequests();
  }

  async componentDidMount() {
    this.carregarAfastamentos(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarAfastamentos(pagina, linhasPorPagina, like) {
    try {
      const data = await this.afastamentosService
        .carregarAfastamentosPorTipo(this.props.tipo, pagina, linhasPorPagina, like);

      data.afastamentos.forEach((afastamento) => {
        Object.assign(afastamento, { nome: afastamento.servidor.nome });
        Object.assign(afastamento, { tipo_str: this.tipos[afastamento.tipo].label });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.afastamentos, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar afastamentos cadastrados',
      });
    }
  }

  editarAfastamento(afastamento) {
    this.props.history.push({
      pathname: `/gestor/afastamentos/edicao/${afastamento.id}`,
    });
  }

  confirmarRemocaoAfastamento(afastamento) {
    this.setState({ afastamentoSelecionado: afastamento, showConfirmModal: true });
  }

  async removerAfastamento() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.afastamentosService
        .removerAfastamento(this.state.afastamentoSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Afastamento removido com sucesso',
      });

      this.carregarAfastamentos(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover afastamento',
      });
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disablePrintAction
              clickRows
              editButtonCallback={(afastamento) => this.editarAfastamento(afastamento)}
              removeButtonCallback={(afastamento) => this
                .confirmarRemocaoAfastamento(afastamento)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarAfastamentos(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarAfastamentos(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarAfastamentos(this.paginaSelecionada,
                  this.linhasPorPagina, this.like);
              }}
              rowCallback={(afastamento) => this.props.history.push({ pathname: `/gestor/afastamentos/${afastamento.id}` })}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerAfastamento();
            } else {
              this.setState({ escolaSelecionada: null });
            }
          }}
          text='Confirme a exclusão do afastamento' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Afastamentos);
