import BaseService from './BaseService';

class EntesService extends BaseService {
  carregarEnte(idEnte) {
    const requestInfo = {
      method: 'get',
      url: `/ente/${idEnte}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoEnte(idEnte) {
    const requestInfo = {
      method: 'get',
      url: `/ente/situacao/${idEnte}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };
    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEnte(ente) {
    const requestInfo = {
      method: 'post',
      url: '/ente',
      data: JSON.stringify(ente),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarSituacaoEnte(ente) {
    const requestInfo = {
      method: 'post',
      url: '/ente/situacao/atualizar',
      data: JSON.stringify(ente),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDadosEnte() {
    const requestInfo = {
      method: 'get',
      url: '/ente/dados',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDadosEntePorIdEnte(idEnte) {
    const requestInfo = {
      method: 'get',
      url: `/ente/dados/${idEnte}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };
    return this.doRequest(
      requestInfo,
    );
  }

  carregarAcessosEnte(idEnte) {
    const requestInfo = {
      method: 'get',
      url: `/ente/acessos/${idEnte}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };
    return this.doRequest(
      requestInfo,
    );
  }

  carregarLimites(idEnte) {
    let url = '/ente/limites';
    if (idEnte) {
      url += `/${idEnte}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUso(idEnte) {
    let url = '/ente/uso';
    if (idEnte) {
      url += `/${idEnte}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarDadosEnte(dadosEnte) {
    const requestInfo = {
      method: 'put',
      url: '/ente/dados',
      data: JSON.stringify(dadosEnte),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFoto(idDadosEnte) {
    const requestInfo = {
      method: 'get',
      url: `/ente/${idDadosEnte}/foto`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEntes(pagina, linhasPorPagina, like) {
    let url = `/ente/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarConfiguracoesEnte(configuracoesEnte) {
    const requestInfo = {
      method: 'put',
      url: '/ente/configuracoes',
      data: JSON.stringify(configuracoesEnte),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEntesPorEstado(idEstado) {
    const requestInfo = {
      method: 'get',
      url: `/ente/estado/${idEstado}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarConfiguracaoRecursos(configuracaoRecursos) {
    const requestInfo = {
      method: 'put',
      url: '/ente/configuracao/recursos',
      data: JSON.stringify(configuracaoRecursos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default EntesService;
