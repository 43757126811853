/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import env from 'conf/env.json';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Button,
  Table,
  UncontrolledTooltip,
  Label
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import Loader from 'react-loader-spinner';
import Papa from 'papaparse';
import TextareaAutosize from 'react-textarea-autosize';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import CursosService from 'services/CursosService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';
import MateriasService from 'services/MateriasService';
import EntesService from 'services/EntesService';

import SituacaoEstruturaCurricular from 'assets/csv/situacoes-estruturas-curriculares.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import EstruturasCurricularesPadrao from 'assets/csv/estruturas-curriculares-padrao.json';

class CadastroEstruturaCurricular extends React.Component {
  constructor(props) {
    super(props);

    this.ciclosEnsino = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.situacoes = [
      SituacaoEstruturaCurricular.ATIVA,
      SituacaoEstruturaCurricular.INATIVA,
    ];

    this.nomeRef = React.createRef();
    this.fileInputRef = React.createRef();

    this.loginService = new LoginService();
    this.cursosService = new CursosService();
    this.estruturasCurricularesService = new EstruturasCurricularesService();
    this.materiasService = new MateriasService();
    this.entesService = new EntesService();

    this.estruturaCurricularSelecionada = null;

    this.state = {
      showAlert: false,

      idadeInicial: '',
      idadeFinal: '',

      formaOrganizacaoEtapas: '',
      serieEquivalencia: '',

      nomeState: '',
      situacaoState: '',
      materiasState: '',

      situacaoSelecionada: this.situacoes[0],

      materias: [{ nome: '', conteudo: '', exercicios: undefined }],

      competenciasGerais: '',

      buttonDisabled: false,
    };

    this.cadastrarEstruturaCurricular = this.cadastrarEstruturaCurricular.bind(this);
    this.atualizarEstruturaCurricular = this.atualizarEstruturaCurricular.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const curso = await this.cursosService
        .carregarCurso(this.props.match.params.idCurso);

      const dadosEnte = await this.entesService.carregarDadosEnte();

      if (this.props.match.params.idEstruturaCurricular !== undefined) {
        const estruturaCurricular = await this.estruturasCurricularesService
          .carregarEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

        const materias = await this.materiasService
          .carregarMateriasPorEstruturaCurricular(estruturaCurricular.id);

        Object.assign(estruturaCurricular, { materias });

        this.estruturaCurricularSelecionada = estruturaCurricular;
        this.temTurmas = estruturaCurricular.temTurmas;

        this.setState({ curso, dadosEnte });

        this.contentLoad();
      } else {
        this.setState({ curso, dadosEnte });
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.nomeRef.current.value = this.estruturaCurricularSelecionada.nome;
    this.setState({
      idadeInicial: this.estruturaCurricularSelecionada.idadeInicial !== undefined ? this.estruturaCurricularSelecionada.idadeInicial : '',
      idadeFinal: this.estruturaCurricularSelecionada.idadeFinal !== undefined ? this.estruturaCurricularSelecionada.idadeFinal : '',
      formaOrganizacaoEtapas: this.estruturaCurricularSelecionada.formaOrganizacaoEtapas || '',
      serieEquivalencia: this.estruturaCurricularSelecionada.serieEquivalencia || '',
      situacaoSelecionada: this.situacoes[this.estruturaCurricularSelecionada.situacao],
      materias: this.estruturaCurricularSelecionada.materias,
      competenciasGerais: this.estruturaCurricularSelecionada.competenciasGerais
        ? this.estruturaCurricularSelecionada.competenciasGerais : '',
    });
  }

  validarCampos() {
    let ret = true;
    if (this.nomeRef.current.value === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }

    if (this.state.idadeInicial > this.state.idadeFinal) {
      this.setState({ idadeInicialState: 'has-danger', idadeFinalState: 'has-danger' });
      ret = false;
    }

    if (!this.state.situacaoSelecionada) {
      this.setState({ situacaoState: 'danger' });
      ret = false;
    }

    this.state.materias.forEach((materia) => {
      if (!materia.nome) {
        Object.assign(materia, { nomeState: 'has-danger' });
        ret = false;
      }
      if (!materia.conteudo) {
        Object.assign(materia, { conteudoState: 'has-danger' });
        ret = false;
      }
    });

    if (!ret) this.forceUpdate();

    return ret;
  }

  async cadastrarEstruturaCurricular() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando estrutura curricular...',
    });

    const estruturaCurricular = {
      nome: this.nomeRef.current.value,
      situacao: this.state.situacaoSelecionada.value,
      curso: { id: this.props.match.params.idCurso },
      materias: this.state.materias,
      competenciasGerais: this.state.competenciasGerais,
      idadeInicial: this.state.idadeInicial,
      idadeFinal: this.state.idadeFinal,
      formaOrganizacaoEtapas: this.state.formaOrganizacaoEtapas,
      serieEquivalencia: this.state.serieEquivalencia,
    };

    try {
      await this.estruturasCurricularesService
        .cadastrarEstruturaCurricular(estruturaCurricular);

      this.limparFormulario();

      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Estrutura curricular cadastrada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar estrutura curricular',
      });
    }
  }

  async atualizarEstruturaCurricular() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando estrutura curricular...',
    });

    const estruturaCurricular = {
      id: this.estruturaCurricularSelecionada.id,
      nome: this.nomeRef.current.value,
      situacao: this.state.situacaoSelecionada.value,
      curso: { id: this.props.match.params.idCurso },
      materias: this.state.materias,
      competenciasGerais: this.state.competenciasGerais,
      idadeInicial: this.state.idadeInicial,
      idadeFinal: this.state.idadeFinal,
      formaOrganizacaoEtapas: this.state.formaOrganizacaoEtapas,
      serieEquivalencia: this.state.serieEquivalencia,
    };

    try {
      if (!this.temTurmas) {
        await this.estruturasCurricularesService
          .atualizarEstruturaCurricular(estruturaCurricular);
      } else {
        await this.estruturasCurricularesService
          .atualizarEstruturaCurricularComTurmas(estruturaCurricular);
      }

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Estrutura curricular atualizada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar estrutura curricular',
      });
    }
  }

  limparFormulario() {
    this.nomeRef.current.value = '';

    this.setState({
      idadeInicial: '',
      idadeFinal: '',

      nomeState: '',
      situacaoState: '',
      materiasState: '',
      idadeInicialState: '',
      idadeFinalState: '',

      situacaoSelecionada: this.situacoes[0],
      materias: [{ nome: '', conteudo: '', exercicios: undefined }],
      competenciasGerais: '',

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.buttonDisabled) return;

    if (!this.estruturaCurricularSelecionada) {
      this.cadastrarEstruturaCurricular();
    } else {
      this.atualizarEstruturaCurricular();
    }
  }

  removerMateria(materia) {
    if (this.state.materias.length === 1) return;
    const materias = this.state.materias.filter(
      (materia2) => materia !== materia2,
    );

    this.setState({ materias });
  }

  validarMateria = (aluno) => {
    const nome = aluno[0];
    const conteudo = aluno[1];

    if (nome === '') {
      throw new Error(`Erro: informe o nome de todos os ${this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
        ? 'campos de experiências' : 'componentes curriculares'}.`);
    }

    if (conteudo === '') {
      throw new Error(`Erro: informe o conteúdo de todos os ${this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
        ? 'campos de experiências' : 'componentes curriculares'}.`);
    }

    return {
      nome,
      conteudo,
    };
  }

  updateData(result) {
    this.fileInputRef.current.value = '';
    const materias = [];

    try {
      result.data.forEach((materia, idx) => {
        if (idx > 0) {
          materias.push(this.validarMateria(materia));
        }
      });
      this.setState({
        materias,
        buttonDisabled: false,
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e.message || 'Erro: arquivo mal formatado',
      });
    }
  }

  handleFile(e) {
    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: `Carregando ${this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
        ? 'Campos de experiências' : 'Componentes curriculares'}...`,
    });
    e.preventDefault();
    const file = e.target.files[0];
    const extension = file.name.split('.').pop();
    if (extension !== 'csv') {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro: informe apenas arquivos com extensão .csv',
      });
    } else {
      Papa.parse(file, {
        complete: this.updateData.bind(this),
        delimiter: ';',
        skipEmptyLines: true,
      });
    }
  }

  preencherComMateriasPadrao() {
    this.setState({
      materias: EstruturasCurricularesPadrao[this.state.curso.serie].materias,
      competenciasGerais: EstruturasCurricularesPadrao[this.state.curso.serie].competencias,
    });
  }

  conteudoPagina() {
    let str = '';
    if (this.state.curso) {
      if (this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
        if (this.state.curso.serie === 0 || this.state.curso.serie === 1) { // Berçário 0
          str = 'Direitos de aprendizagem e desenvolvimento';
        } else if (this.state.curso.serie === 2 || this.state.curso.serie === 3) { // Berçário 0
          str = 'Direitos de aprendizagem e desenvolvimento';
        } else { // PRÉ1 E PRÉ2
          str = 'Direitos de aprendizagem e desenvolvimento';
        }
      } else { // ENSINO FUNDAMENTAL 1
        str = `Competências gerais propostas para os estudantes do ${this.state.curso.nome}`;
      }
    }

    return this.state.curso
      ? <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}`}>Curso</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.estruturaCurricularSelecionada ? 'Edição estrutura curricular' : 'Cadastro estrutura curricular'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da estrutura curricular</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        disabled={this.temTurmas}
                        innerRef={this.nomeRef}
                        type="text"
                        maxLength="200"
                        onChange={() => this.setState({ nomeState: 'has-success' })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome da estrutura curricular
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Curso *</label>
                      <Input
                        value={this.state.curso.nome}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Etapa de ensino *</label>
                      <Input
                        value={this.ciclosEnsino[this.state.curso.cicloEnsino].label}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Situação *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.situacaoState}`}
                        classNamePrefix="react-select"
                        value={this.state.situacaoSelecionada}
                        onChange={(event) => {
                          this.setState({ situacaoState: '', situacaoSelecionada: event });
                        }}
                        options={this.situacoes}
                        placeholder="Situação..."
                        isDisabled={this.estruturaCurricularSelecionada === null}
                      />
                      {this.state.situacaoState === 'danger' ? (
                        <label className="error">
                          Informe a situação da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.temTurmas && <Row>
                    <Col>
                      <FormGroup className={'has-label'}>
                        <label>Observações</label>
                        <Input
                          value={'Existem turmas que usam essa estrutura curricular. Somente a situação, faixa etária e conteúdos podem ser alterados.'}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.idadeInicialState}`}>
                      <label>Faixa etária (idade inicial)</label>
                      <Input
                        value={this.state.idadeInicial}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({
                            idadeInicial: parseInt(val.formattedValue),
                            idadeInicialState: '',
                          });
                        }}
                        tag={NumberFormat} />
                      {this.state.idadeInicialState === 'has-danger' ? (
                        <label className="error">
                          Faixa etária inconsistente
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.idadeFinalState}`}>
                      <label>Faixa etária (idade final)</label>
                      <Input
                        value={this.state.idadeFinal}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({
                            idadeFinal: parseInt(val.formattedValue),
                            idadeFinalState: '',
                          });
                        }}
                        tag={NumberFormat} />
                      {this.state.idadeFinalState === 'has-danger' ? (
                        <label className="error">
                          Faixa etária inconsistente
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
                <br />
                <Row>
                  <Col md="6">
                    <h5>
                      {this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                        ? 'Campos de experiências' : 'Componentes curriculares'}
                    </h5>
                  </Col>
                  {
                    !this.props.match.params.idEstruturaCurricular && <Col md="6" className="text-right">
                      <Button color="primary" onClick={() => this.preencherComMateriasPadrao()}>
                        Preencher com {this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                          ? 'Campos de experiências' : 'Componentes curriculares'} padrão
                      </Button>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col>
                    <div>
                      <div className="fileinput text-center">
                        <input
                          type="file"
                          accept=".csv"
                          onChange={this.handleFile.bind(this)}
                          ref={this.fileInputRef} />
                      </div>
                      {
                        !this.temTurmas && false && <>
                          <Button
                            color="default"
                            onClick={() => this.fileInputRef.current.click()}>
                            Importar {this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                              ? 'Campos de experiências' : 'Componentes curriculares'} de arquivo
                          </Button>
                          <Button
                            id="btnBaixarArquivoModelo"
                            className="btn-icon"
                            color="default"
                            onClick={() => window.open(`https://${env.BUCKET_NAME}.s3-sa-east-1.amazonaws.com/arquivos/modelos/materias.csv`)}>
                            <i className="fa fa-download" aria-hidden="true"></i>
                          </Button>
                          <UncontrolledTooltip placement="bottom" target="btnBaixarArquivoModelo" delay={0}>
                            Baixar arquivo modelo
                          </UncontrolledTooltip>
                        </>
                      }
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <PerfectScrollbar className="sisa-perfect-scrollbar">
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th style={{ width: '20%' }}>Nome</th>
                            <th style={{ width: '45%' }}>Conteúdo</th>
                            <th style={{ width: '10%' }}>Exercícios</th>
                            {this.state.dadosEnte.avaliacaoAtelie && <th className="text-center" style={{ width: '5%' }}>Ateliê</th>}
                            {!this.temTurmas && <th className="text-left" style={{ paddingLeft: 20, width: '15%' }}>Ação</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.materias.map((materia, index) => (
                              <tr key={index}>
                                <td>
                                  <FormGroup className={`has-label ${materia.nomeState}`}>
                                    <Input
                                      disabled={this.temTurmas}
                                      type="text"
                                      value={materia.nome}
                                      maxLength="200"
                                      onChange={(event) => {
                                        Object.assign(materia,
                                          {
                                            nome: event.target.value,
                                            nomeState: 'primary',
                                            conteudoState: 'primary',
                                            exerciciosState: 'primary',
                                          });
                                        this.forceUpdate();
                                      }}
                                    />
                                    {materia.nomeState === 'has-danger' ? (
                                      <label className="error">
                                        Informe o nome do {this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                                          ? 'campo de experiências' : 'componente curricular'}.
                                      </label>
                                    ) : null}
                                  </FormGroup>
                                </td>
                                <td>
                                  <FormGroup className={`has-label ${materia.conteudoState}`}>
                                    <TextareaAutosize
                                      className={`autoheight-curriculo ${materia.conteudoState}`}
                                      value={materia.conteudo}
                                      onChange={(event) => {
                                        Object.assign(materia,
                                          {
                                            conteudo: event.target.value,
                                            nomeState: 'primary',
                                            conteudoState: 'primary',
                                            exerciciosState: 'primary',
                                          });
                                        this.forceUpdate();
                                      }} />
                                    {materia.conteudoState === 'has-danger' ? (
                                      <label className="error">
                                        Informe o conteúdo do {this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                                          ? 'campo de experiências' : 'componente curricular'}.
                                      </label>
                                    ) : null}
                                  </FormGroup>
                                </td>
                                <td>
                                  <FormGroup className={`has-label ${materia.exerciciosState}`}>
                                    <Input
                                      value={materia.exercicios}
                                      decimalSeparator={false}
                                      allowNegative={false}
                                      style={{ textAlign: 'right' }}
                                      onValueChange={(event) => {
                                        Object.assign(materia,
                                          {
                                            exercicios: event.formattedValue <= 3 ? event.formattedValue : 3,
                                            nomeState: 'primary',
                                            conteudoState: 'primary',
                                            exerciciosState: 'primary',
                                          });
                                        this.forceUpdate();
                                      }}
                                      tag={NumberFormat} />
                                    {materia.exerciciosState === 'has-danger' ? (
                                      <label className="error">
                                        Informe a quantidade de exercícios.
                                      </label>
                                    ) : null}
                                  </FormGroup>
                                </td>
                                {
                                  this.state.dadosEnte.avaliacaoAtelie && <td className="text-center">
                                    <FormGroup check>
                                      <Label id="atelieMateria" className="form-check-label">
                                        <Input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={materia.atelie || false}
                                          onChange={(e) => {
                                            Object.assign(materia, {
                                              atelie: e.target.checked,
                                            });
                                            this.forceUpdate();
                                          }} />
                                        <span className="form-check-sign">
                                          <span className="check"></span>
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  </td>
                                }
                                {
                                  !this.temTurmas && <td className="text-left" style={{ verticalAlign: 'top', paddingTop: '17px', paddingLeft: 20 }}>
                                    <Button
                                      id={`btnRemoverItem${index}`}
                                      className="btn-icon"
                                      color="danger"
                                      size="sm"
                                      onClick={() => this.removerMateria(materia)}
                                      type="button">
                                      <i className="fa fa-times" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target={`btnRemoverItem${index}`} delay={0}>
                                      Remover
                                    </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {index === this.state.materias.length - 1
                                      && <>
                                        <Button
                                          id="adicionarMateriaBtn"
                                          className="btn-icon"
                                          color="success"
                                          size="sm"
                                          onClick={() => {
                                            this.state.materias.push({
                                              nome: '',
                                              conteudo: '',
                                              exercicios: undefined,
                                            });
                                            this.forceUpdate();
                                          }}>
                                          <i className="fa fa-plus-circle" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="adicionarMateriaBtn" delay={0}>
                                          Adicionar {this.state.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                                            ? 'campo de experiências' : 'componente curricular'}
                                        </UncontrolledTooltip>
                                      </>
                                    }
                                  </td>
                                }
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </PerfectScrollbar>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>
                      {str}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'has-label'}>
                      <FormGroup className={'has-label'}>
                        <TextareaAutosize
                          className="autoheight"
                          value={this.state.competenciasGerais}
                          onChange={(evt) => {
                            this.setState({
                              competenciasGerais: evt.target.value,
                            });
                          }} />
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary" disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.goBack()} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.estruturaCurricularSelecionada
                        ? <Button color="primary" disabled={this.state.buttonDisabled}>
                          Cadastrar
                        </Button>
                        : <Button style={{ marginRight: 5 }} color="primary" disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroEstruturaCurricular;
