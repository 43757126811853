import BaseService from './BaseService';

class TarefasService extends BaseService {
  carregarTarefa(idTarefa) {
    const requestInfo = {
      method: 'get',
      url: `/tarefa/${idTarefa}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTarefas(idTurma, idMateria, pagina, linhasPorPagina, like) {
    let url = '/tarefa'
      + `/turma/${idTurma}/materia/${idMateria}`
      + `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarTarefa(idTurma, idMateria, tarefa) {
    const requestInfo = {
      method: 'post',
      url: `/tarefa/turma/${idTurma}/materia/${idMateria}`,
      data: JSON.stringify(tarefa),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarTarefa(tarefa) {
    const requestInfo = {
      method: 'put',
      url: '/tarefa',
      data: JSON.stringify(tarefa),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerTarefa(idTarefa) {
    const requestInfo = {
      method: 'delete',
      url: `/tarefa/${idTarefa}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  enviarArquivo(idTarefa, arquivo) {
    const requestInfo = {
      method: 'post',
      url: `/tarefa/${idTarefa}/envio`,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEnvios(idTarefa, pagina, linhasPorPagina, like) {
    let url = `/tarefa/${idTarefa}/envios/aluno/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEnvio(idEnvio) {
    const requestInfo = {
      method: 'delete',
      url: `/tarefa/envio/${idEnvio}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAlunosQueEnviaramTarefa(idDiario, idTarefa, idTurma, pagina, linhasPorPagina, like) {
    let url = `/tarefa/${idTarefa}/diario/${idDiario}/turma/${idTurma}/envios/alunos/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAlunosQueNaoEnviaramTarefa(idDiario, idTarefa, idTurma, pagina, linhasPorPagina, like) {
    let url = `/tarefa/${idTarefa}/diario/${idDiario}/turma/${idTurma}/naoenvios/alunos/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEnviosPorAluno(idTarefa, idAluno, pagina, linhasPorPagina, like) {
    let url = `/tarefa/${idTarefa}/envios/aluno/${idAluno}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTexto(idTarefa) {
    const requestInfo = {
      method: 'get',
      url: `/tarefa/${idTarefa}/texto`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTextoPorAluno(idTarefa, idAluno) {
    const url = `/tarefa/${idTarefa}/texto/aluno/${idAluno}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarTexto(idTarefa, conteudo) {
    const requestInfo = {
      method: 'post',
      url: `/tarefa/${idTarefa}/texto`,
      data: JSON.stringify(conteudo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default TarefasService;
