import BaseService from './BaseService';

class TransportesService extends BaseService {
  carregarTransporte(idTransporte) {
    const requestInfo = {
      method: 'get',
      url: `/transporte/${idTransporte}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTransportes(pagina, linhasPorPagina, like) {
    let url = `/transporte/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarTransporte(transporte) {
    const requestInfo = {
      method: 'post',
      url: '/transporte',
      data: JSON.stringify(transporte),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarTransporte(transporte) {
    const requestInfo = {
      method: 'put',
      url: '/transporte',
      data: JSON.stringify(transporte),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerTransporte(idTransporte) {
    const requestInfo = {
      method: 'delete',
      url: `/transporte/${idTransporte}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  adicionarPassageiro(idTransporte, idUsuario, idAnoLetivo) {
    const requestInfo = {
      method: 'post',
      url: `/transporte/${idTransporte}/adicionar/passageiro/${idUsuario}/anoletivo/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarPassageiros(idTransporte, idAnoLetivo, pagina, linhasPorPagina, like) {
    let url = `/transporte/${idTransporte}/passageiros/anoletivo/${idAnoLetivo}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerPassageiro(idTransporte, idUsuario, idAnoLetivo) {
    const requestInfo = {
      method: 'delete',
      url: `/transporte/${idTransporte}/passageiro/${idUsuario}/anoletivo/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTransportesAluno(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/transporte/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTodosTransportesAluno(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/transporte/aluno/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default TransportesService;
