import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import { connect } from 'react-redux';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoDepoimento from 'assets/csv/situacoes-depoimento.json';
import Papel from 'assets/csv/papeis.json';

import DepoimentosService from 'services/DepoimentosService';
import LoginService from 'services/LoginService';

class Depoimentos extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoDepoimento.AGUARDANDO_MODERACAO,
      SituacaoDepoimento.ACEITO,
      SituacaoDepoimento.REJEITADO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-depoimentos`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.carregarDepoimentos = this.carregarDepoimentos.bind(this);
    this.removerDepoimento = this.removerDepoimento.bind(this);
    this.confirmarRemocaoDepoimento = this.confirmarRemocaoDepoimento.bind(this);

    this.depoimentosService = new DepoimentosService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      depoimentoSelecionado: null,

      showLoaderModal: false,
      showConfirmModal: false,

      dadosTabela: {
        header: [['Descrição rápida', '20%'], ['Usuário', '20%'], ['Ente', '20%'], ['Situação', '20%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-left'],
        keys: ['descricao', 'usuario', 'ente', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    };
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.depoimentosService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    this.carregarDepoimentos(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarDepoimentos(pagina, linhasPorPagina, like) {
    try {
      const data = await this.depoimentosService
        .carregarDepoimentos(pagina, linhasPorPagina, like);

      data.depoimentos.forEach((depoimento) => {
        Object.assign(depoimento, { situacao_str: this.situacoes[depoimento.situacao].label });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.depoimentos, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar depoimentos cadastrados',
      });
    }
  }

  confirmarRemocaoDepoimento(depoimento) {
    this.setState({ depoimentoSelecionado: depoimento, showConfirmModal: true });
  }

  async removerDepoimento() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.depoimentosService
        .removerDepoimento(this.state.depoimentoSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Depoimento removido com sucesso',
      });

      this.carregarDepoimentos(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover depoimento',
      });
    }
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Depoimentos</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Depoimentos cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <AdaptiveTable
                  selectedPage={this.paginaSelecionada}
                  rowsPerPage={this.linhasPorPagina}
                  disablePrintAction
                  disableEditAction
                  disableActions={this.props.role === Papel.ADMINISTRADOR.value}
                  clickRows
                  removeButtonCallback={(depoimento) => this.confirmarRemocaoDepoimento(depoimento)}
                  rowsPerPageCallback={(info) => {
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.linhasPorPagina = info.rowsPerPage;
                    sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                    this.carregarDepoimentos(this.paginaSelecionada, info.rowsPerPage, this.like);
                  }}
                  likeCallback={(text) => {
                    this.like = text;
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarDepoimentos(this.paginaSelecionada, this.linhasPorPagina, text);
                  }}
                  paginatorCallback={(page) => {
                    this.paginaSelecionada = page;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarDepoimentos(this.paginaSelecionada,
                      this.linhasPorPagina, this.like);
                  }}
                  rowCallback={(depoimento) => this.props.history.push({ pathname: `${this.props.layout}/depoimentos/${depoimento.id}` })}
                  data={this.state.dadosTabela} />
              </CardBody>
              {
                this.props.role !== Papel.ADMINISTRADOR.value && <CardFooter className="text-right">
                  <Button color="primary"
                    onClick={() => {
                      this.props.history.push({ pathname: `${this.props.layout}/depoimentos/cadastro` });
                    }}>
                    Cadastrar depoimento
                </Button>
                </CardFooter>
              }
            </Card>
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerDepoimento();
            } else {
              this.setState({ depoimentoSelecionado: null });
            }
          }}
          text='Confirme a exclusão do depoimento' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Depoimentos);
