import React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import tippy, { animateFill } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

import moment from 'moment';
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.pt';

import TipoFeriado from 'assets/csv/tipos-feriado.json';

class CalendarModal extends React.Component {
  constructor(props) {
    super(props);

    this.tipos_feriados = [
      TipoFeriado.FERIADO_NACIONAL,
      TipoFeriado.FERIADO_ESTADUAL,
      TipoFeriado.FERIADO_MUNICIPAL,
      TipoFeriado.PONTO_FACULTATIVO,
    ];

    this.tooltip = null;

    this.state = {
      isOpen: props.isOpen,
      anoLetivo: props.anoLetivo,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (this.state.anoLetivo !== nextProps.anoLetivo) {
      this.setState({ anoLetivo: nextProps.anoLetivo });
    }
  }

  getDataSource() {
    const dataSource = [];
    if (this.state.anoLetivo.dataInicioBimestre1) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre1, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre1, 'DD/MM/YYYY').toDate(),
        name: 'Início do 1º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataInicioBimestre2) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre2, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre2, 'DD/MM/YYYY').toDate(),
        name: 'Início do 2º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataInicioBimestre3) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre3, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre3, 'DD/MM/YYYY').toDate(),
        name: 'Início do 3º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataInicioBimestre4) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre4, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre4, 'DD/MM/YYYY').toDate(),
        name: 'Início do 4º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre1) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre1, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre1, 'DD/MM/YYYY').toDate(),
        name: 'Fim do 1º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre2) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre2, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre2, 'DD/MM/YYYY').toDate(),
        name: 'Fim do 2º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre3) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre3, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre3, 'DD/MM/YYYY').toDate(),
        name: 'Fim do 3º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre4) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre4, 'DD/MM/YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre4, 'DD/MM/YYYY').toDate(),
        name: 'Fim do 4º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.feriados) {
      this.state.anoLetivo.feriados.forEach((feriado) => {
        dataSource.push({
          startDate: moment(feriado.data, 'DD/MM/YYYY').toDate(),
          endDate: moment(feriado.data, 'DD/MM/YYYY').toDate(),
          name: `${this.tipos_feriados[feriado.tipo].label}: ${feriado.nome}`,
          color: '#EE332A',
        });
      });
    }
    if (this.state.anoLetivo.diasCancelados) {
      this.state.anoLetivo.diasCancelados.forEach((dia) => {
        dataSource.push({
          startDate: moment(dia.data, 'DD/MM/YYYY').toDate(),
          endDate: moment(dia.data, 'DD/MM/YYYY').toDate(),
          name: `Dia letivo cancelado: ${dia.motivo}`,
          color: '#EE332A',
        });
      });
    }
    if (this.state.anoLetivo.diasExtras) {
      this.state.anoLetivo.diasExtras.forEach((dia) => {
        dataSource.push({
          startDate: moment(dia.data, 'DD-MM-YYYY').toDate(),
          endDate: moment(dia.data, 'DD-MM-YYYY').toDate(),
          name: `Dia letivo extra: ${dia.motivo}`,
          color: '#E54CE0',
        });
      });
    }
    if (this.state.anoLetivo.dataInicioRecesso && this.state.anoLetivo.dataFimRecesso) {
      const inicioRecesso = moment(this.state.anoLetivo.dataInicioRecesso, 'DD/MM/YYYY').toDate();
      const fimRecesso = moment(this.state.anoLetivo.dataFimRecesso, 'DD/MM/YYYY').toDate();
      for (let d = inicioRecesso; d <= fimRecesso; d.setDate(d.getDate() + 1)) {
        dataSource.push({
          startDate: new Date(d),
          endDate: new Date(d),
          name: 'Recesso escolar',
          color: '#3CC932',
        });
      }
    }

    return dataSource;
  }

  handleDayEnter(e) {
    if (e.events.length > 0) {
      let content = '';
      for (let i = 0; i < e.events.length; i += 1) {
        content += `${'<div class="event-tooltip-content">'
          + '<div class="event-name">'}${e.events[i].name}</div>`
          // + '<div class="event-details">' + e.events[i].details + '</div>'
          + '</div>';
      }
      if (this.tooltip != null) {
        this.tooltip.destroy();
        this.tooltip = null;
      }
      this.tooltip = tippy(e.element, {
        placement: 'right',
        content,
        animateFill: false,
        animation: 'shift-away',
        arrow: true,
      }, [animateFill]);
      this.tooltip.show();
    }
  }

  handleDayLeave() {
    if (this.tooltip !== null) {
      this.tooltip.destroy();
      this.tooltip = null;
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          size="xl">
          <ModalHeader
            toggle={this.props.toggle}>
            Calendário Escolar {this.state.anoLetivo.ano ? this.state.anoLetivo.ano
              : new Date().getFullYear()}
          </ModalHeader>
          <ModalBody>
            <Calendar
              dataSource={this.getDataSource()}
              onDayEnter={(e) => this.handleDayEnter(e)}
              onDayLeave={() => this.handleDayLeave()}
              // style='background'
              year={this.state.anoLetivo.ano ? this.state.anoLetivo.ano : new Date().getFullYear()}
              customDayRenderer={(element, date) => {
                if (date.getDay() === 6 || date.getDay() === 0) {
                  Object.assign(element.style, { cssText: 'color: red' });
                }
              }}
              language="pt" />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default CalendarModal;
