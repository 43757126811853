import BaseService from './BaseService';

class AulasService extends BaseService {
  carregarAula(idAula) {
    const requestInfo = {
      method: 'get',
      url: `/aula/${idAula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAulas(idTurma, idMateria, pagina, linhasPorPagina, like) {
    let url = `/aula/turma/${idTurma}/materia/${idMateria}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAulasUsuario(like) {
    let url = '/aula/usuario';
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAulasDoDiretorio(idDiretorioPai) {
    let url = '/aula/diretorio';
    if (idDiretorioPai) {
      url += `/${idDiretorioPai}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAulasDoDiretorioDoProfessor(idProfessor, idDiretorioPai) {
    let url = `/aula/professor/${idProfessor}/diretorio`;
    if (idDiretorioPai) {
      url += `/${idDiretorioPai}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarAulaEAdicionarNaTurma(aula, idTurma, idMateria) {
    const requestInfo = {
      method: 'post',
      url: `/aula/turma/${idTurma}/materia/${idMateria}`,
      data: JSON.stringify(aula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarAula(aula) {
    const requestInfo = {
      method: 'post',
      url: '/aula',
      data: JSON.stringify(aula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarAula(aula) {
    const requestInfo = {
      method: 'put',
      url: '/aula',
      data: JSON.stringify(aula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerAula(idAula) {
    const requestInfo = {
      method: 'delete',
      url: `/aula/${idAula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerAulaDaTurmaVirtual(idAula, idTurma, idMateria) {
    const requestInfo = {
      method: 'delete',
      url: `/aula/${idAula}/turma/${idTurma}/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  uploadArquivo(arquivo) {
    const requestInfo = {
      method: 'post',
      url: '/aula/upload/arquivo',
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  importarAulas(aulas, idTurma, idMateria) {
    const requestInfo = {
      method: 'post',
      url: `/aula/turma/${idTurma}/materia/${idMateria}/importar`,
      data: JSON.stringify(aulas),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default AulasService;
