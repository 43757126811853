import BaseService from './BaseService';

class ArquivosTurmaVirtualService extends BaseService {
  carregarArquivosTurmaVirtual(idTurma, idMateria, pagina, linhasPorPagina, like) {
    let url = `/arquivoturmavirtual/${idTurma}/arquivos`;
    if (idMateria) {
      url += `/materia/${idMateria}`;
    }

    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarArquivoTurmaVirtual(idTurma, idMateria, arquivo) {
    let url = `/arquivoturmavirtual/${idTurma}/upload/arquivo`;
    if (idMateria) {
      url += `/materia/${idMateria}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerArquivoTurmaVirtual(idArquivo) {
    const requestInfo = {
      method: 'delete',
      url: `/arquivoturmavirtual/arquivo/${idArquivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default ArquivosTurmaVirtualService;
