import Papel from 'assets/csv/papeis.json';
import BaseService from './BaseService';

class UsuarioService extends BaseService {
  carregarUsuario(idUsuario) {
    let url = '/usuario';
    if (idUsuario !== undefined) {
      url += `/${idUsuario}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUsuarios(papel, pagina, linhasPorPagina, like) {
    let papelPath = '';
    switch (papel) {
      case Papel.GESTOR.value:
        papelPath = 'gestor';
        break;
      case Papel.DIRETOR.value:
        papelPath = 'diretor';
        break;
      case Papel.SECRETARIO.value:
        papelPath = 'secretario';
        break;
      case Papel.COORDENADOR_PEDAGOGICO.value:
        papelPath = 'coordenador';
        break;
      case Papel.PROFESSOR.value:
        papelPath = 'professor';
        break;
      case Papel.ALUNO.value:
        papelPath = 'aluno';
        break;
      case Papel.PAIS.value:
        papelPath = 'pais';
        break;
      default:
        throw new Error('Papel inválido');
    }

    let url = `/usuario/papel/${papelPath}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUsuariosAtivos(papel, pagina, linhasPorPagina, like) {
    let papelPath = '';
    switch (papel) {
      case Papel.GESTOR.value:
        papelPath = 'gestor';
        break;
      case Papel.DIRETOR.value:
        papelPath = 'diretor';
        break;
      case Papel.SECRETARIO.value:
        papelPath = 'secretario';
        break;
      case Papel.COORDENADOR_PEDAGOGICO.value:
        papelPath = 'coordenador';
        break;
      case Papel.PROFESSOR.value:
        papelPath = 'professor';
        break;
      case Papel.ALUNO.value:
        papelPath = 'aluno';
        break;
      case Papel.PAIS.value:
        papelPath = 'pais';
        break;
      default:
        throw new Error('Papel inválido');
    }

    let url = `/usuario/ativo/papel/${papelPath}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUsuariosPorPapeis(papeis, pagina, linhasPorPagina, like) {
    let url = `/usuario/papeis/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(papeis),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUsuariosDoEnte(pagina, linhasPorPagina, like) {
    let url = `/usuario/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarServidores(like) {
    let url = '/usuario/servidores';
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarUsuario(usuario) {
    const requestInfo = {
      method: 'put',
      url: '/usuario',
      data: JSON.stringify(usuario),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerUsuario(idUsuario) {
    const requestInfo = {
      method: 'delete',
      url: `/usuario/${idUsuario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarAluno(aluno) {
    const requestInfo = {
      method: 'post',
      url: '/usuario/cadastrar/aluno',
      data: JSON.stringify(aluno),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarAlunos(alunos) {
    const requestInfo = {
      method: 'post',
      url: '/usuario/cadastrar/alunos',
      data: JSON.stringify(alunos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarAluno(aluno) {
    const requestInfo = {
      method: 'put',
      url: '/usuario/atualizar/aluno',
      data: JSON.stringify(aluno),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarInformacoesLaudoNEEAluno(aluno) {
    const requestInfo = {
      method: 'put',
      url: '/usuario/atualizar/informacoeslaudonee/aluno',
      data: JSON.stringify(aluno),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFoto(idUsuario) {
    const requestInfo = {
      method: 'get',
      url: `/usuario/${idUsuario}/foto`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFotoDefault() {
    const requestInfo = {
      method: 'get',
      url: `/usuario/foto/default`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarPaisAluno(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/usuario/${idAluno}/pais`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  vincularPaisAluno(idAluno, idPais) {
    const requestInfo = {
      method: 'post',
      url: `/usuario/vincular/aluno/${idAluno}/pais/${idPais}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerPaisAluno(idAluno, idPais) {
    const requestInfo = {
      method: 'delete',
      url: `/usuario/remover/aluno/${idAluno}/pais/${idPais}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFilhosPais(idPais) {
    const requestInfo = {
      method: 'get',
      url: `/usuario/${idPais}/filhos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerAluno(idAluno, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/usuario/remover/aluno/${idAluno}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarObservacoesAluno(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/usuario/aluno/${idAluno}/observacoes`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarObservacoesAluno(idAluno, observacoesAluno) {
    const requestInfo = {
      method: 'put',
      url: `/usuario/aluno/${idAluno}/observacoes`,
      data: JSON.stringify(observacoesAluno),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEscolasUsuario(idUsuario, papel) {
    const requestInfo = {
      method: 'get',
      url: `/usuario/${idUsuario}/papel/${papel}/escolas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUsuariosVinculadosDiretor(papel, pagina, linhasPorPagina, like) {
    let url = `/usuario/vinculados/diretor/papel/${papel}/page/${pagina}/pagesize/${linhasPorPagina}`
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarUsuariosVinculadosEscola(idEscola, papel, pagina, linhasPorPagina, like) {
    let url = `/usuario/vinculados/escola/${idEscola}/papel/${papel}/page/${pagina}/pagesize/${linhasPorPagina}`
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFilhos() {
    const requestInfo = {
      method: 'get',
      url: `/usuario/filhos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  pesquisarAluno(aluno) {
    const requestInfo = {
      method: 'post',
      url: `/usuario/pesquisar/aluno`,
      data: JSON.stringify(aluno),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarArquivosLaudoNEE(idAluno) {
    let url = `/usuario/${idAluno}/arquivoslaudonee`;
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarArquivoLaudoNEE(idAluno, arquivo) {
    const url = `/usuario/${idAluno}/upload/arquivolaudonee`;
    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerArquivoLaudoNEE(idArquivo) {
    const requestInfo = {
      method: 'delete',
      url: `/usuario/arquivoslaudonee/${idArquivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarVinculosUsuario(idUsuario) {
    let url = `/usuario/${idUsuario}/vinculos`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarVinculosUsuarioAtivo() {
    let url = `/usuario/vinculos`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default UsuarioService;
