export const EDITAR_DADOS_USUARIO = 'EDITAR_DADOS_USUARIO';
export const EDITAR_FOTO_USUARIO = 'EDITAR_FOTO_USUARIO';

export function editDadosUsuario(dadosUsuario) {
  return { type: EDITAR_DADOS_USUARIO, dadosUsuario };
}

export function editFotoUsuario(foto) {
  return { type: EDITAR_FOTO_USUARIO, foto };
}
