import React from 'react';
import { Container, Row } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';

import version from 'conf/version.json';
import env from 'conf/env.json';

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={`footer${this.props.default ? ' footer-default' : ''}`}
      >
        <Container fluid={!!this.props.fluid}>
          <Row>
            <div className="credits ml-auto">
              <span className="copyright">
                &copy; {1900 + new Date().getYear()},
                {env.SYSNAME} - v{version.VERSION}
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
