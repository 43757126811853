import BaseService from './BaseService';

class LimitesService extends BaseService {
  carregarLimitesDoEnte() {
    const requestInfo = {
      method: 'get',
      url: '/limites',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default LimitesService;
