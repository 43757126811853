import BaseService from './BaseService';

class EstoquesService extends BaseService {

  carregarEstoque(idEstoque) {
    const requestInfo = {
      method: 'get',
      url: `/estoque/${idEstoque}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoques(pagina, linhasPorPagina, like) {
    let url = `/estoque/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
  
  cadastrarEstoque(estoque) {
    const requestInfo = {
      method: 'post',
      url: '/estoque',
      data: JSON.stringify(estoque),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarEstoque(estoque) {
    const requestInfo = {
      method: 'put',
      url: '/estoque',
      data: JSON.stringify(estoque),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEstoque(idEstoque) {
    const requestInfo = {
      method: 'delete',
      url: `/estoque/${idEstoque}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueItem(idEstoqueItem) {
    const requestInfo = {
      method: 'get',
      url: `/estoque/item/${idEstoqueItem}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueItens(idEstoque, pagina, linhasPorPagina, like) {
    let url = `/estoque/${idEstoque}/itens/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEstoqueItem(estoqueItem) {
    const requestInfo = {
      method: 'post',
      url: '/estoque/item',
      data: JSON.stringify(estoqueItem),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarEstoqueItem(estoqueItem) {
    const requestInfo = {
      method: 'put',
      url: '/estoque/item',
      data: JSON.stringify(estoqueItem),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEstoqueItem(idEstoqueItem, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/estoque/item/${idEstoqueItem}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueEntrada(idEstoqueEntrada) {
    const requestInfo = {
      method: 'get',
      url: `/estoque/entrada/${idEstoqueEntrada}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueEntradas(idEstoque, idEscola, pagina, linhasPorPagina, like) {
    let url = `/estoque/${idEstoque}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/entradas/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEstoqueEntrada(estoqueEntrada) {
    const requestInfo = {
      method: 'post',
      url: '/estoque/entrada',
      data: JSON.stringify(estoqueEntrada),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarEstoqueEntrada(estoqueEntrada) {
    const requestInfo = {
      method: 'put',
      url: '/estoque/entrada',
      data: JSON.stringify(estoqueEntrada),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEstoqueEntrada(idEstoqueEntrada, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/estoque/entrada/${idEstoqueEntrada}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueSaida(idEstoqueSaida) {
    const requestInfo = {
      method: 'get',
      url: `/estoque/saida/${idEstoqueSaida}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueSaidas(idEstoque, idEscola, pagina, linhasPorPagina, like) {
    let url = `/estoque/${idEstoque}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/saidas/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEstoqueSaida(estoqueSaida) {
    const requestInfo = {
      method: 'post',
      url: '/estoque/saida',
      data: JSON.stringify(estoqueSaida),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarEstoqueSaida(estoqueSaida) {
    const requestInfo = {
      method: 'put',
      url: '/estoque/saida',
      data: JSON.stringify(estoqueSaida),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEstoqueSaida(idEstoqueSaida, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/estoque/saida/${idEstoqueSaida}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarQuantidadeItem(idEstoqueItem, idEscola) {
    let url = `/estoque/quantidade/item/${idEstoqueItem}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueItemEntradas(idEstoqueItem, idEscola, pagina, linhasPorPagina, like) {
    let url = `/estoque/item/${idEstoqueItem}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/entradas/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstoqueItemSaidas(idEstoqueItem, idEscola, pagina, linhasPorPagina, like) {
    let url = `/estoque/item/${idEstoqueItem}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/saidas/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default EstoquesService;
