import moment from 'moment';

import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import TipoEvento from 'assets/csv/tipos-evento.json';

class Util {
  static base64ToBlob = (base64, type) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    if (!type) type = { type: 'application/pdf' };
    return new Blob([bytes], type);
  }

  static validarCNS(s) {
    if (s.match(/[1-2]\d{10}00[0-1]\d/g) || s.match(/[7-9]\d{14}/g)) {
      return this.somaPonderada(s) % 11 === 0;
    }
    return false;
  }

  static somaPonderada(s) {
    let soma = 0;
    for (let i = 0; i < s.length; i += 1) {
      soma += s.charAt(i) * (15 - i);
    }
    return soma;
  }

  static getSituacaoNoDia(matricula, data) {
    const registros = matricula.registros.filter((registro) => {
      const rData = moment(registro.data, 'DD/MM/YYYY');
      return !rData.isAfter(data);
    });

    let situacao = SituacaoMatricula.NAO_MATRICULADO.value;
    if(registros.length > 0) {
      situacao = registros[0].situacao;
    }

    return situacao;
  }

  static getCorEvento(tipo) {
    if (tipo === TipoEvento.AVISO.value) {
      return '#1DC7EA';
    } else if (tipo === TipoEvento.ATIVIDADE.value) {
      return '#FFA534';
    } else if (tipo === TipoEvento.PROVA.value) {
      return '#FB404B';
    } else {
      return '#A49E93';
    }
  }
}

export default Util;
