import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      text: props.text,
      confirmText: props.confirmText,
      cancelText: props.cancelText,
      confirmColor: props.confirmColor,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (this.state.text !== nextProps.text) {
      this.setState({ text: nextProps.text });
    }
    if (this.state.confirmText !== nextProps.confirmText) {
      this.setState({ confirmText: nextProps.confirmText });
    }
    if (this.state.cancelText !== nextProps.cancelText) {
      this.setState({ cancelText: nextProps.cancelText });
    }
    if (this.state.confirmColor !== nextProps.confirmColor) {
      this.setState({ confirmColor: nextProps.confirmColor });
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}>
        <ModalBody>
          <div align="center" style={{ paddingTop: '20px' }}>
            <p>
              {this.state.text}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => { this.props.callback(false); }}>
            {this.state.cancelText || 'Cancelar'}
            </Button>
          <Button
            color={this.state.confirmColor || 'danger'}
            type="button"
            onClick={() => { this.props.callback(true); }}>
            {this.state.confirmText || 'Excluir'}
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal >
    );
  }
}

export default ConfirmModal;
