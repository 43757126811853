import React from 'react';

import { connect } from 'react-redux';

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import TarefasService from 'services/TarefasService';

import Papel from 'assets/csv/papeis.json';

class Tarefas extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-tarefas-${this.props.match.params.idTurma}-${this.props.match.params.idMateria}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.tarefasService = new TarefasService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,
      showConfirmModal: false,

      erro: false,

      dadosTabela: {
        header: [['Tarefa', '50%'], ['Data início', '15%'], ['Data fim', '15%']],
        columnAlign: ['text-left', 'text-center', 'text-center'],
        keys: ['nome', 'dataInicio', 'dataFim'],
        rows: [],
        total: undefined,
      },
    };

    this.removerTarefa = this.removerTarefa.bind(this);
    this.confirmarRemocaoTarefa = this.confirmarRemocaoTarefa.bind(this);
    this.editarTarefa = this.editarTarefa.bind(this);
    this.carregarTarefas = this.carregarTarefas.bind(this);
  }

  componentWillUnmount() {
    this.umounted = true;
  }

  async componentDidMount() {
    try {
      await this.carregarTarefas(this.paginaSelecionada, this.linhasPorPagina, this.like);

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarTarefas(pagina, linhasPorPagina, like) {
    this.setState({ buttonDisabled: true, showAlert: false });

    try {
      const data = await this.tarefasService
        .carregarTarefas(this.props.match.params.idTurma,
          this.props.match.params.idMateria,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.tarefas, total: data.total }),
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar tarefas cadastradas',
      });
    }
  }

  confirmarRemocaoTarefa(tarefa) {
    this.setState({ tarefaSelecionada: tarefa, showConfirmModal: true });
  }

  async removerTarefa() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.tarefasService
        .removerTarefa(this.state.tarefaSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Tarefa removida com sucesso',
      });

      this.carregarTarefas(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover tarefa',
      });
    }
  }

  cadastrarTarefa() {
    if (this.props.match.params.idAnoLetivo) {
      this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.props.match.params.idEscola}`
        + `/turma/${this.props.match.params.idTurma}`
        + `/diario/${this.props.match.params.idDiario}`
        + `/materia/${this.props.match.params.idMateria}`
        + '/turmavirtual/cadastrotarefa');
    } else {
      this.props.history.push(`${this.props.layout}/ava/turmasvirtuais`
        + `/turma/${this.props.match.params.idTurma}`
        + `/diario/${this.props.match.params.idDiario}`
        + `/materia/${this.props.match.params.idMateria}`
        + '/cadastrotarefa');
    }
  }

  editarTarefa(tarefa) {
    if (this.props.match.params.idAnoLetivo) {
      this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.props.match.params.idEscola}`
        + `/turma/${this.props.match.params.idTurma}`
        + `/diario/${this.props.match.params.idDiario}`
        + `/materia/${this.props.match.params.idMateria}`
        + `/turmavirtual/edicaotarefa/${tarefa.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/ava/turmasvirtuais`
        + `/turma/${this.props.match.params.idTurma}`
        + `/diario/${this.props.match.params.idDiario}`
        + `/materia/${this.props.match.params.idMateria}`
        + `/edicaotarefa/${tarefa.id}`);
    }
  }

  tarefaSelecionada(tarefa) {
    if (this.props.role !== Papel.ALUNO.value) {
      if (this.props.match.params.idAnoLetivo) {
        this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.props.match.params.idEscola}`
          + `/turma/${this.props.match.params.idTurma}`
          + `/diario/${this.props.match.params.idDiario}`
          + `/materia/${this.props.match.params.idMateria}`
          + `/turmavirtual/tarefa/${tarefa.id}`);
      } else {
        this.props.history.push(`${this.props.layout}/ava/turmasvirtuais`
          + `/turma/${this.props.match.params.idTurma}`
          + `/diario/${this.props.match.params.idDiario}`
          + `/materia/${this.props.match.params.idMateria}`
          + `/tarefa/${tarefa.id}`);
      }
    } else {
      this.props.history.push(`/aluno/turmas/${this.props.match.params.idMatricula}`
        + `/turma/${this.props.match.params.idTurma}`
        + `/materia/${this.props.match.params.idMateria}`
        + `/turmavirtual/tarefa/${tarefa.id}`);
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions={this.props.role !== Papel.PROFESSOR.value}
              disablePrintAction
              clickRows
              editButtonCallback={(tarefa) => this.editarTarefa(tarefa)}
              removeButtonCallback={(tarefa) => this.confirmarRemocaoTarefa(tarefa)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarTarefas(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarTarefas(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarTarefas(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              rowCallback={(tarefa) => this.tarefaSelecionada(tarefa)}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="text-left">
            <Button color="primary"
              onClick={() => this.props.history.goBack()}>
              Voltar
            </Button>
          </Col>
          <Col md="6" className="text-right">
            {
              this.props.role === Papel.PROFESSOR.value && <>
                <Button color="primary"
                  onClick={() => this.cadastrarTarefa()}>
                  Cadastrar tarefa
                </Button>
                { }
              </>
            }
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerTarefa();
            } else {
              this.setState({ tarefaSelecionada: null });
            }
          }}
          text='Confirme a exclusão da tarefa' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Tarefas);
