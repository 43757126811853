import React from 'react';
import { connect } from 'react-redux';

import AdaptiveTable from 'views/components/AdaptiveTable';

import EstoquesService from 'services/EstoquesService';

class EscolaEstoques extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-escola-estoques-${this.props.match.params.idEscola}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.estoquesService = new EstoquesService();

    this.state = {
      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };

    this.carregarEstoques = this.carregarEstoques.bind(this);
  }

  async componentDidMount() {
    this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarEstoques(pagina, linhasPorPagina, like) {
    try {
      const data = await this.estoquesService.carregarEstoques(pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.estoques, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar estoques cadastrados',
      });
    }
  }

  render() {
    return (
      <>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          clickRows
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          editButtonCallback={(item) => this.editarItemMerenda(item)}
          removeButtonCallback={(item) => this.confirmarRemocaoItemMerenda(item)}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          rowCallback={(estoque) => this.props.history.push({ pathname: `${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${estoque.id}` }) }
          data={this.state.dadosTabela} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(EscolaEstoques);
