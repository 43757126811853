import React from 'react';

import { connect } from 'react-redux';

import AdaptiveTable from 'views/components/AdaptiveTable';

import TurmasService from 'services/TurmasService';

import SituacaoTurma from 'assets/csv/situacoes-turma.json';

class RelatorioSituacaoTurmasAbertasOuConsolidadas extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoTurma.ABERTA,
      SituacaoTurma.CONSOLIDADA,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-relatorio-situacao-turmas-situacao-${this.props.situacao}-${this.props.idAnoLetivo}-${this.props.idEscola}-${this.props.serie}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    const curso = this.props.dadosUsuario.ente !== 'Patos/PB' ? 'Curso' : 'Série';

    this.state = {
      dadosTabela: {
        header: [['Nome', '25%'], ['Escola', '25%'], [curso, '20%'], ['Situação', '15%'], ['Matrículas', '15%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-center', 'text-center'],
        keys: ['nome', 'escola_str', 'curso_str', 'situacao_str', 'total_matriculas'],
        rows: [],
        total: undefined,
      },
    };

    this.turmasService = new TurmasService();
  }

  async componentDidMount() {
    await this.carregarTurmasPorAnoLetivoEEscola(
      this.paginaSelecionada, this.linhasPorPagina, this.like,
    );
  }

  async carregarTurmasPorAnoLetivoEEscola(pagina, linhasPorPagina, like) {
    try {
      const data = await this.turmasService
        .carregarTurmasPorAnoLetivoEEscola(this.props.idAnoLetivo,
          this.props.idEscola, this.props.situacao, this.props.serie,
          pagina, linhasPorPagina, like);

      const fn = (turma) => new Promise((resolve) => resolve(
        this.turmasService.carregarTotalMatriculasAtivas(turma.id),
      ));

      const actions = data.turmas.map(fn);

      const totalMatriculas = await Promise.all(actions);

      data.turmas.forEach((turma, idx) => {
        Object.assign(turma, { escola_str: turma.escola.nome });
        Object.assign(turma, { curso_str: turma.curso.nome });
        Object.assign(turma, { situacao_str: this.situacoes[turma.situacao].label });
        Object.assign(turma, { total_matriculas: totalMatriculas[idx] });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.turmas, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  render() {
    return (
      <div style={{ paddingTop: '20px' }}>
        <AdaptiveTable
          clickRows
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarTurmasPorAnoLetivoEEscola(
              this.paginaSelecionada, info.rowsPerPage, this.like,
            );
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarTurmasPorAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, text,
            );
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarTurmasPorAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, this.like,
            );
          }}
          rowCallback={(turma) => this.props.history.push(`${this.props.layout}`
            + `/anosletivos/${this.props.match.params.idAnoLetivo}`
            + `/escola/${turma.escola.id}/turma/${turma.id}`)}
          data={this.state.dadosTabela} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(RelatorioSituacaoTurmasAbertasOuConsolidadas);
