import React from 'react';

import { connect } from 'react-redux';

import AdaptiveTable from 'views/components/AdaptiveTable';

import AnosLetivosService from 'services/AnosLetivosService';

class RelatorioContagemGeralAlunosAtivos extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-relatorio-contagem-geral-alunos-ativos-${this.props.idAnoLetivo}-${this.props.idEscola}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      dadosTabela: {
        header: [['Nome', '20%'], ['Mãe', '20%'], ['Endereço', '20%']],
        columnAlign: ['text-left', 'text-left', 'text-left'],
        keys: ['nome', 'nomeMae', 'endereco'],
        rows: [],
        total: undefined,
      },
    };

    this.anosLetivosService = new AnosLetivosService();
  }

  async componentDidMount() {
    await this.carregarAlunosAtivosPorAnoLetivoEEscola(
      this.paginaSelecionada, this.linhasPorPagina, this.like,
    );
  }

  async carregarAlunosAtivosPorAnoLetivoEEscola(pagina, linhasPorPagina, like) {
    try {
      const data = await this.anosLetivosService
        .carregarAlunosAtivosPorAnoLetivoEEscola(this.props.idAnoLetivo,
          this.props.idEscola, pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.alunosAtivos, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  render() {
    return (
      <div style={{ paddingTop: '20px' }}>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarAlunosAtivosPorAnoLetivoEEscola(
              this.paginaSelecionada, info.rowsPerPage, this.like,
            );
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarAlunosAtivosPorAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, text,
            );
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarAlunosAtivosPorAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, this.like,
            );
          }}
          data={this.state.dadosTabela} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(RelatorioContagemGeralAlunosAtivos);
