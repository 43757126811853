import BaseService from './BaseService';

class LoginService extends BaseService {
  enviar(login, senha) {
    const requestInfo = {
      method: 'post',
      url: '/autenticacao',
      data: JSON.stringify({
        login,
        senha,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    };
    return this.doRequest(
      requestInfo,
    );
  }

  ativarVinculoNaoAutenticado(credenciais) {
    const requestInfo = {
      method: 'post',
      url: '/autenticacao/definir/vinculo/naoautenticado',
      data: JSON.stringify(credenciais),
      headers: {
        'Content-type': 'application/json',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  ativarVinculoAutenticado(credenciais) {
    const requestInfo = {
      method: 'post',
      url: '/autenticacao/definir/vinculo/autenticado',
      data: JSON.stringify(credenciais),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  verificarPapel(papel) {
    const requestInfo = {
      method: 'get',
      url: `/autenticacao/verificar/papel/${papel}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default LoginService;
