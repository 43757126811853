import BaseService from './BaseService';

class SolicitacaoRecuperacaoSenhaService extends BaseService {
  solicatarRecuperarSenha(solicitacaoRecuperacaoSenha) {
    const requestInfo = {
      method: 'post',
      url: '/solicitar/recuperacao/senha',
      data: JSON.stringify(solicitacaoRecuperacaoSenha),
      headers: {
        'Content-type': 'application/json',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  redefinirSenha(cadastro) {
    const requestInfo = {
      method: 'post',
      url: '/redefinir/senha',
      data: JSON.stringify(cadastro),
      headers: {
        'Content-type': 'application/json',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default SolicitacaoRecuperacaoSenhaService;
