import React from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import Datetime from 'react-datetime';

require('moment/locale/pt-br');

class DatePicker extends React.Component {
  render() {
    return (
      <Datetime
        renderInput={
          (props) => (this.props.clearButtonCallback ? <InputGroup>
            <Input
              disabled={this.props.disabled}
              {...props}
              mask="99/99/9999"
              maskChar=""
              tag={InputMask}
              readOnly
            />
            <InputGroupAddon
              id="btnAction"
              addonType="append"
              className='clickable'
              onClick={this.props.clearButtonCallback}>
              <InputGroupText>
                <i className="fa fa-times clickable-icon" />
              </InputGroupText>
            </InputGroupAddon>
            <UncontrolledTooltip placement="bottom" target='btnAction' delay={0}>
              Limpar
            </UncontrolledTooltip>
          </InputGroup>
            : <Input
              disabled={this.props.disabled}
              {...props}
              mask="99/99/9999"
              maskChar=""
              tag={InputMask}
              readOnly
            />
          )
        }
        viewDate={this.props.viewDate}
        timeFormat={false}
        locale="pt-br"
        onChange={this.props.onChange}
        value={this.props.value}
        closeOnSelect
      />
    );
  }
}

export default DatePicker;
