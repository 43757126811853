import React from 'react';

import { connect } from 'react-redux';

import AdaptiveTable from 'views/components/AdaptiveTable';

import TurmasService from 'services/TurmasService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';

import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class RelatorioMatriculasPorEtniaGrupo extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-relatorio-matriculasetnia-grupo-${this.props.etnia}-${this.props.idAnoLetivo}-${this.props.idEscola}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    const curso = this.props.dadosUsuario.ente !== 'Patos/PB' ? 'Curso' : 'Série';

    this.state = {
      dadosTabela: {
        header: [['Nome', '20%'], ['Mãe', '20%'], ['Escola', '20%'], ['Turma', '10%'], [curso, '20%'], ['Situação', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-left', 'text-left', 'text-left'],
        keys: ['nome', 'mae', 'escola', 'nomeTurma', 'curso', 'situacao'],
        rows: [],
        total: undefined,
      },
    };

    this.turmasService = new TurmasService();
    this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
  }

  async componentDidMount() {
    await this.carregarMatriculasPorEtniaAnoLetivoEEscola(
      this.paginaSelecionada, this.linhasPorPagina, this.like,
    );
  }

  async carregarMatriculasPorEtniaAnoLetivoEEscola(pagina, linhasPorPagina, like) {
    try {
      const data = await this.turmasService
        .carregarMatriculasPorEtniaAnoLetivoEEscola(this.props.idAnoLetivo,
          this.props.idEscola, this.props.situacao, this.props.etnia,
          pagina, linhasPorPagina, like);

      await this.carregarSituacaoMatricula(data.matriculas);

      data.matriculas.forEach((matricula) => {
        Object.assign(matricula, { nome: matricula.usuario.nome });
        Object.assign(matricula, { mae: matricula.usuario.nomeMae });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.matriculas, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  async carregarSituacaoMatricula(matriculas) {
    const fn = (matricula) => new Promise((resolve) => resolve(
      this.registroSituacaoMatriculaService
        .carregarUltimoRegistroSituacaoMatricula(matricula.id),
    ));

    const actions = matriculas.map(fn);

    const registros = await Promise.all(actions);

    matriculas.forEach((matricula, idx) => Object.assign(
      matricula, { situacao: this.situacoes[registros[idx].situacao].label },
    ));
  }

  render() {
    return (
      <div style={{ paddingTop: '20px' }}>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarMatriculasPorEtniaAnoLetivoEEscola(
              this.paginaSelecionada, info.rowsPerPage, this.like,
            );
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarMatriculasPorEtniaAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, text,
            );
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarMatriculasPorEtniaAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, this.like,
            );
          }}
          data={this.state.dadosTabela} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(RelatorioMatriculasPorEtniaGrupo);
