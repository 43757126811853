import React from 'react';
import { createBrowserHistory } from 'history';
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
// import 'assets/scss/paper-dashboard.scss?v=1.1.0';
import 'assets/scss/paper-dashboard.scss';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import SideBarLayout from 'layouts/sidebarlayout/SideBarLayout';
import RootLayout from 'layouts/root/RootLayout';

import adminRoutes, { routesProfile as adminRoutesProfile } from 'routes/routes-admin';
import gestorRoutes, { routesProfile as gestorRoutesProfile } from 'routes/routes-gestor';
import diretorRoutes, { routesProfile as diretorRoutesProfile } from 'routes/routes-diretor';
import secretarioRoutes, { routesProfile as secretarioRoutesProfile } from 'routes/routes-secretario';
import professorRoutes, { routesProfile as professorRoutesProfile } from 'routes/routes-professor';
import coordenadorRoutes, { routesProfile as coordenadorRoutesProfile } from 'routes/routes-coordenador-pedagogico';
import alunoRoutes, { routesProfile as alunoRoutesProfile } from 'routes/routes-aluno';
import paisRoutes, { routesProfile as paisRoutesProfile } from 'routes/routes-pais';
import raizRoutes from 'routes/routes-raiz';

import BaseService from 'services/BaseService';

const hist = createBrowserHistory();
BaseService.hist = hist;

class App extends React.Component {
  render = () => <Router history={hist}>
    <Switch>
      <Redirect exact from="/gestor" to="/gestor/dashboard" />
      <Redirect exact from="/diretor" to="/diretor/dashboard" />
      <Redirect exact from="/secretario" to="/secretario/dashboard" />
      <Redirect exact from="/professor" to="/professor/dashboard" />
      <Redirect exact from="/coordenadorpedagogico" to="/coordenadorpedagogico/dashboard" />
      <Redirect exact from="/aluno" to="/aluno/turmas" />
      <Redirect exact from="/pais" to="/pais/turmas" />
      <Redirect exact from="/admin" to="/admin/dashboard" />

      <Route path="/gestor" render={(props) => <SideBarLayout layout="/gestor" routes={gestorRoutes} routesProfile={gestorRoutesProfile} {...props} />} />
      <Route path="/diretor" render={(props) => <SideBarLayout layout="/diretor" routes={diretorRoutes} routesProfile={diretorRoutesProfile} {...props} />} />
      <Route path="/secretario" render={(props) => <SideBarLayout layout="/secretario" routes={secretarioRoutes} routesProfile={secretarioRoutesProfile} {...props} />} />
      <Route path="/professor" render={(props) => <SideBarLayout layout="/professor" routes={professorRoutes} routesProfile={professorRoutesProfile} {...props} />} />
      <Route path="/coordenadorpedagogico" render={(props) => <SideBarLayout layout="/coordenadorpedagogico" routes={coordenadorRoutes} routesProfile={coordenadorRoutesProfile} {...props} />} />
      <Route path="/aluno" render={(props) => <SideBarLayout layout="/aluno" routes={alunoRoutes} routesProfile={alunoRoutesProfile} {...props} />} />
      <Route path="/pais" render={(props) => <SideBarLayout layout="/pais" routes={paisRoutes} routesProfile={paisRoutesProfile} {...props} />} />
      <Route path="/admin" render={(props) => <SideBarLayout layout="/admin" routes={adminRoutes} routesProfile={adminRoutesProfile} {...props} />} />
      <Route path="/" render={(props) => <RootLayout layout="/" routes={raizRoutes} {...props} />} />
    </Switch>
  </Router>
}

export default App;
