import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Button,
} from 'reactstrap';

import Select from 'react-select';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoEnte from 'assets/csv/situacoes-entes.json';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';

import estados from 'assets/csv/estados.json';
import municipios from 'assets/csv/municipios.json';

class ConvitesGestores extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoEnte.ATIVO,
      SituacaoEnte.BLOQUEADO,
    ];

    this.idPagina = 'entes';
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.loginService = new LoginService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      estadoState: 'primary',
      municipioState: 'primary',

      estadoSelecionado: '',
      municipioSelecionado: '',
      municipiosDoEstado: undefined,

      dadosTabela: {
        header: [['Nome', '25%'], ['Estado', '20%'], ['Situação', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-left'],
        keys: ['nome', 'estado_str', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    };

    this.carregarDadosEntes = this.carregarDadosEntes.bind(this);
  }

  async componentDidMount() {
    this.carregarEntes(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarEntes(pagina, linhasPorPagina, like) {
    try {
      const data = await this.entesService
        .carregarEntes(pagina, linhasPorPagina, like);

      await this.carregarDadosEntes(data);

      data.entes.forEach((ente) => {
        Object.assign(ente, {
          situacao_str: this.situacoes[ente.situacao].label,
          estado_str: ente.estado.uf,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.entes, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar entes cadastrados',
      });
    }
  }

  async carregarDadosEntes(data) {
    const fn = (ente) => new Promise((resolve) => resolve(this.entesService
      .carregarDadosEntePorIdEnte(ente.id)));

    const actions = data.entes.map(fn);

    const dadosEntes = await Promise.all(actions);

    data.entes.forEach(async (ente, idx) => {
      Object.assign(ente, { dados: dadosEntes[idx] });
    });
  }

  validarCampos() {
    let ret = true;
    if (!this.state.estadoSelecionado) {
      this.setState({ estadoState: 'danger' });
      ret = false;
    }
    if (!this.state.municipioSelecionado) {
      this.setState({ municipioState: 'danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarEnte() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
    });

    try {
      const ente = {
        nome: this.state.municipioSelecionado.label,
        estado: {
          id: this.state.estadoSelecionado.id,
        },
      };

      await this.entesService.cadastrarEnte(ente);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ente cadastrado com sucesso',
      });

      this.carregarEntes(this.paginaSelecionada, this.linhasPorPagina, this.like);

      this.limparFormulario();
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar ente',
      });
    }
  }

  limparFormulario() {
    this.setState({
      municipioSelecionado: '',
      estadoSelecionado: '',
    });
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Entes</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Cadastrar Ente</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Estado *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select ${this.state.estadoState}`}
                        classNamePrefix="react-select"
                        value={this.state.estadoSelecionado}
                        onChange={(event) => {
                          this.setState({
                            estadoState: 'primary',
                            estadoSelecionado: event,
                            municipioSelecionado: '',
                            situacaoEnteSelecionado: undefined,
                            municipiosDoEstado: municipios[event.value].sort(
                              (a, b) => a.label.localeCompare(b.label),
                            ),
                          });
                        }}
                        options={estados}
                        placeholder="Estado..."
                      />
                      {this.state.estadoState === 'danger' ? (
                        <label className="error">
                          Informe o estado.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Município *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select ${this.state.municipioState}`}
                        classNamePrefix="react-select"
                        value={this.state.municipioSelecionado}
                        onChange={(event) => {
                          this.setState({
                            municipioState: 'primary',
                            municipioSelecionado: event,
                          });
                        }}
                        options={this.state.municipiosDoEstado}
                        placeholder="Município..."
                      />
                      {this.state.municipioState === 'danger' ? (
                        <label className="error">
                          Informe o município.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="1">
                    <Button
                      style={{ marginTop: '25px' }}
                      type="button" color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.cadastrarEnte()}>
                      Cadastrar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Entes</CardTitle>
              </CardHeader>
              <CardBody>
                <AdaptiveTable
                  selectedPage={this.paginaSelecionada}
                  rowsPerPage={this.linhasPorPagina}
                  disableActions
                  clickRows
                  rowsPerPageCallback={(info) => {
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.linhasPorPagina = info.rowsPerPage;
                    sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                    this.carregarEntes(this.paginaSelecionada, info.rowsPerPage, this.like);
                  }}
                  likeCallback={(text) => {
                    this.like = text;
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarEntes(this.paginaSelecionada, this.linhasPorPagina, text);
                  }}
                  paginatorCallback={(page) => {
                    this.paginaSelecionada = page;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarEntes(this.paginaSelecionada, this.linhasPorPagina, this.like);
                  }}
                  rowCallback={(ente) => this.props.history.push({ pathname: `/admin/entes/${ente.id}` })}
                  data={this.state.dadosTabela} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={'Cadastrando ente...'} />
      </div>
    );
  }
}

export default ConvitesGestores;
