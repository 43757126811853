import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap';

import ReCAPTCHA from 'react-google-recaptcha';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import TermosDeUsoModal from 'views/components/TermosDeUsoModal';

import CadastroService from 'services/CadastroService';

import RootFooter from './Footer';

class Cadastro extends React.Component {
  constructor(props) {
    super(props);

    this.chave = this.props.match.params.chave;

    this.loginRef = React.createRef();
    this.nomeRef = React.createRef();
    this.senhaRef = React.createRef();
    this.confirmacaoSenhaRef = React.createRef();
    this.recaptchaRef = React.createRef();

    this.state = {
      showAlert: false,

      loginState: '',
      nomeState: '',
      senhaState: '',
      confirmacaoSenha: '',

      senhaErro: '',
      confirmacaoSenhaErro: '',

      buttonDisabled: false,
    };

    this.cadastrar = this.cadastrar.bind(this);
    this.redoCaptcha = this.redoCaptcha.bind(this);
    this.onVerifyRecaptcha = this.onVerifyRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

    this.cadastroService = new CadastroService();
  }

  componentWillUnmount() {
    this.cadastroService.abortPedingRequests();
  }

  validarCampos() {
    const loginRegex = /^[a-zA-Z][a-z0-9_.]+$/g;

    let ret = true;
    if (!this.state.termosDeUso) {
      this.setState({ termosDeUsoState: 'has-danger' });
      ret = false;
    }
    if (this.loginRef.current.value === '') {
      this.setState({
        loginState: 'has-danger',
        loginErroMsg: 'Informe um login para acesso',
      });
      ret = false;
    } else if (!this.loginRef.current.value.match(loginRegex)) {
      this.setState({
        loginState: 'has-danger',
        loginErroMsg: 'O login deve começar com letra e conter apenas letras, números, _ e .',
      });
      ret = false;
    }
    if (this.nomeRef.current.value === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (this.senhaRef.current.value === '' || this.confirmacaoSenhaRef.current.value === '') {
      if (this.senhaRef.current.value === '') {
        this.setState({
          senhaState: 'has-danger',
          senhaErro: 'Informe uma senha.',
        });
        ret = false;
      }
      if (this.confirmacaoSenhaRef.current.value === '') {
        this.setState({
          confirmacaoSenhaState: 'has-danger',
          confirmacaoSenhaErro: 'Informe a confirmação da senha.',
        });
        ret = false;
      }
    } else if (this.senhaRef.current.value !== this.confirmacaoSenhaRef.current.value) {
      this.setState({
        senhaState: 'has-danger',
        confirmacaoSenhaState: 'has-danger',
        senhaErro: 'A senha difere da cofirmação de senha',
        confirmacaoSenhaErro: 'A senha difere da cofirmação de senha',
      });
      ret = false;
    } else if (this.senhaRef.current.value.length < 6) {
      this.setState({
        senhaState: 'has-danger',
        confirmacaoSenhaState: 'has-danger',
        senhaErro: 'A senha deve conter no mínimo seis caracteres',
        confirmacaoSenhaErro: 'A senha deve conter no mínimo seis caracteres',
      });
      ret = false;
    } else if (this.senhaRef.current.value.indexOf(' ') !== -1) {
      this.setState({
        senhaState: 'has-danger',
        confirmacaoSenhaState: 'has-danger',
        senhaErro: 'A senha não pode conter espaços em branco',
        confirmacaoSenhaErro: 'A senha não pode conter espaços em branco',
      });
      ret = false;
    } else {
      this.setState({
        senhaState: 'has-success',
        confirmacaoSenhaState: 'has-success',
      });
    }

    return ret;
  }

  async cadastrar() {
    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.recaptchaRef.current.execute();
  }

  async cadastrarUsuario() {
    this.setState({ buttonDisabled: true, showAlert: false });

    const cadastro = {
      login: this.loginRef.current.value,
      nome: this.nomeRef.current.value,
      chave: this.props.match.params.chave,
      senha: this.senhaRef.current.value,
    };

    try {
      await this.cadastroService.cadastrar(cadastro);

      this.limparDadosFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Cadastro realizado com sucesso',
      });
    } catch (msg) {
      this.recaptchaRef.current.reset();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao realizar cadastro',
      });
    }
  }

  limparDadosFormulario() {
    this.loginRef.current.value = '';
    this.nomeRef.current.value = '';
    this.senhaRef.current.value = '';
    this.confirmacaoSenhaRef.current.value = '';

    this.setState({
      termosDeUso: false,
    });
  }

  redoCaptcha() {
    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
  }

  async onVerifyRecaptcha() {
    this.cadastrarUsuario();
  }

  onErrorRecaptcha() {
    this.setState({
      buttonDisabled: false,
      showAlert: true,
      alertColor: 'danger',
      alertMsg: 'Erro: sua interação foi identificada como um robô. Por favor, recarregue a página e tente novamente.',
    });
  }

  render() {
    return <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li><NavLink to="/">&nbsp;</NavLink></li>
            </ul>
          </nav>
        </div>
      </header>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Cadastro</h2>
              <ol>
                <li><NavLink to="/">Início</NavLink></li>
                <li>Cadastro</li>
              </ol>
            </div>
          </div>
        </section>
        <div className="container">
          <Alert
            style={{ marginTop: '20px' }}
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
        </div>
        <section className="inner-page">
          <div className="container">
            <Row>
              <Col className="mr-auto" md={{ size: 6, offset: 3 }}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.cadastrar();
                  }}>
                  <Card className="card-signup text-left" style={{ padding: '20px' }}>
                    <CardHeader>
                      <CardTitle tag="h4">Cadastro</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className={`has-label ${this.state.loginState}`}>
                        <label>Login *</label>
                        <Input
                          innerRef={this.loginRef}
                          type="text"
                          maxLength="50"
                          onChange={() => this.setState({ loginState: '' })}
                        />
                        {this.state.loginState === 'has-danger' ? (
                          <label className="error">
                            {this.state.loginErroMsg}
                          </label>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label ${this.state.nomeState}`}>
                        <label>Nome completo *</label>
                        <Input
                          innerRef={this.nomeRef}
                          type="text"
                          maxLength="200"
                          onChange={() => this.setState({ nomeState: '' })}
                        />
                        {this.state.nomeState === 'has-danger' ? (
                          <label className="error">
                            Informe o seu nome completo.
                          </label>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label ${this.state.senhaState}`}>
                        <label>Senha *</label>
                        <Input
                          innerRef={this.senhaRef}
                          type="password"
                          maxLength="200"
                          onChange={() => this.setState({ senhaState: '' })}
                        />
                        {this.state.senhaState === 'has-danger' ? (
                          <label className="error">
                            {this.state.senhaErro}
                          </label>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label ${this.state.confirmacaoSenhaState}`}>
                        <label>Confirmação de senha *</label>
                        <Input
                          innerRef={this.confirmacaoSenhaRef}
                          type="password"
                          maxLength="200"
                          onChange={() => this.setState({ confirmacaoSenhaState: '' })}
                        />
                        {this.state.confirmacaoSenhaState === 'has-danger' ? (
                          <label className="error">
                            {this.state.confirmacaoSenhaErro}
                          </label>
                        ) : null}
                      </FormGroup>
                      <Row>
                        <Col className="text-center">
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            size="invisible"
                            onExpired={this.redoCaptcha}
                            onChange={this.onVerifyRecaptcha}
                            onError={this.onErrorRecaptcha}
                            sitekey={env.SITEKEY}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-left">
                          <FormGroup check className={`has-label ${this.state.termosDeUsoState}`}>
                            <FormGroup>
                              <Label check>
                                <Input
                                  checked={this.state.termosDeUso}
                                  onChange={() => this.setState({
                                    termosDeUso: !this.state.termosDeUso,
                                    termosDeUsoState: '',
                                  })}
                                  type="checkbox" />
                                <span className="form-check-sign" style={{ marginTop: '50px' }} />
                              </Label>
                              <div style={{ marginLeft: '45px', marginTop: '-3px' }}>
                                Li e aceito os {' '}
                                <Link to="#"
                                  onClick={() => this.setState({
                                    showTermosDeUsoModal: true,
                                  })}>
                                  Termos de Uso
                              </Link>
                              </div>
                            </FormGroup>
                            {this.state.termosDeUsoState === 'has-danger' ? (
                              <label className="error">
                                Você deve aceitar os termos de uso
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                        Cadastrar
                    </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </section>
      </main>
      <RootFooter />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Cadastrando...' />
      <TermosDeUsoModal
        isOpen={this.state.showTermosDeUsoModal}
        closeCallback={() => this.setState({ showTermosDeUsoModal: false })} />
    </>;
  }
}

export default Cadastro;
