/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Button,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Papel from 'assets/csv/papeis.json';

class RelatoriosTransportes extends React.Component {
  render = () => <div className="content">
    <Row>
      <Col md="12">
        <Breadcrumb>
          <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
          <BreadcrumbItem active>Relatórios de transportes</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle></CardTitle>
          </CardHeader>
          <CardBody>
            <PerfectScrollbar className="sisa-perfect-scrollbar">
              <Table hover>
                <thead className="text-primary">
                  <tr>
                    <th>Relatórios de transportes</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.role === Papel.GESTOR.value && <tr
                      className='tr-clickable'
                      onClick={() => this.props.history.push(`${this.props.layout}/relatorios/transportes/passageirosportransporte`)}>
                      <td>Relação de passageiros por transporte</td>
                    </tr>
                  }
                  <tr
                      className='tr-clickable'
                      onClick={() => this.props.history.push(`${this.props.layout}/relatorios/transportes/passageirosporturma`)}>
                      <td>Relação de passageiros por turma</td>
                    </tr>
                </tbody>
              </Table>
            </PerfectScrollbar>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="6" className="text-left">
                <Button
                  color="primary"
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios`)} >
                  Voltar
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </div>
}

export default RelatoriosTransportes;
