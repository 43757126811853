import BaseService from './BaseService';

class PlanosService extends BaseService {
  carregarPlano(idPlano) {
    const requestInfo = {
      method: 'get',
      url: `/plano/${idPlano}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarPlanos() {
    const requestInfo = {
      method: 'get',
      url: '/plano',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarPlano(plano) {
    const requestInfo = {
      method: 'put',
      url: '/plano',
      data: JSON.stringify(plano),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarPlano(plano) {
    const requestInfo = {
      method: 'put',
      url: '/plano',
      data: JSON.stringify(plano),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  assinarPlano(plano) {
    const requestInfo = {
      method: 'post',
      url: '/plano/assinar',
      data: JSON.stringify(plano),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default PlanosService;
