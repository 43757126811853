import BaseService from './BaseService';

class DiasCanceladosService extends BaseService {
  carregarDiasCanceladosPorAnoLetivo(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/diascancelados/anoletivo/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DiasCanceladosService;
