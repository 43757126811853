import React from 'react';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';

import moment from 'moment';
import Select from 'react-select';

import TipoEvento from 'assets/csv/tipos-evento.json';

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);

    this.tiposEvento = [
      TipoEvento.AVISO,
      TipoEvento.ATIVIDADE,
      TipoEvento.PROVA,
    ]

    this.state = {
      isOpen: props.isOpen,
      id: props.evento.id,
      titulo: props.evento.titulo || '',
      tipoEventoSelecionado: this.props.evento.tipo ? this.tiposEvento[this.props.evento.tipo] : '',
      descricao: props.evento.descricao || '',
      diaInteiro: this.props.evento.diaInteiro || true,
      inicio: props.evento.inicio,
      fim: props.evento.fim,
      data: moment(props.evento.inicio).format('DD/MM/YYYY'),
    };

  }

  componentWillReceiveProps(nextProps) {
    const data = moment(nextProps.evento.inicio).format('DD/MM/YYYY');

    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (this.state.id !== nextProps.evento.id) {
      this.setState({ id: nextProps.evento.id });
    }
    if (this.state.titulo !== nextProps.evento.titulo) {
      this.setState({ titulo: nextProps.evento.titulo });
    }
    if (this.state.titulo !== nextProps.evento.tipo) {
      this.setState({ tipoEventoSelecionado: this.tiposEvento[nextProps.evento.tipo] });
    }
    if (this.state.descricao !== nextProps.evento.descricao) {
      this.setState({ descricao: nextProps.evento.descricao });
    }
    if (this.state.diaInteiro !== nextProps.evento.diaInteiro) {
      this.setState({ diaInteiro: nextProps.evento.diaInteiro });
    }
    if (this.state.inicio !== nextProps.evento.inicio) {
      this.setState({ inicio: nextProps.evento.inicio });
    }
    if (this.state.fim !== nextProps.evento.fim) {
      this.setState({ fim: nextProps.evento.fim });
    }
    if (this.state.data !== data) {
      this.setState({ data });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.titulo) {
      this.setState({ tituloState: 'has-danger' });
      ret = false;
    }

    if (!this.state.tipoEventoSelecionado) {
      this.setState({ tipoEventoState: 'danger' });
      ret = false;
    }

    if (!this.state.descricao) {
      this.setState({ descricaoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  cadastrar() {
    if (!this.validarCampos()) return;

    this.limparCampos();

    this.props.callbackCadastrar({
      id: this.state.id,
      titulo: this.state.titulo,
      tipo: this.state.tipoEventoSelecionado.value,
      diaInteiro: this.state.diaInteiro,
      inicio: moment(this.state.inicio).format('DD/MM/YYYY HH:mm:ss'),
      fim: moment(this.state.fim).format('DD/MM/YYYY HH:mm:ss'),
      descricao: this.state.descricao,
    });
  }

  excluir() {
    this.props.callbackExcluir(this.state.id);
  }

  limparCampos() {
    this.setState({
      titulo: '',
      tipoEventoSelecionado: '',
      descricao: '',

      tituloState: '',
      tipoEventoState: '',
      descricaoState: '',
    })
  }

  render() {
    return (
      <Modal size="lg"
        isOpen={this.state.isOpen}>
        <ModalBody>
          <h4>{!this.state.id ? 'Cadastro' : 'Edição'} de evento - {this.state.data}</h4>
          <Row>
            <Col md="8">
              <FormGroup className={`has-label ${this.state.tituloState}`}>
                <label>Título *</label>
                <Input
                  disabled={this.props.onlyRead}
                  value={this.state.titulo}
                  type="text"
                  maxLength="100"
                  onChange={(evt) => this.setState({
                    titulo: evt.target.value,
                    tituloState: 'has-success',
                  })}
                />
                {this.state.tituloState === 'has-danger' ? (
                  <label className="error">
                    Informe o nome título do evento.
                  </label>
                ) : null}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup className={'has-label has-danger'}>
                <label>Tipo do evento *</label>
                <Select
                  isDisabled={this.props.onlyRead}
                  noOptionsMessage={() => 'Nenhuma entrada'}
                  className={`react-select primary ${this.state.tipoEventoState}`}
                  classNamePrefix="react-select"
                  value={this.state.tipoEventoSelecionado}
                  onChange={(event) => {
                    this.setState({ tipoEventoState: '', tipoEventoSelecionado: event });
                  }}
                  options={this.tiposEvento}
                  placeholder="Tipo do evento..."
                />
                {this.state.tipoEventoState === 'danger' ? (
                  <label className="error">
                    Informe o tipo do evento.
                  </label>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className={`has-label ${this.state.descricaoState}`}>
                <label>Descrição *</label>
                <Input
                  disabled={this.props.onlyRead}
                  value={this.state.descricao}
                  onChange={(evt) => this.setState({
                    descricao: evt.target.value,
                    descricaoState: 'has-success',
                  })}
                  type="textarea"
                  style={{ height: '200px' }} />
                {this.state.descricaoState === 'has-danger' ? (
                  <label className="error">
                    Informe a descrição do evento.
                  </label>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {
              !this.props.onlyRead && this.state.id && <Col>
                <Button
                  color="danger"
                  type="button"
                  onClick={() => this.excluir()}>
                  Excluir
                </Button>
              </Col>
            }
            <Col className="text-right">
              <Button
                color="default"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  this.limparCampos();
                  this.setState({ isOpen: false })
                }}>
                { this.props.onlyRead ? 'Fechar' : 'Cancelar' }
              </Button>
              {
                !this.props.onlyRead && <Button
                  color="primary"
                  type="button"
                  onClick={() => this.cadastrar()}>
                  {!this.state.id ? 'Cadastrar' : 'Atualizar'}
                </Button>
              }
            </Col>
          </Row>
        </ModalBody>



      </Modal >
    );
  }
}

export default ConfirmModal;
