import BaseService from './BaseService';

class SolicitacaoMatriculaService extends BaseService {
  carregarSolicitacaoMatricula(idSolicitacaoMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/solicitacaomatricula/${idSolicitacaoMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSolicitacoesMatricula(idAnoLetivo, idEscola, situacao, pagina, linhasPorPagina, like) {
    let url = `/solicitacaomatricula/anoletivo/${idAnoLetivo}/escola/${idEscola}/situacao/${situacao}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSolicitacoesMatriculaAluno(idAnoLetivo, idAluno) {
    let url = `/solicitacaomatricula/anoletivo/${idAnoLetivo}/aluno/${idAluno}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmasMatriculaAluno(idAnoLetivo, idAluno) {
    let url = `/solicitacaomatricula/anoletivo/${idAnoLetivo}/aluno/${idAluno}/turmas`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  solicitarMatricula(solicitacaoMatricula) {
    const requestInfo = {
      method: 'post',
      url: '/solicitacaomatricula',
      data: JSON.stringify(solicitacaoMatricula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cancelarSolicitacaoMatricula(idSolicitacaoMatricula) {
    const requestInfo = {
      method: 'post',
      url: `/solicitacaomatricula/${idSolicitacaoMatricula}/cancelar`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  aceitarSolicitacaoMatricula(idSolicitacaoMatricula) {
    const requestInfo = {
      method: 'post',
      url: `/solicitacaomatricula/${idSolicitacaoMatricula}/aceitar`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  recusarSolicitacaoMatricula(idSolicitacaoMatricula) {
    const requestInfo = {
      method: 'post',
      url: `/solicitacaomatricula/${idSolicitacaoMatricula}/recusar`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAnexosSolicitacaoMatricula(idSolicitacaoMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/solicitacaomatricula/${idSolicitacaoMatricula}/anexos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default SolicitacaoMatriculaService;
