import BaseService from './BaseService';

class CadastroService extends BaseService {
  cadastrar(dados) {
    const requestInfo = {
      method: 'post',
      url: '/cadastro',
      data: JSON.stringify(dados),
      headers: {
        'Content-type': 'application/json',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarGestor(dados) {
    const requestInfo = {
      method: 'post',
      url: '/cadastro/gestor/convite',
      data: JSON.stringify(dados),
      headers: {
        'Content-type': 'application/json',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarAluno(dados) {
    const requestInfo = {
      method: 'post',
      url: '/cadastro/aluno',
      data: JSON.stringify(dados),
      headers: {
        'Content-type': 'application/json',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default CadastroService;
