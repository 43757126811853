import React from 'react';

import { connect } from 'react-redux';

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import ArquivosTurmaVirtualService from 'services/ArquivosTurmaVirtualService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';

class ArquivosTurmaVirtual extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-arquivosturmavirtual-${this.props.match.params.idTurma}-${this.props.match.params.idMateria}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.loginService = new LoginService();
    this.arquivosTurmaVirtualService = new ArquivosTurmaVirtualService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,
      showConfirmModal: false,
      showImportarAulaModal: false,

      erro: false,

      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };

    this.fileInputRef = React.createRef();

    this.fileChange = this.fileChange.bind(this);
  }

  async componentDidMount() {
    await this.carregarArquivosTurmaVirtual(this.paginaSelecionada,
      this.linhasPorPagina, this.like);
  }

  async carregarArquivosTurmaVirtual(pagina, linhasPorPagina, like) {
    try {
      const data = await this.arquivosTurmaVirtualService
        .carregarArquivosTurmaVirtual(this.props.match.params.idTurma,
          this.props.match.params.idMateria,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.arquivos, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  confirmarRemocaoArquivoTurmaVirtual(arquivo) {
    this.setState({ arquivoSelecionado: arquivo, showConfirmModal: true });
  }

  async removerArquivoTurmaVirtual() {
    this.props.callbackMessage({ showAlert: false });

    this.setState({
      buttonDisabled: true,
      loaderModalText: 'Removendo arquivo...',
    });

    try {
      await this.arquivosTurmaVirtualService
        .removerArquivoTurmaVirtual(this.state.arquivoSelecionado.id);

      this.setState({ buttonDisabled: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Arquivo removido com sucesso',
      });

      await this.carregarArquivosTurmaVirtual(this.paginaSelecionada,
        this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover arquivo',
      });
    }
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 10000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo para arquivo é 10MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.props.callbackMessage({ showAlert: false });
              this.setState({
                buttonDisabled: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              await this.arquivosTurmaVirtualService.salvarArquivoTurmaVirtual(
                this.props.match.params.idTurma, this.props.match.params.idMateria, arquivo,
              );

              await this.carregarArquivosTurmaVirtual(this.paginaSelecionada,
                this.linhasPorPagina, this.like);

              this.setState({ buttonDisabled: false });

              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'success',
                alertMsg: 'Arquivo salvo com sucesso.',
              });
            } catch (msg) {
              this.setState({ buttonDisabled: false });
              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao salvar arquivo selecionado.',
              });
            }
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  render() {
    return (
      <>
        <div className="fileinput text-center">
          <input
            type="file"
            onChange={this.fileChange}
            ref={this.fileInputRef} />
        </div>
        <Row>
          <Col>
            <AdaptiveTable
              disablePrintAction
              disableEditAction
              disableRemoveButton={this.props.match.parrole !== Papel.PROFESSOR.value}
              clickRows
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions={this.props.role !== Papel.PROFESSOR.value}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarArquivosTurmaVirtual(this.paginaSelecionada,
                  info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarArquivosTurmaVirtual(this.paginaSelecionada,
                  this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarArquivosTurmaVirtual(this.paginaSelecionada,
                  this.linhasPorPagina, this.like);
              }}
              removeButtonCallback={(arquivo) => this
                .confirmarRemocaoArquivoTurmaVirtual(arquivo)}
              rowCallback={(arquivo) => window.open(arquivo.url)}
              data={this.state.dadosTabela}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="text-left">
            <Button color="primary"
              onClick={() => this.props.history.goBack()}>
              Voltar
            </Button>
          </Col>
          <Col md="6" className="text-right">
            {
              this.props.role === Papel.PROFESSOR.value && <>
                <Button
                  color="primary"
                  disabled={this.state.buttonDisabled}
                  onClick={() => this.fileInputRef.current.click()} >
                  Inserir arquivo
                </Button>
              </>
            }
          </Col>
        </Row>
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerArquivoTurmaVirtual();
            } else {
              this.setState({ arquivoSelecionado: null });
            }
          }}
          text='Confirme a exclusão do arquivo' />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(ArquivosTurmaVirtual);
