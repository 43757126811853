import Noticias from 'views/gestor/Noticias';
import TurmasAluno from 'views/aluno/TurmasAluno';
import TurmaAluno from 'views/aluno/TurmaAluno';
import TurmaVirtualAluno from 'views/aluno/TurmaVirtualAluno';
import AulaAluno from 'views/aluno/AulaAluno';
import TarefaAluno from 'views/aluno/TarefaAluno';
import Ouvidoria from 'views/gestor/Ouvidoria';
import CadastroChamadoOuvidoria from 'views/gestor/CadastroChamadoOuvidoria';
import ChamadoOuvidoria from 'views/gestor/ChamadoOuvidoria';

import DadosUsuario from 'views/comum/DadosUsuario';
import MudarSenha from 'views/comum/MudarSenha';

import Papel from 'assets/csv/papeis.json';

const routes = [
  {
    path: '/noticias',
    name: 'Notícias',
    icon: 'far fa-newspaper',
    component: Noticias,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/turmas',
    name: 'Turmas',
    icon: 'fa fa-calendar',
    component: TurmasAluno,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/turmas/:idMatricula',
    component: TurmaAluno,
    redirect: true,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/turmas/:idMatricula/turma/:idTurma/materia/:idMateria/turmavirtual',
    component: TurmaVirtualAluno,
    redirect: true,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/turmas/:idMatricula/turma/:idTurma/materia/:idMateria/turmavirtual/aula/:idAula',
    component: AulaAluno,
    redirect: true,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/turmas/:idMatricula/turma/:idTurma/materia/:idMateria/turmavirtual/tarefa/:idTarefa',
    component: TarefaAluno,
    redirect: true,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/ouvidoria/chamados',
    component: Ouvidoria,
    redirect: true,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/ouvidoria/chamados/cadastrar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria/editar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria',
    component: ChamadoOuvidoria,
    layout: '/aluno',
    redirect: true,
    role: Papel.ALUNO.value,
  },
];

const routesProfile = [
  {
    path: '/dados',
    name: 'Meus Dados',
    mini: 'M',
    component: DadosUsuario,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },
  {
    path: '/mudarsenha',
    name: 'Alterar senha',
    mini: 'A',
    component: MudarSenha,
    layout: '/aluno',
    role: Papel.ALUNO.value,
  },

];

export { routesProfile };
export default routes;
