import React from 'react';

import { connect } from 'react-redux';

import AdaptiveTable from 'views/components/AdaptiveTable';

import DiariosService from 'services/DiariosService';

import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class RelatorioSituacaoDiariosAbertosOuConsolidados extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-relatorio-situacao-diarios-situacao-${this.props.situacao}-${this.props.idAnoLetivo}-${this.props.idEscola}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    const curso = this.props.dadosUsuario.ente !== 'Patos/PB' ? 'Curso' : 'Série';

    this.state = {
      dadosTabela: {
        header: [['Nome', '15%'], ['Escola', '30%'], ['Turma', '15%'], [curso, '30%'], ['Situação', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-left', 'text-left'],
        keys: ['nome', 'escola', 'nomeTurma', 'curso', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    };

    this.diariosService = new DiariosService();
  }

  async componentDidMount() {
    await this.carregarDiariosPorAnoLetivoEEscola(
      this.paginaSelecionada, this.linhasPorPagina, this.like,
    );
  }

  async carregarDiariosPorAnoLetivoEEscola(pagina, linhasPorPagina, like) {
    try {
      const data = await this.diariosService
        .carregarDiariosPorAnoLetivoEEscola(this.props.idAnoLetivo,
          this.props.idEscola, this.props.situacao, pagina, linhasPorPagina, like);

      data.diarios.forEach((diario) => {
        Object.assign(diario, { situacao_str: this.situacoes[diario.situacao].label });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.diarios, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  diarioSelecionado(diario) {
    if (diario.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      this.props.history
        .push(`${this.props.layout}/anosletivos/${this.props.idAnoLetivo}`
          + `/escola/${diario.idEscola}`
          + `/turma/${diario.idTurma}`
          + `/diario/${diario.id}`);
    } else {
      this.props.history
        .push(`${this.props.layout}/anosletivos/${this.props.idAnoLetivo}`
          + `/escola/${diario.idEscola}`
          + `/turma/${diario.idTurma}`
          + `/diario/${diario.id}/alfabetizacao`);
    }
  }

  render() {
    return (
      <div style={{ paddingTop: '20px' }}>
        <AdaptiveTable
          clickRows
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarDiariosPorAnoLetivoEEscola(
              this.paginaSelecionada, info.rowsPerPage, this.like,
            );
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarDiariosPorAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, text,
            );
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarDiariosPorAnoLetivoEEscola(
              this.paginaSelecionada, this.linhasPorPagina, this.like,
            );
          }}
          rowCallback={(diario) => this.diarioSelecionado(diario)}
          data={this.state.dadosTabela} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(RelatorioSituacaoDiariosAbertosOuConsolidados);
