import BaseService from './BaseService';

class EventosService extends BaseService {

  carregarEventosDoDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/evento/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEventosDaTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/evento/turma/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEvento(idDiario, evento) {
    let url = `/evento/diario/${idDiario}`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(evento),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEventoMultiseriado(idTurma, evento) {
    let url = `/evento/turma/multiseriado/${idTurma}`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(evento),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEvento(idEvento) {
    const requestInfo = {
      method: 'delete',
      url: `/evento/${idEvento}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEventoMultiseriado(idEvento) {
    const requestInfo = {
      method: 'delete',
      url: `/evento/turma/multiseriado/${idEvento}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default EventosService;
