import BaseService from './BaseService';

class DiariosService extends BaseService {
  carregarAbonos(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/abono/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAbonosMatricula(idDiario, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/abono/diario/${idDiario}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAbonosTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/abono/turma/multiseriado/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarAbonos(idDiario, abonos) {
    const requestInfo = {
      method: 'put',
      url: `/abono/diario/${idDiario}/atualizar`,
      data: JSON.stringify(abonos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarAbonosTurmaMultiseriado(idTurma, abonos) {
    const requestInfo = {
      method: 'put',
      url: `/abono/turma/multiseriado/${idTurma}/atualizar`,
      data: JSON.stringify(abonos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DiariosService;
