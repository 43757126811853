import React from 'react';

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';

import { connect } from 'react-redux';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import EstoquesService from 'services/EstoquesService';

class Estoques extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-estoques`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      showAlert: false,

      estoqueSelecionado: null,

      showLoaderModal: false,
      showConfirmModal: false,

      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    }

    this.estoquesService = new EstoquesService();

    this.carregarEstoques = this.carregarEstoques.bind(this);
    this.editarEstoque = this.editarEstoque.bind(this);
    this.removerEstoque = this.removerEstoque.bind(this);
    this.confirmarRemocaoEstoque = this.confirmarRemocaoEstoque.bind(this);

  }

  async componentDidMount() {
    this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarEstoques(pagina, linhasPorPagina, like) {
    try {
      const data = await this.estoquesService.carregarEstoques(pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.estoques, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar estoques cadastrados',
      });
    }
  }

  editarEstoque(estoque) {
    this.props.history.push({ pathname: `/gestor/estoques/edicao/${estoque.id}` });
  }

  confirmarRemocaoEstoque(estoque) {
    this.setState({ estoqueSelecionado: estoque, showConfirmModal: true });
  }

  async removerEstoque() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.estoquesService.removerEstoque(this.state.estoqueSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Estoque removido com sucesso',
      });

      this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover estoque',
      });
    }
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Estoques</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Estoques cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <AdaptiveTable
                  selectedPage={this.paginaSelecionada}
                  rowsPerPage={this.linhasPorPagina}
                  disablePrintAction
                  clickRows
                  editButtonCallback={(estoque) => this.editarEstoque(estoque)}
                  removeButtonCallback={(estoque) => this.confirmarRemocaoEstoque(estoque)}
                  rowsPerPageCallback={(info) => {
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.linhasPorPagina = info.rowsPerPage;
                    sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                    this.carregarEstoques(this.paginaSelecionada, info.rowsPerPage, this.like);
                  }}
                  likeCallback={(text) => {
                    this.like = text;
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, text);
                  }}
                  paginatorCallback={(page) => {
                    this.paginaSelecionada = page;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarEstoques(this.paginaSelecionada, this.linhasPorPagina, this.like);
                  }}
                  rowCallback={(estoque) => this.props.history.push({ pathname: `${this.props.layout}/estoques/${estoque.id}` })}
                  data={this.state.dadosTabela} />
              </CardBody>
              <CardFooter className="text-right">
                <Button color="primary"
                  onClick={() => {
                    this.props.history.push({ pathname: '/gestor/estoques/cadastro' });
                  }}>
                  Cadastrar estoque
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerEstoque();
            } else {
              this.setState({ estoqueSelecionado: null });
            }
          }}
          text='Confirme a exclusão do estoque' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo estoque...' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Estoques);
