import BaseService from './BaseService';

class DashboardService extends BaseService {
  carregarInformacoesGeraisAdmin() {
    const requestInfo = {
      method: 'get',
      url: '/dashboard/informacoes/gerais/admin',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarInformacoesGeraisGestor() {
    const requestInfo = {
      method: 'get',
      url: '/dashboard/informacoes/gerais/gestor',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarInformacoesGeraisDiretor() {
    const requestInfo = {
      method: 'get',
      url: '/dashboard/informacoes/gerais/diretor',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarInformacoesGeraisSecretario() {
    const requestInfo = {
      method: 'get',
      url: '/dashboard/informacoes/gerais/secretario',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarInformacoesGeraisCoordenador() {
    const requestInfo = {
      method: 'get',
      url: '/dashboard/informacoes/gerais/coordenador',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarInformacoesAnoLetivo(idAnoLetivo, idEscola) {
    let url = `/dashboard/informacoes/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarInformacoesGeraisProfessor() {
    const requestInfo = {
      method: 'get',
      url: '/dashboard/professor/informacoes/gerais',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmasDoProfessor(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/dashboard/professor/turmas/anoletivo/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosDoProfessor(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/dashboard/professor/diarios/anoletivo/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarInformacoesAnoLetivoProfessor(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/dashboard/professor/informacoes/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAcaoTracks(pagina, linhasPorPagina, like) {
    let url = `/dashboard/acaotrack/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DashboardService;
