function getCamposAprendizagemCreche(curso, idMateria) {
  const obj = [
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos, experimentando marcas da cultura corporal nos cuidados pessoais, na dança, música, teatro, artes circenses, escuta de histórias e brincadeiras.\n\nBRINCAR utilizando criativamente o repertório da cultura corporal e do movimento.\n\nEXPLORAR amplo repertório de movimentos, gestos, olhares, produção de sons e de mímicas, descobrindo modos de ocupação e de uso do espaço com o corpo.\n\nPARTICIPAR de atividades que envolvem práticas corporais, desenvolvendo autonomia para cuidar de si.\n\nEXPRESSAR corporalmente emoções e representações tanto nas relações cotidianas como nas brincadeiras, dramatizações, danças, músicas e contação de histórias.\n\nCONHECER-SE nas diversas oportunidades de interações e explorações com seu corpo.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI02CG01) Apropriar-se de gestos e movimentos de sua cultura no cuidado de si e nos jogos e brincadeiras.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '99A Participar de brincadeiras de faz de conta, utilizando como referência acontecimentos do cotidiano, personagens diversos, etc.',
              descricao2: '99B Brincar de faz de conta imitando e recriando situações vividas, enredos, cenários, personagens, etc.',
            },
            {
              ordem: 1,
              descricao: '100A Participar de situações práticas de manifestações culturais de sua localidade. ',
              descricao2: '100B Apreciar a escuta de diferentes estilos de música, dança e outras expressões da cultura corporal, a partir de experiências em que possa vivenciá-la. ',
            },
            {
              ordem: 2,
              descricao: '101A Assistir a diferentes manifestações da cultura local.',
              descricao2: '101B Recriar movimentos e gestos a partir de apresentações artísticas assistidas.',
            },
            {
              ordem: 3,
              descricao: '102A Participar de brincadeiras envolvendo cantigas de roda, parlendas, rimas, etc.',
              descricao2: '102B Imitar gestos e movimentos seguindo ritmos variados.',
            },
            {
              ordem: 4,
              descricao: '103A Participar de práticas de cuidado de si em casa e em ambientes sociais, como: higienizar-se, vestir-se e descansar.',
              descricao2: '103B Conversar sobre a necessidade de se ter bons hábitos de cuidado consigo e com os outros.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI02CG02) Deslocar seu corpo no espaço, orientando-se por noções como em frente, atrás, no alto, embaixo, dentro, fora etc., ao se envolver em brincadeiras e atividades de diferentes naturezas.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '104A Explorar diferentes espaços a partir de movimentos como: arrastar, correr, pular, pegar algo ou soltar.',
              descricao2: '104B Explorar diferentes espaços e situações de brincadeira em que sejam desafiados a: andar, correr, saltar, saltitar, pular, subir, escalar, arrastar-se, pendurar-se, balançar-se, equilibrar-se etc.',
            },
            {
              ordem: 1,
              descricao: '105A Brincar de esconder objetos, de movimentar partes do corpo ou de mover-se em determinado espaço a partir do comando do adulto ou de outras crianças.',
              descricao2: '105B Brincar a partir de circuitos, de diferentes perspectivas e com diferentes desafios.',
            },
            {
              ordem: 2,
              descricao: '106A Observar situações a partir de diversas perspectivas como, por exemplo, olhar pela janela, por debaixo da mesa, de cima de um banco, dentro de uma caixa, etc.',
              descricao2: '106B Percorrer ou inventar trajetos espontaneamente em situações de brincadeira.',
            },
            {
              ordem: 3,
              descricao: '107A Reconhecer o local onde estão seus pertences.',
              descricao2: '107B Envolver-se em práticas onde possam explorar diferentes espaços e percursos para chegarem em um dado destino, orientados pelo uso de noções de localização: distância/proximidade, à frente/atrás, embaixo/em cima.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI02CG03) Explorar formas de deslocamento no espaço (pular, saltar, dançar), combinando movimentos e seguindo orientações.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '108A Participar de brincadeiras e jogos teatrais em que se expresse por meio de gestos e movimentos.',
              descricao2: '108B Envolver-se em brincadeiras e jogos teatrais a partir de histórias, temas e personagens.',
            },
            {
              ordem: 1,
              descricao: '109A Participar de brincadeiras em que siga a orientação de um adulto ou de outra criança para movimentar-se de diferentes formas.',
              descricao2: '109B Realizar movimentos simples autonomamente ou orientados por um adulto ou outra criança, descrevendo seus movimentos enquanto os realiza.',
            },
            {
              ordem: 2,
              descricao: '110A Deslocar-se no espaço de diferentes modos: rolando, agachando, andando de frente ou de costas, etc.',
              descricao2: '110B Explorar junto com outras crianças espaços ainda não explorados e que por vez promovam novos desafios, por exemplo, espaços maiores, ou que requeiram delas mais movimento.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI02CG04) Demonstrar progressiva independência no cuidado do seu corpo.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '111A Realizar ações com auxílio do professor, como por exemplo, utilizar o vaso sanitário, limparse, lavar mãos, prender o cabelo, calçar o sapatos, cortar algum alimento, etc.',
              descricao2: '111B Agir autonomamente em situações em que precise fazer uso do vaso sanitário, vestir-se, ou retirar suas roupas, alimentar-se, escovar os dentes, pentear os cabelos, etc.',
            },
            {
              ordem: 1,
              descricao: '112A Conhecer seus pertences.',
              descricao2: '112B Utilizar utensílios que a auxilie a alimentar-se ou higienizar-se.',
            },
            {
              ordem: 2,
              descricao: '113A Realizar ações de cuidado por iniciativa própria.',
              descricao2: '113B Pedir auxílio sempre que necessário, como por exemplo, para calçar-se, limpar o nariz, limpar-se, etc.',
            },
            {
              ordem: 3,
              descricao: '114A Interessar-se por provar novos alimentos.',
              descricao2: '114B Interessar-se por provar e conhecer novos alimentos.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI02CG05) Desenvolver progressivamente as habilidades manuais, adquirindo controle para desenhar, pintar, rasgar, folhear, entre outros.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '115A Manusear diferentes materiais e objetos, por exemplo, explorar objetos frágeis como os feitos com papel.',
              descricao2: '115B Fazer uso de giz de cera, canetas coloridas, lápis para fazer seus registros gráficos.',
            },
            {
              ordem: 1,
              descricao: '116A Vivenciar experiências de pintura, desenho, rabiscos.',
              descricao2: '116B Experimentar práticas de manuseio de livros, cadernos, revistas.',
            },
            {
              ordem: 2,
              descricao: '117A Brincar de empilhar, montar, encaixar, etc.',
              descricao2: '117B Conhecer brinquedos e jogos de sua cultura local.',
            },
            {
              ordem: 3,
              descricao: '118A Experimentar situações em que possam se equilibrar, carregar, guardar e empilhar objetos.',
              descricao2: '118B Vivenciar situações de desenho, pintura, escrita, recorte e colagem a partir de diferentes instrumentos gráficos e em múltiplos suportes.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos em situações comunicativas cotidianas, constituindo modos de pensar, imaginar, sentir, narrar, dialogar e conhecer.\n\nBRINCAR com parlendas, trava-línguas, adivinhas, memória, rodas, brincadeiras cantadas, jogos e textos de imagens, escritos e outros, ampliando o repertório das manifestações culturais da tradição local e de outras culturas, enriquecendo sua linguagem oral, corporal, musical, dramática, escrita, dentre outras.\n\nPARTICIPAR de rodas de conversa, de relatos de experiências, de contação e leitura de histórias e poesias, de construção de narrativas, da elaboração, descrição e representação de papéis no faz de conta, da exploração de materiais impressos e de variedades linguísticas, construindo diversas formas de organizar o pensamento.\n\nEXPLORAR gestos, expressões, sons da língua, rimas, imagens, textos escritos, além dos sentidos das palavras nas poesias, parlendas, canções e nos enredos de histórias, apropriando-se desses elementos para criar novas falas, enredos, histórias e escritas, convencionais ou não.\n\nEXPRESSAR sentimentos, ideias, percepções, desejos, necessidades, pontos de vista, informações, dúvidas e descobertas, utilizando múltiplas linguagens, considerando o que é comunicado pelos colegas e adultos.\n\nCONHECER-SE e reconhecer suas preferências por pessoas, brincadeiras, lugares, histórias, autores, gêneros linguísticos e seu interesse em produzir com a linguagem verbal.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI02EF01) Dialogar com crianças e adultos, expressando seus desejos, necessidades, sentimentos e opiniões.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '241A Interagir com outras crianças e demais pessoas, falando sobre suas experiências pessoais, relatando fatos significativos, expressando-se e comunicando-se por meio do corpo, do movimento, da dança, da mímica, do som, da música, de suas esculturas, desenhos ou do teatro.',
              descricao2: '241B Expressar suas ideias, sentimentos e emoções por meio de diferentes linguagens, como a dança, o desenho, a mímica, a música, a oralidade e os rabiscos.',
            },
            {
              ordem: 1,
              descricao: '242A Utilizar palavras ou construções de frases simples para se expressar, iniciando diálogos estruturados, formulando perguntas e ampliando o vocabulário.',
              descricao2: '242B Interessar-se por interagir com outras crianças fazendo uso da linguagem verbal, tentando se fazer entender e tendo atenção ao escutar o outro.',
            },
            {
              ordem: 2,
              descricao: '243A Conversar sobre seus interesses em contextos de brincadeiras, jogos e atividades em pequenos grupos e pares.',
              descricao2: '243B Conversar sobre seus interesses em contextos de brincadeiras, jogos e atividades em pequenos grupos e pares.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI02EF02) Identificar e criar diferentes sons e reconhecer rimas e aliterações em cantigas de roda e textos poéticos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '244A Interessar-se por jogos com a linguagem, explorando seus sons, seus efeitos e intensidades.',
              descricao2: '244B Recitar poemas e parlendas criando diferentes entonações e ritmos.',
            },
            {
              ordem: 1,
              descricao: '245A Envolver-se em situações quais se divertem em brincadeiras de roda cantadas.',
              descricao2: '245B Declamar textos poéticos conhecidos nas brincadeiras como corre-cotia, pula corda etc.',
            },
            {
              ordem: 2,
              descricao: '246A Recitar parlendas em suas brincadeiras, criando novas rimas e divertindo-se com suas produções.',
              descricao2: '246B Criar sons enquanto canta ou cria uma música ou um poema.',
            },
            {
              ordem: 3,
              descricao: '247A Participar de situações de declamações, escutando histórias rimadas e brincando com o ritmo de uma declamação.',
              descricao2: '247B Divertir-se ao brincar com a linguagem, criando sons e reconhecendo rimas e aliterações.',
            },
            {
              ordem: 4,
              descricao: '248A Escutar e brincar com textos poéticos, como, por exemplo, as canções, os poemas, as parlendas e as histórias com rimas.',
              descricao2: '248B Brincar com as palavras que rimam nos textos, divertindo-se com seus sons ou criando novas rimas.',
            },
            {
              ordem: 5,
              descricao: '249A Recontar textos conhecidos utilizando-os em suas brincadeiras com a imitação de gestos e entonações dos personagens.',
              descricao2: '249B Recontar textos conhecidos utilizando-os em suas brincadeiras com a imitação de gestos e entonações dos personagens.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI02EF03) Demonstrar interesse e atenção ao ouvir a leitura de histórias e outros textos, diferenciando escrita de ilustrações, e acompanhando, com orientação do adulto-leitor, a direção da leitura (de cima para baixo, da esquerda para a direita).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '250A Interessar-se por aprender sobre a linguagem que é usada em cada tipo de texto, sua função, seu conteúdo e seu formato.',
              descricao2: '250B Interessar-se por aprender sobre a linguagem que é usada em cada tipo de texto, sua função, seu conteúdo e seu formato.',
            },
            {
              ordem: 1,
              descricao: '251A Fazer uso de diferentes técnicas, materiais e recursos gráficos para produzir ilustrações.',
              descricao2: '251B Fazer uso de diferentes técnicas, materiais e recursos gráficos para produzir ilustrações.',
            },
            {
              ordem: 2,
              descricao: '252A Refletir sobre a relação das ilustrações com o texto nas situações de leitura de histórias pelo adulto.',
              descricao2: '252B Perceber que algumas ilustrações apresentam o que está escrito na narrativa e outras complementam ou agregam uma nova informação ao texto.',
            },
            {
              ordem: 3,
              descricao: '253A Ordenar ilustrações de uma história e ilustrar momentos de narrativas.',
              descricao2: '253B Ordenar ilustrações de uma história e ilustrar momentos de narrativas.',
            },
            {
              ordem: 4,
              descricao: '254A Imitar alguns comportamentos e procedimentos típicos de leitores, como no ato de acompanhar com o dedo a parte do texto que está sendo lido, de identificar em um índice o número da página em que está o texto a ser lido, usar a ilustração como referência para lembrar uma passagem do texto, etc.',
              descricao2: '254B Buscar o título da história no índice, ler textos memorizados com a ajuda do(a) professor(a), fazendo uso de procedimentos como acompanhar o texto com o dedo seguindo da esquerda para a direita.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI02EF04) Formular e responder perguntas sobre fatos da história narrada, identificando cenários, personagens e principais acontecimentos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '255A Interessar-se pela escuta de histórias a partir de um repertório de narrações conhecidas.',
              descricao2: '255B Diferenciar histórias conhecidas considerando os principais acontecimentos, personagens e cenários.',
            },
            {
              ordem: 1,
              descricao: '256A Identificar regularidades nos diferentes textos, conversando e refletindo para além do seu conteúdo, mas também sobre sua estrutura.',
              descricao2: '256B Refletir sobre a organização textual da narrativa, respondendo a perguntas como: "quem?", "o quê?", "quando?", "como?" e "por quê?".',
            },
            {
              ordem: 2,
              descricao: '257A Falar sobre os personagens e cenários da história, identificando, por exemplo, algumas de suas características.',
              descricao2: '257B Identificar as ações e intenções dos personagens nas diferentes situações da narrativa.',
            },
            {
              ordem: 3,
              descricao: '258A Recontar ou dramatizar histórias a partir de suas ilustrações.',
              descricao2: '258B Interessar-se por identificar características dos personagens das histórias para incrementar cenários e adereços em suas brincadeiras de faz de conta.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI02EF05) Relatar experiências e fatos acontecidos, histórias ouvidas, filmes ou peças teatrais assistidos etc.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '259A Conversar umas com as outras, contando seus planos, suas experiências pessoais significativas, descrevendo objetos, acontecimentos e relações, brincando e construindo narrativas comuns, negociando papéis, cenários e lidando com possíveis conflitos.',
              descricao2: '259B Expressar-se verbalmente em conversas, narrações e brincadeiras, ampliando seu vocabulário e fazendo uso de estruturas orais que aprimorem suas competências comunicativas.',
            },
            {
              ordem: 1,
              descricao: '260A Contribuir em situações de conversas em grandes e pequenos grupos ou duplas, relatando suas experiências pessoais e interessando-se por escutar o relato dos colegas.',
              descricao2: '260B Contribuir em situações de conversas em grandes e pequenos grupos ou duplas, relatando suas experiências pessoais e interessando-se por escutar o relato dos colegas.',
            },
            {
              ordem: 2,
              descricao: '261A Compreender o conteúdo e o propósito de diferentes mensagens em diversos contextos.',
              descricao2: '261B Compreender o conteúdo e o propósito de diferentes mensagens em diversos contextos.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI02EF06) Criar e contar histórias oralmente, com base em imagens ou temas sugeridos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '262A Recontar histórias ao brincar de faz de conta.',
              descricao2: '262B Recontar histórias ao brincar de faz de conta.',
            },
            {
              ordem: 1,
              descricao: '263A Observar semelhanças e diferenças entre as histórias.',
              descricao2: '263B Fazer relações entre diferentes histórias conhecidas.',
            },
            {
              ordem: 2,
              descricao: '264A Contar aos(às) professores(as) e colegas histórias criadas ou conhecidas.',
              descricao2: '264B Ditar histórias criadas ou memorizadas aos(às) professores(as) e colegas.',
            },
            {
              ordem: 3,
              descricao: '265A Criar narrativas a partir da apreciação de fatos, imagens ou de temas que são do seu interesse.',
              descricao2: '265B Criar histórias com ou sem o apoio de imagens, fotos ou temas disparadores.',
            },
          ],
        },
        {
          ordem: 6,
          descricao: '(EI02EF07) Manusear diferentes portadores textuais, demonstrando reconhecer seus usos sociais.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '266A Explorar materiais escritos em seus contextos sociais por meio da brincadeira, como, por exemplo, brincar de correio, de escritório, de supermercado, de banco, de livraria etc.',
              descricao2: '266B Interessar-se por portadores textuais, buscando fazer uso deles nas práticas cotidianas segundo seus usos sociais.',
            },
            {
              ordem: 1,
              descricao: '267A Localizar seu nome na lista de nomes de sua turma, nos crachás e na identificação dos materiais escolares.',
              descricao2: '267B Identificar seu nome e de seus colegas na lista de nomes de sua turma, nos crachás e na identificação dos materiais escolares.',
            },
            {
              ordem: 2,
              descricao: '268A Folhear livros contando suas histórias para seus colegas em situações de livre escolha.',
              descricao2: '268B Folhear livros contando suas histórias para seus colegas em situações de livre escolha.',
            },
            {
              ordem: 3,
              descricao: '269A Produzir bilhetes, cartas e/ou cartões aos seus colegas ou familiares tendo o(a) professor(a) como escriba.',
              descricao2: '269B Escrever bilhetes, cartas e/ou cartões aos seus colegas ou familiares tendo o(a) professor(a) como escriba ou fazendo uso da escrita espontânea.',
            },
          ],
        },
        {
          ordem: 7,
          descricao: '(EI02EF08) Manipular textos e participar de situações de escuta para ampliar seu contato com diferentes gêneros textuais (parlendas, histórias de aventura, tirinhas, cartazes de sala, cardápios, notícias etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '270A Interessar-se por participar de situações de exploração de portadores de diferentes gêneros textuais em brincadeiras ou atividades de pequenos grupos.',
              descricao2: '270B Explorar textos, em seus suportes e usá-los em suas brincadeiras e atividades individuais ou em pequenos grupos.',
            },
            {
              ordem: 1,
              descricao: '271A Participar de diferentes situações de leitura de diversos gêneros textuais, como, por exemplo, histórias, parlendas, trava-línguas, receitas, capa de CDs e DVDs, entre outros.',
              descricao2: '271B Familiarizar-se com diferentes gêneros textuais, como, por exemplo, brincar recitando parlendas, buscar o jornal para encontrar informação sobre uma atividade cultural, participar de atividades de culinária fazendo uso de livros de receitas etc.',
            },
            {
              ordem: 2,
              descricao: '272A Participar de momentos coletivos de leitura e interação com textos digitais explorando seus recursos audiovisuais',
              descricao2: '272B Participar de momentos coletivos de leitura e interação com textos digitais explorando suas mídias, imagens, gráficos, etc',
            },
          ],
        },
        {
          ordem: 8,
          descricao: '(EI02EF09) Manusear diferentes instrumentos e suportes de escrita para desenhar, traçar letras e outros sinais gráficos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '273A Interessar-se pela escrita e sua representação gráfica, identificando nos textos a direção da escrita, bem como a presença de letras e de desenhos.',
              descricao2: '273B Interessar-se pela escrita e sua representação gráfica, identificando nos textos a direção da escrita, bem como a presença de letras e de desenhos.',
            },
            {
              ordem: 1,
              descricao: '274A Utilizar seus desenhos como uma forma de comunicação.',
              descricao2: '274B Utilizar seus desenhos como uma forma de comunicação.',
            },
            {
              ordem: 2,
              descricao: '275A Experimentar escrever seu nome com rabiscos sempre que for necessário.',
              descricao2: '275B Escrever o nome à sua maneira sempre que for necessário.',
            },
            {
              ordem: 3,
              descricao: '276A Fazer uso de garatujas com a intenção de uma comunicação escrita.',
              descricao2: '276B Fazer uso de garatujas, rabiscos e letras (ainda que de forma não convencional), em seus registros de comunicação.',
            },
            {
              ordem: 4,
              descricao: '277A Imitar comportamentos de escritor ao fazer de conta que escrevem recados e textos diversos',
              descricao2: '277B Produzir textos diversos e comunicações escritas com objetivos e destinatários específicos',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos e com eles investigar o mundo natural e social.\n\nBRINCAR com materiais, objetos e elementos da natureza e de diferentes culturas e perceber a diversidade de formas, texturas, cheiros, cores, tamanhos, pesos e densidades que apresentam.\n\nEXPLORAR características do mundo natural e social, nomeando-as, agrupando-as e ordenando-as segundo critérios relativos às noções de espaços, tempos, quantidades, relações e transformações.\n\nPARTICIPAR de atividades de investigação de características de elementos naturais, objetos, situações, espaços, utilizando ferramentas de exploração — bússola, lanterna, lupa — e instrumentos de registro e comunicação, como máquina fotográfica, filmadora, gravador, projetor e computador.\n\nEXPRESSAR suas observações, explicações e representações sobre objetos, organismos vivos, fenômenos da natureza, características do ambiente.\n\nCONHECER-SE e construir sua identidade pessoal e cultural, reconhecendo seus interesses na relação com o mundo físico e social.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI02ET01) Explorar e descrever semelhanças e diferenças entre as características e propriedades dos objetos (textura, massa, tamanho).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '344A Empilhar autonomamente objetos de várias cores, tamanhos e formas.',
              descricao2: '344B Empilhar objetos do maior para o menor e vice-versa.',
            },
            {
              ordem: 1,
              descricao: '345A Selecionar materiais encontrados na natureza como pedras, folhas e sementes definindo suas semelhanças e diferenças.',
              descricao2: '345B Selecionar materiais encontrados na natureza como pedras, folhas e sementes definindo suas semelhanças e diferenças.',
            },
            {
              ordem: 2,
              descricao: '346A Brincar com água e areia, provocando uma mistura entre esses materiais.',
              descricao2: '346B Utilizar água, areia e argila construindo esculturas de pessoas, animais, brinquedos, descrevendo as características (duro, mole, muito, pouco, etc).',
            },
            {
              ordem: 3,
              descricao: '347A Manusear tinta em papéis, tecidos e muros, misturando as cores.',
              descricao2: '347B Utilizar mistura de tinta de diversas cores, descrevendo o que acontece.',
            },
            {
              ordem: 4,
              descricao: '348A Manipular ingredientes provocando mudanças físicas em experiências de culinária.',
              descricao2: '348B Manipular ingredientes provocando mudanças físicas em experiências de culinária.',
            },
            {
              ordem: 5,
              descricao: '349A Organizar os objetos e materiais da sala conforme suas características',
              descricao2: '349B Organizar os objetos e materiais da sala conforme suas características',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI02ET02) Observar, relatar e descrever incidentes do cotidiano e fenômenos naturais (luz solar, vento, chuva etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '350A Relatar o que está vendo em situações relacionadas ao tempo (sol, chuva, vento).',
              descricao2: '350B Descrever e questionar o que ocorre quando o tempo está chuvoso, ensolarado, com vento, nublado.',
            },
            {
              ordem: 1,
              descricao: '351A Falar, a partir de observações, o que fazem os animais, como se locomovem, o que comem (mamíferos, répteis, aves).',
              descricao2: '351B Ouvir e perceber as características dos animais apresentadas em revistas, livros, vídeos.',
            },
            {
              ordem: 2,
              descricao: '352A Realizar investigações simples para descobrir como ocorrem as mudanças da natureza',
              descricao2: '352B Investigar causas e efeitos dos fenômenos da natureza',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI02ET03) Compartilhar, com outras crianças, situações de cuidado de plantas e animais nos espaços da instituição e fora dela.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '353A Envolver-se em experiências de cuidado das plantas, observando seu crescimento.',
              descricao2: '353B Participar do cultivo de hortas e jardins, responsabilizando-se em limpar, regar e cuidar das plantas, acompanhando o seu desenvolvimento.',
            },
            {
              ordem: 1,
              descricao: '354A Comunicar, a partir de observações, o que fazem os animais, como se locomovem, o que comem.',
              descricao2: '354B Descrever, a partir de investigações, os modos de vida dos animais como locomoção, alimentação e crescimento.',
            },
            {
              ordem: 2,
              descricao: '355A Observar, o crescimento de alimentos em hortas ou de outros tipos de plantas',
              descricao2: '355B Demonstrar iniciativa em responsabilizar-se por ações simples de cuidado com animais ou plantas de seu entorno',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI02ET04) Identificar relações espaciais (dentro e fora, em cima, embaixo, acima, abaixo, entre e do lado) e temporais (antes, durante e depois).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '356A Percorrer os diversos espaços da instituição, sabendo localizar-se dentro deles',
              descricao2: '356B Encontrar objetos ou brinquedos desejados nas situações de brincadeiras com indicações sobre a sua localização (acima, abaixo, dentro, fora, perto, longe, entre outras).',
            },
            {
              ordem: 1,
              descricao: '357A Narrar acontecimentos das atividades da rotina diária.',
              descricao2: '357B Conversar sobre os acontecimentos diários fazendo uso de expressões temporais que demonstrem a compreensão sobre o tempo em que ocorrem os fatos.',
            },
            {
              ordem: 2,
              descricao: '358A Participar de atividades de comando em que posicionem objetos, de acordo com as posições indicadas: dentro e fora, em cima, embaixo, acima, abaixo, do lado',
              descricao2: '358B Participar de brincadeiras que envolvam práticas de posicionar-se, envolvendo conceitos: dentro e fora, em cima, embaixo, acima, abaixo, do lado',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI02ET05) Classificar objetos, considerando determinado atributo (tamanho, peso, cor, forma etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '359A Selecionar diferentes brinquedos e materiais sob critérios relacionados a cor, forma e tamanho.',
              descricao2: '359B Fazer comparações entre determinados objetos, quanto aos seus atributos como: tamanho, peso e forma e cor.',
            },
            {
              ordem: 1,
              descricao: '360A Interessar-se por participar dos momentos de organização dos brinquedos da sala, usando seus atributos para agrupá-los',
              descricao2: '360B Organizar os brinquedos e materiais da sala, usando seus atributos para agrupá-los',
            },
            {
              ordem: 2,
              descricao: '361A Agrupar objetos segundo critérios próprios',
              descricao2: '361B Participar de atividades que envolvem a confecção de objetos, fazendo uso de diferentes materiais e selecionando-',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI02ET06) Utilizar conceitos básicos de tempo (agora, antes, durante, depois, ontem, hoje, amanhã, lento, rápido, depressa, devagar).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '362A Conversar sobre momentos de sua rotina na sala e na instituição.',
              descricao2: '362B Participar de relatos de acontecimentos, antecipando ou prevendo ações do cotidiano e favorecendo o uso de termos básicos de tempo como agora, antes, durante, depois.',
            },
            {
              ordem: 1,
              descricao: '363A Participar de brincadeiras onde sejam explorados diversos movimentos corporais com diferentes níveis de velocidades.',
              descricao2: '363B Obedecer, em brincadeiras, comandos para usar ritmos e velocidades diferentes, como depressa e lentamente.',
            },
            {
              ordem: 2,
              descricao: '364A Falar sobre acontecimentos ocorridos na sua vida cotidiana familiar, situando-os no tempo (ontem, hoje, amanhã)',
              descricao2: '364B Falar sobre acontecimentos ocorridos na sua vida cotidiana familiar, situando-os no tempo (ontem, hoje, amanhã)',
            },
          ],
        },
        {
          ordem: 6,
          descricao: '(EI02ET07) Contar oralmente objetos, pessoas, livros etc., em contextos diversos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '365A Vivenciar situações onde sejam lidas as quantidades e numerais como no seu calçado, no telefone, na sala.',
              descricao2: '365B Encontrar os números em contextos sociais reais como nas brincadeiras de faz de conta, nas quais façam uso da leitura espontânea da fita métrica, calculadora, teclado de computador.',
            },
            {
              ordem: 1,
              descricao: '366A Participar de situações onde seja necessário contar e dividir objetos e brinquedos.',
              descricao2: '366B Distribuir brinquedos e outros materiais, contando-os de modo que nenhum colega deixe de receber.',
            },
            {
              ordem: 2,
              descricao: '367A Brincar de cantigas que envolvam a recitação da sequência numérica',
              descricao2: '367B Participar de jogos de percurso simples movendo sua peça conforme a quantidade tirada no dado',
            },
          ],
        },
        {
          ordem: 7,
          descricao: '(EI02ET08) Registrar com números a quantidade de crianças (meninas e meninos, presentes e ausentes) e a quantidade de objetos da mesma natureza (bonecas, bolas, livros etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '368A Registrar com marcas gráficas, próprias, a quantidade de objetos que dispõe na mesa.',
              descricao2: '368B Registrar números de diferentes formas.',
            },
            {
              ordem: 1,
              descricao: '369A Brincar com computador, calculadora, régua e outros suportes com números escritos.',
              descricao2: '369B Manter contato com jogos nos quais se precisa contar, ler ou registrar números.',
            },
            {
              ordem: 2,
              descricao: '370A Envolver-se nas atividades da rotina, nas quais estão presentes situações de contagem das crianças, observação do calendário, observação da sequência numérica',
              descricao2: '370B Participar das situações de contagem das crianças, observação do calendário, observação da sequência numérica',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos em pequenos grupos, reconhecendo e respeitando as diferentes identidades e pertencimento étnico-racial, de gênero e religião de seus parceiros.\n\nBRINCAR com diferentes parceiros, desenvolvendo sua imaginação e solidariedade.\n\nEXPLORAR diferentes formas de interagir com parceiros diversos em situações variadas, ampliando sua noção de mundo e sua sensibilidade em relação aos outros.\n\nPARTICIPAR ativamente das situações do cotidiano, tanto daquelas ligadas ao cuidado de si e do ambiente como das relativas às atividades propostas pelo(a) professor(a).\n\nEXPRESSAR às outras crianças e/ou adultos suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões e oposições.\n\nCONHECER-SE e construir uma identidade pessoal e cultural, valorizando suas características e as das outras crianças e adultos, aprendendo a identificar e combater atitudes preconceituosas e discriminatórias.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI02EO01) Demonstrar atitudes de cuidado e solidariedade na interação com crianças e adultos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '25A Acolher o colega com abraços, beijos, sorrisos ou outras expressões.',
              descricao2: '25B Acolher o colega com abraços, beijos, sorrisos ou outras expressões.',
            },
            {
              ordem: 1,
              descricao: '26A Compartilhar brinquedos com outras crianças.',
              descricao2: '26B Compartilhar brinquedos com outras crianças.',
            },
            {
              ordem: 2,
              descricao: '27A Participar de brincadeiras de roda, faz de conta em pares ou pequenos grupos.',
              descricao2: '27B Esperar sua vez de brincar com determinado brinquedo/objeto.',
            },
            {
              ordem: 3,
              descricao: '28A Apreciar ações e gestos dos colegas.',
              descricao2: '28A Interagir por meio de diferentes linguagens com outras crianças e adultos.',
            },
            {
              ordem: 4,
              descricao: '29A Experienciar situações que explorem dinâmicas com os nomes das crianças.',
              descricao2: '29B Identificar crianças e adultos de seu convívio chamando-os por seu nome.',
            },
            {
              ordem: 5,
              descricao: '30A Comunicar ao professor quando um colega estiver chorando.',
              descricao2: '30B Vivenciar dinâmicas de troca de afeto criando laços afetivos com crianças e adultos.',
            },
            {
              ordem: 6,
              descricao: '31A Expressar sentimentos em decorrência dos conflitos ocorridos ao usarem materiais de uso coletivo, buscando solucioná-los.',
              descricao2: '31B Expressar sentimentos em decorrência dos conflitos ocorridos ao usarem materiais de uso coletivo, buscando solucioná-los.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI02EO02) Demonstrar imagem positiva de si e confiança em sua capacidade para enfrentar dificuldades e desafios.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '32A Expressar satisfação em realizar ações como: descer no escorregador, subir em algo, pegar um objeto que está em cima de uma bancada, empilhar peças de brinquedo, alimentar-se sozinho, etc.',
              descricao2: '32B Ser capaz de realizar ações autonomamente como: alimentar-se, guardar pertences na mochila, escolher brinquedos, etc.',
            },
            {
              ordem: 1,
              descricao: '33A Explorar o próprio corpo em situações de brincadeira e interação.',
              descricao2: '33B Expressar satisfação por sua imagem, suas características físicas.',
            },
            {
              ordem: 2,
              descricao: '34A Cuidar da autoimagem a partir de sua apresentação pessoal e zelo com seus pertences.',
              descricao2: '34B Identificar suas características e possibilidades corporais em momentos de interação e brincadeira.',
            },
            {
              ordem: 3,
              descricao: '35A Escolher com qual brinquedo brincar.',
              descricao2: '35B Manifestar prazer em poder brincar com o seu corpo, por meio de movimentos, gestos de apontar partes de seu corpo mostrando correspondência com a de seus colegas.',
            },
            {
              ordem: 4,
              descricao: '36A Demonstrar persistência ao agir autonomamente.',
              descricao2: '36B Realizar atividades como locomover objetos, trazendo-os ou deixando-os em determinados ambientes quando solicitado.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI02EO03) Compartilhar os objetos e os espaços com crianças da mesma faixa etária e adultos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '37A Vivenciar situações de explorações diversas, com diferentes materiais e em situações de interação com outras crianças e professores(as).',
              descricao2: '37B Explorar diferentes espaços junto à outras crianças, como: parque, brinquedoteca, cantinho da leitura, espaço para o faz de conta, etc.',
            },
            {
              ordem: 1,
              descricao: '38A Experienciar momentos de partilha, troca ou divisão de brinquedos.',
              descricao2: '38B Compartilhar brinquedos em suas atividades de explorações, investigações ou de faz de conta.',
            },
            {
              ordem: 2,
              descricao: '39A Brincar com outras crianças, em pequenos ou grandes grupos, onde suas interações aconteçam com maior duração de tempo.',
              descricao2: '39B Interagir com outras crianças agindo com solidariedade e colaboração.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI02EO04) Comunicar-se com os colegas e os adultos, buscando compreendê-los e fazendo-se compreender.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '40A Participar em situações de brincadeira onde possam encenar personagens, fazer uso de suas expressões corporais e faciais.',
              descricao2: '40B Participar em situações de brincadeira, onde possam expressar-se por meio de diferentes linguagens.',
            },
            {
              ordem: 1,
              descricao: '41A Cooperar com os colegas e professores em situações cotidianas.',
              descricao2: '41B Cooperar com os colegas e professores em situações cotidianas.',
            },
            {
              ordem: 2,
              descricao: '42A Respeitar os colegas nas situações cotidianas, ouvindo-os, respeitando seus espaços e decisões em, por exemplo, brincar com um objeto e não com outro, etc.',
              descricao2: '42B Expressar suas ideias, vontades, sentimentos e emoções, sendo ouvido e ouvindo seus colegas.',
            },
            {
              ordem: 3,
              descricao: '43A Escolher histórias, brinquedos, colegas para brincar, etc.',
              descricao2: '43B Criar enredos e cenários para contar algo vivido ou imaginado.',
            },
            {
              ordem: 4,
              descricao: '44A Fazer questionamentos sobre o mundo à sua volta.',
              descricao2: '44B Descrever situações ou fatos vividos utilizando palavras novas e frases cada vez mais complexas.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI02EO05) Perceber que as pessoas têm características físicas diferentes, respeitando essas diferenças.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '45A Identificar algumas de suas características físicas, seus gostos e preferências.',
              descricao2: '45B Identificar suas características no reflexo do espelho, em fotografias, bem como realizar um autorretrato por meio do desenho.',
            },
            {
              ordem: 1,
              descricao: '46A Observar diferenças e semelhanças entre si e as demais crianças.',
              descricao2: '46B Perceber diferenças físicas, preferências e habilidades suas e de seus colegas, respeitando-as.',
            },
            {
              ordem: 2,
              descricao: '47A Ouvir histórias infantis que tratam sobre a diversidade e o respeito às diferenças.',
              descricao2: '47B Conhecer sua história a partir de relatos de seus familiares e registros de imagens diversos.',
            },
            {
              ordem: 3,
              descricao: '48A Brincar de faz de conta assumindo diferentes papéis, imitando ações e modos de ser de crianças e adultos.',
              descricao2: '48B Brincar de faz de conta assumindo diferentes papéis, imitando ações e modos de ser de crianças e adultos.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI02EO06) Respeitar regras básicas de convívio social nas interações e brincadeiras.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '49A Participar em colaboração com outras crianças de jogos e atividades simples, como por exemplo, guardar peças de um brinquedo em uma caixa, recolher objetos do chão, etc.',
              descricao2: '49B Começar a seguir, de forma gradativa, regras simples de convívio em momentos de alimentação, cuidado com a saúde e brincadeiras.',
            },
            {
              ordem: 1,
              descricao: '50A Participar de situações em que respeite a vez do outro de, por exemplo, falar, brincar com determinado brinquedo, manusear um objeto, etc.',
              descricao2: '50B Desenvolver o respeito aos combinados estabelecidos por colegas e professores.',
            },
            {
              ordem: 2,
              descricao: '51A Escolher junto com os colegas a história a ser contada, o tema de pesquisa, as brincadeiras coletivas, etc.',
              descricao2: '51B Participar de diferentes manifestações culturais, como: eventos/festas tradicionais do seu bairro, cidade e estado.',
            },
          ],
        },
        {
          ordem: 6,
          descricao: '(EI02EO07) Resolver conflitos nas interações e brincadeiras, com a orientação de um adulto.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '52A Expressar e falar com o(a) professor(a) sobre seus sentimentos.',
              descricao2: '52B Procurar o(a) professor(a) para ajudar a resolver conflitos nas brincadeiras e interações com outras crianças.',
            },
            {
              ordem: 1,
              descricao: '53A Procurar o adulto quando não estiver satisfeito com algo.',
              descricao2: '53B Falar com o (a) professor(a) quando estiver insatisfeito ou para relatar algo que o desagradou.',
            },
            {
              ordem: 2,
              descricao: '54A Falar com colegas no decorrer dos conflitos, nas situações de interações e brincadeiras.',
              descricao2: '54B Aceitar ajuda e conseguir acalmar-se com o apoio do(a) professor(a) ao vivenciar conflitos nas interações.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER e fruir com os colegas e professores manifestações artísticas e culturais da sua comunidade e de outras culturas — artes plásticas, música, dança, teatro, cinema, folguedos e festas populares.\n\nBRINCAR com diferentes sons, ritmos, formas, cores, texturas, objetos e materiais, construindo cenários e indumentárias para brincadeiras de faz de conta, encenações ou para festas tradicionais.\n\nEXPLORAR variadas possibilidades de usos e combinações de materiais, substâncias, objetos e recursos tecnológicos para criar desenhos, modelagens, músicas, danças, encenações teatrais e musicais.\n\nPARTICIPAR de decisões e ações relativas à organização do ambiente (tanto o cotidiano quanto o preparado para determinados eventos), à definição de temas e à escolha de materiais a serem usados em atividades lúdicas e artísticas.\n\nEXPRESSAR suas emoções, sentimentos, necessidades e ideias cantando, dançando, esculpindo, desenhando, encenando.\n\nCONHECER-SE no contato criativo com manifestações artísticas e culturais locais e de outras comunidades.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI02TS01) Criar sons com materiais, objetos e instrumentos musicais, para acompanhar diversos ritmos de música.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '150A Conhecer as canções e reproduzi-las.',
              descricao2: '150B Inventar pequenos versos a partir de canções conhecidas.',
            },
            {
              ordem: 1,
              descricao: '151A Envolver-se com o corpo na escuta de canções buscando mover-se no compasso da canção.',
              descricao2: '151B Envolver-se com o corpo na escuta de canções buscando mover-se no compasso da canção.',
            },
            {
              ordem: 2,
              descricao: '152A Brincar com materiais, objetos e instrumentos musicais buscando produzir novos sons.',
              descricao2: '152B Imitar, inventar e reproduzir criações musicais com diferentes objetos sonoros.',
            },
            {
              ordem: 3,
              descricao: '153A Explorar novos materiais buscando diferentes sons para acompanhar canções que lhe são familiares.',
              descricao2: '153B Explorar novos materiais buscando diferentes sons para acompanhar canções que lhe são familiares.',
            },
            {
              ordem: 4,
              descricao: '154A Explorar objetos ou instrumentos sonoros buscando descobrir e criar sons e acompanhar o ritmo da música.',
              descricao2: '154B Adequar os sons produzidos com os diferentes objetos ou instrumentos ao ritmo da música.',
            },
            {
              ordem: 5,
              descricao: '155A Produzir sons, utilizar o próprio corpo, como ao bater palmas, os pés, de forma ritmada, com o auxílio de outras crianças e professores(as).',
              descricao2: '155B Produzir sons, utilizar o próprio corpo, como ao bater palmas, os pés, de forma ritmada, com o auxílio de outras crianças e professores(as).',
            },
            {
              ordem: 6,
              descricao: '156A Explorar diferentes fontes sonoras reconhecendo sua ausência ou presença em diferentes situações.',
              descricao2: '156B Diferenciar sons dos objetos sonoros e dos instrumentos musicais.',
            },
            {
              ordem: 7,
              descricao: '157A Expressar-se utilizando diferentes instrumentos musicais, ritmos, velocidades, intensidades, sequências de melodia e timbres em suas brincadeiras, nas danças ou em interações em duplas, trios ou pequenos grupos.',
              descricao2: '157B Expressar-se utilizando diferentes instrumentos musicais, ritmos, velocidades, intensidades, sequências de melodia e timbres em suas brincadeiras, nas danças ou em interações em duplas, trios ou pequenos grupos.',
            },
            {
              ordem: 8,
              descricao: '158A Demonstrar sua preferência por determinadas músicas instrumentais e diferentes expressões da cultura musical brasileira e de outras culturas: canções, acalantos, cantigas de roda, brincos, parlendas, travalínguas etc.',
              descricao2: '158B Apreciar os gêneros musicais valorizando a diversidade de produção artística local e das diferentes culturas.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI02TS02) Utilizar materiais variados com possibilidades de manipulação (argila, massa de modelar), explorando cores, texturas, superfícies, planos, formas e volumes ao criar objetos tridimensionais.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '159A Criar representações de pessoas e coisas utilizando diferentes materiais, como argila, massa de modelar, areia, etc.',
              descricao2: '159B Criar produtos com massa de modelar ou argila a partir de seu próprio repertório, explorando diferentes elementos, como forma, volume, textura, etc.',
            },
            {
              ordem: 1,
              descricao: '160A Expressar-se utilizando diversos suportes, materiais, instrumentos e técnicas.',
              descricao2: '160B Expressar-se utilizando diversos suportes, materiais, instrumentos e técnicas.',
            },
            {
              ordem: 2,
              descricao: '161A Aprimorar habilidades necessárias ao manuseio dos diferentes materiais e instrumentos relacionando-os aos objetos representados.',
              descricao2: '161B Explorar texturas, formas e cores manipulando, combinando e misturando tintas e materiais variados.',
            },
            {
              ordem: 3,
              descricao: '162A Criar objetos bidimensionais e tridimensionais a partir de materiais como argila, barro, massa de modelar, papel e tinta.',
              descricao2: '162B Criar objetos tridimensionais com palitos de madeira, papéis diversos e outros materiais.',
            },
            {
              ordem: 4,
              descricao: '163A Experimentar formas tridimensionais nas brincadeiras de montar, encaixar e empilhar.',
              descricao2: '163B Experimentar formas tridimensionais nas brincadeiras de montar, encaixar e empilhar.',
            },
            {
              ordem: 5,
              descricao: '164A Explorar as características de objetos e materiais - odores, sabores, sonoridades, texturas, formas, pesos, tamanhos e posições no espaço - ao utilizar materiais como argila, barro, massa de modelar, papel, tinta, etc.',
              descricao2: '164B Explorar as características de objetos e materiais - odores, sabores, sonoridades, texturas, formas, pesos, tamanhos e posições no espaço - ao utilizar materiais como argila, barro, massa de modelar, papel, tinta, etc.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI02TS03) Utilizar diferentes fontes sonoras disponíveis no ambiente em brincadeiras cantadas, canções, músicas e melodias. ',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '165A Investigar diferentes fontes sonoras presentes no ambiente escolar ou ao seu redor.',
              descricao2: '165B Explorar e identificar sons familiares e possibilidades sonoras de objetos de seu cotidiano ou de instrumentos musicais.',
            },
            {
              ordem: 1,
              descricao: '166A Identificar e imitar sons conhecidos, como os sons da natureza (cantos de pássaros, vozes de animais, barulho do vento, da chuva etc.), sons da cultura (vozes humanas, sons de instrumentos musicais, de máquinas, produzidos por objetos e outras fontes sonoras) ou o silêncio.',
              descricao2: '166B Repetir e recriar sons ou canções conhecidas e usar nas brincadeiras.',
            },
            {
              ordem: 2,
              descricao: '167A Experimentar ouvir, perceber e discriminar eventos sonoros diversos, fontes sonoras e produções musicais.',
              descricao2: '167B Experimentar ouvir, perceber e discriminar eventos sonoros diversos, fontes sonoras e produções musicais.',
            },
            {
              ordem: 3,
              descricao: '168A Interessar-se por canções ou brincadeiras cantadas apresentadas pelos(as) professores(as) ou seus colegas.',
              descricao2: '168B Explorar e identificar elementos da música para se expressar, interagir com os outros e ampliar seu conhecimento do mundo.',
            },
            {
              ordem: 4,
              descricao: '169A Participar de jogos e brincadeiras que envolvam a dança e/ou a improvisação musical.',
              descricao2: '169B Apreciar músicas de diferentes tradições culturais buscando cantar juntos e imitar os gestos comuns.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI02TS04RN) Desenvolver a sensibilidade e a capacidade de apreciação estética e criação de obras artísticas em diferentes linguagens das artes visuais.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '170A Contemplar e recriar obras artísticas em diferentes linguagens (gráficas, plásticas, multimídias, etc.), contextualizando sua produção.',
              descricao2: '170B Apreciar, recriar e criar obras artísticas em diferentes linguagens (gráficas, plásticas, multimídias, etc.), contextualizando sua produção.',
            },
            {
              ordem: 1,
              descricao: '171A Explorar procedimentos necessários para modelar, desenhar e pintar e suas diferentes possibilidades de manuseio e técnicas a partir da intencionalidade na produção artística.',
              descricao2: '171B Explorar e aprofundar suas descobertas em relação a procedimentos necessários para modelar, desenhar, pintar e fotografar e suas diferentes possibilidades de manuseio e técnicas a partir da intencionalidade na produção artística.',
            },
            {
              ordem: 2,
              descricao: '172A Desenvolver atitudes de cuidado e apreciação pela produção própria e a dos colegas.',
              descricao2: '172B Desenvolver atitudes de cuidado e apreciação pela produção própria e a dos colegas.',
            },
          ],
        },
      ],
    },
    
    
  ];
  return obj[curso];
}

export default getCamposAprendizagemCreche;
