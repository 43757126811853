import BaseService from './BaseService';

class CompetenciasMunicipaisService extends BaseService {
  salvarCompetenciasMunicipais(idMateria, competenciasMunicipais) {
    const requestInfo = {
      method: 'post',
      url: `/competencia/municipal/materia/${idMateria}`,
      data: JSON.stringify(competenciasMunicipais),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCompetenciasMunicipaisPorMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/competencia/municipal/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoCompetenciaMunicipalNaMatriculaEMateria(idCompetenciaMunicipal, idMatricula, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/competencia/municipal/${idCompetenciaMunicipal}/matricula/${idMatricula}/materia/${idMateria}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarCompetenciasMunicipaisAdquiridasMatriculaMateria(idMatricula, idMateria, situacoesCompetenciasMunicipais) {
    const requestInfo = {
      method: 'post',
      url: `/competencia/municipal/matricula/${idMatricula}/materia/${idMateria}/situacoes`,
      data: JSON.stringify(situacoesCompetenciasMunicipais),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default CompetenciasMunicipaisService;
