import BaseService from './BaseService';

class HistoricosService extends BaseService {
  carregarHistorico(idHistorico) {
    const requestInfo = {
      method: 'get',
      url: `/historico/${idHistorico}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarHistoricoPorAluno(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/historico/aluno/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarHistorico(historico) {
    const requestInfo = {
      method: 'post',
      url: '/historico',
      data: JSON.stringify(historico),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarHistorico(historico) {
    const requestInfo = {
      method: 'put',
      url: '/historico',
      data: JSON.stringify(historico),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerHistorico(idHistorico) {
    const requestInfo = {
      method: 'delete',
      url: `/historico/${idHistorico}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMateriasHistorico(idHistorico) {
    const requestInfo = {
      method: 'get',
      url: `/historico/${idHistorico}/materias`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirHistorico(idEscola, idAluno, tipo, tipoAssinatura, observacoes, incluirNotasBimestrais, mostrarSomenteUltimoRegistro) {
    const data = {
      observacoes,
      incluirNotasBimestrais,
      mostrarSomenteUltimoRegistro
    };

    const requestInfo = {
      method: 'post',
      url: `/historico/escola/${idEscola}/aluno/${idAluno}/imprimir/${tipo}/assinatura/${tipoAssinatura}`,
      data,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirHistoricoResumido(idEscola, idAluno, tipo, tipoAssinatura, observacoes, incluirNotasBimestrais, mostrarSomenteUltimoRegistro) {
    const data = {
      observacoes,
      incluirNotasBimestrais,
      mostrarSomenteUltimoRegistro
    };

    const requestInfo = {
      method: 'post',
      url: `/historico/resumido/escola/${idEscola}/aluno/${idAluno}/imprimir/${tipo}/assinatura/${tipoAssinatura}`,
      data: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default HistoricosService;
