import BaseService from './BaseService';

class ProfessorAuxiliarService extends BaseService {
  carregarProfessorAuxiliar(idUsuario) {
    const requestInfo = {
      method: 'get',
      url: `/professor/auxiliar/usuario/${idUsuario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMeuProfessorAuxiliar() {
    const requestInfo = {
      method: 'get',
      url: `/professor/auxiliar/usuario`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarProfessoresAuxiliares() {
    const requestInfo = {
      method: 'get',
      url: `/professor/auxiliar`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarProfessorAuxiliar(professorAuxiliar) {
    const requestInfo = {
      method: 'put',
      url: '/professor/auxiliar',
      data: JSON.stringify(professorAuxiliar),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default ProfessorAuxiliarService;
