import BaseService from './BaseService';

class EscolasService extends BaseService {
  carregarEscola(idEscola) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEscolas(pagina, linhasPorPagina, like) {
    let url = `/escola/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTodasAsEscolas() {
    let url = `/escola/todas`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEscolasPorProfessorNoAnoLetivo(idAnoLetivo, pagina, linhasPorPagina, like) {
    let url = `/escola/anoletivo/${idAnoLetivo}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEscola(escola) {
    const requestInfo = {
      method: 'post',
      url: '/escola',
      data: JSON.stringify(escola),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarEscola(escola) {
    const requestInfo = {
      method: 'put',
      url: '/escola',
      data: JSON.stringify(escola),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEscola(idEscola) {
    const requestInfo = {
      method: 'delete',
      url: `/escola/${idEscola}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiretores(idEscola, pagina, linhasPorPagina, like) {
    let url = `/escola/${idEscola}/diretores/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  adicionarDiretor(diretor) {
    const requestInfo = {
      method: 'post',
      url: '/escola/adicionar/diretor',
      data: JSON.stringify(diretor),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSecretarios(idEscola, pagina, linhasPorPagina, like) {
    let url = `/escola/${idEscola}/secretarios/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  adicionarSecretario(secretario) {
    const requestInfo = {
      method: 'post',
      url: '/escola/adicionar/secretario',
      data: JSON.stringify(secretario),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerDiretor(idDiretor) {
    const requestInfo = {
      method: 'delete',
      url: `/escola/remover/diretor/${idDiretor}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerSecretario(idSecretario) {
    const requestInfo = {
      method: 'delete',
      url: `/escola/remover/secretario/${idSecretario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCoordenadores(idEscola, pagina, linhasPorPagina, like) {
    let url = `/escola/${idEscola}/coordenadores/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  adicionarCoordenador(coordenador) {
    const requestInfo = {
      method: 'post',
      url: '/escola/adicionar/coordenador',
      data: JSON.stringify(coordenador),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerCoordenador(idCoordenador) {
    const requestInfo = {
      method: 'delete',
      url: `/escola/remover/coordenador/${idCoordenador}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarItemMerenda(idItemMerenda) {
    const requestInfo = {
      method: 'get',
      url: `/escola/itemmerenda/${idItemMerenda}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarItensMerenda(idEscola, pagina, linhasPorPagina, like) {
    let url = `/escola/itemmerenda/${idEscola}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarItemMerenda(itemMerenda) {
    const requestInfo = {
      method: 'post',
      url: '/escola/itemmerenda/adicionar',
      data: JSON.stringify(itemMerenda),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarItemMerenda(itemMerenda) {
    const requestInfo = {
      method: 'put',
      url: '/escola/itemmerenda/atualizar',
      data: JSON.stringify(itemMerenda),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerItemMerenda(idItemMerenda) {
    const requestInfo = {
      method: 'delete',
      url: `/escola/itemmerenda/remover/${idItemMerenda}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFuncionario(idFuncionario) {
    const requestInfo = {
      method: 'get',
      url: `/escola/funcionario/${idFuncionario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFuncionarios(idEscola, pagina, linhasPorPagina, like) {
    let url = `/escola/funcionario/${idEscola}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarFuncionario(funcionario) {
    const requestInfo = {
      method: 'post',
      url: '/escola/funcionario/adicionar',
      data: JSON.stringify(funcionario),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarFuncionario(funcionario) {
    const requestInfo = {
      method: 'put',
      url: '/escola/funcionario/atualizar',
      data: JSON.stringify(funcionario),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerFuncionario(idFuncionario) {
    const requestInfo = {
      method: 'delete',
      url: `/escola/funcionario/remover/${idFuncionario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCardapio(idEscola) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/cardapio`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCiclosEnsino(idEscola) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/ciclos/ensino`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurnos(idEscola) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/turnos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarCardapio(idEscola, cardapio) {
    const requestInfo = {
      method: 'put',
      url: `/escola/${idEscola}/cardapio`,
      data: JSON.stringify(cardapio),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDeclaracaoConclusao(idEscola, idAluno, tipo, tipoAssinatura) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/imprimir/declaracao/conclusao/aluno/${idAluno}/tipo/${tipo}/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDeclaracaoConclusaoSerie(idEscola, idAluno, tipo, tipoAssinatura) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/imprimir/declaracao/conclusao/serie/aluno/${idAluno}/tipo/${tipo}/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDeclaracaoFrequenciaMensal(idEscola, idMatricula,
    dataInicial, dataFinal, tipoAssinatura) {
    const url = `/escola/${idEscola}/imprimir/declaracao/frequencia`
      + `/mensal/matricula/${idMatricula}/datainicio/${dataInicial}/datafim/${dataFinal}/assinatura/${tipoAssinatura}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDeclaracaoTransferencia(idEscola, idAluno, tipoAssinatura) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/imprimir/declaracao/transferencia/aluno/${idAluno}/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDeclaracaoGeral(idEscola, tipoAssinatura, titulo, texto) {
    const requestInfo = {
      method: 'post',
      data: JSON.stringify({
        titulo,
        texto,
      }),
      url: `/escola/${idEscola}/imprimir/declaracao/geral/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDeclaracaoVagas(idEscola, serie, tipoAssinatura) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/imprimir/declaracao/vagas/serie/${serie}/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarSolicitacaoTransferencia(idEscolaOrigem, idAluno, idEscolaDestino, senha) {
    const requestInfo = {
      method: 'post',
      url: `/escola/solicitacao/transferencia/origem/${idEscolaOrigem}/aluno/${idAluno}/destino/${idEscolaDestino}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSolicitacoesTransferenciaEntrada(idEscola, pagina, linhasPorPagina, like) {
    let url = `/escola/${idEscola}/solicitacoes/transferencia/entrada/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSolicitacoesTransferenciaSaida(idEscola, pagina, linhasPorPagina, like) {
    let url = `/escola/${idEscola}/solicitacoes/transferencia/saida/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cancelarSolicitacaoTransferencia(idSolicitacaoTransferencia) {
    const requestInfo = {
      method: 'get',
      url: `/escola/solicitacao/transferencia/${idSolicitacaoTransferencia}/cancelar`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  recusarSolicitacaoTransferencia(idSolicitacaoTransferencia) {
    const requestInfo = {
      method: 'get',
      url: `/escola/solicitacao/transferencia/${idSolicitacaoTransferencia}/recusar`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  aceitarSolicitacaoTransferencia(idSolicitacaoTransferencia) {
    const requestInfo = {
      method: 'get',
      url: `/escola/solicitacao/transferencia/${idSolicitacaoTransferencia}/aceitar`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarProfessoresPorAnoLetivo(idAnoLetivo, idEscola) {
    const requestInfo = {
      method: 'get',
      url: `/escola/${idEscola}/anoletivo/${idAnoLetivo}/professores`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default EscolasService;
