import BaseService from './BaseService';

class CredenciaisService extends BaseService {
  carregarCredenciais() {
    const requestInfo = {
      method: 'get',
      url: '/credenciais',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  mudarSenha(senhas) {
    const requestInfo = {
      method: 'post',
      url: '/credenciais/mudar/senha',
      data: JSON.stringify(senhas),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default CredenciaisService;
