import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import { connect } from 'react-redux';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';

import Papel from 'assets/csv/papeis.json';
import Situacao from 'assets/csv/situacoes-vinculo.json';

import LoginService from 'services/LoginService';
import UsuarioService from 'services/UsuarioService';

class Gestores extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      Situacao.ATIVO,
      Situacao.INATIVO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-gestores`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.carregarGestores = this.carregarGestores.bind(this);

    this.loginService = new LoginService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,

      dadosTabela: {
        header: [['Email', '20%'], ['Login', '20%'], ['Nome', '20%'], ['Entes', '20%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-left'],
        keys: ['email_str', 'login_str', 'nome_str', 'ente_str'],
        rows: [],
        total: undefined,
      },
    };
  }

  async componentDidMount() {
    this.carregarGestores(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarGestores(pagina, linhasPorPagina, like) {
    this.setState({ showAlert: false });

    try {
      const data = await this.usuarioService
        .carregarUsuarios(Papel.GESTOR.value, pagina, linhasPorPagina, like);

      const fn = (usuario) => new Promise((resolve) => resolve(
        this.usuarioService.carregarVinculosUsuario(usuario.id),
      ));

      const actions = data.usuarios.map(fn);

      const vinculos = await Promise.all(actions);
      
      data.usuarios.forEach((gestor,idx) => {
        Object.assign(gestor, { ente_str: '' });
        vinculos[idx].forEach((vinculo) => {
          Object.assign(gestor, {
            email_str: gestor.credenciais.email,
            login_str: gestor.credenciais.login,
            nome_str: gestor.nome ? gestor.nome : '-',
          });
          if(vinculo.papel === Papel.GESTOR.value) {
            Object.assign(gestor, {
              ente_str: `${gestor.ente_str} ${vinculo.ente.nome}/${vinculo.ente.estado.uf}, `,
            });
          }
        });
        Object.assign(
          gestor,
          { ente_str: gestor.ente_str.substring(0, gestor.ente_str.length - 2) },
        );
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.usuarios, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar gestores cadastrados',
      });
    }
  }

  gestorSelecionado(gestor) {
    this.props.history.push({
      pathname: `/admin/gestores/${gestor.id}`,
    });
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Gestores</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Gestores cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <AdaptiveTable
                  selectedPage={this.paginaSelecionada}
                  rowsPerPage={this.linhasPorPagina}
                  rowsPerPageCallback={(info) => {
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.linhasPorPagina = info.rowsPerPage;
                    sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                    this.carregarGestores(this.paginaSelecionada, info.rowsPerPage, this.like);
                  }}
                  likeCallback={(text) => {
                    this.like = text;
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarGestores(this.paginaSelecionada, this.linhasPorPagina, text);
                  }}
                  paginatorCallback={(page) => {
                    this.paginaSelecionada = page;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarGestores(this.paginaSelecionada, this.linhasPorPagina, this.like);
                  }}
                  rowCallback={(gestor) => {
                    this.gestorSelecionado(gestor);
                  }}
                  data={this.state.dadosTabela}
                  disableActions
                  clickRows />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Gestores);
