import React from 'react';
import classnames from 'classnames';
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';

import { connect } from 'react-redux';

import TermosDeUsoModal from 'views/components/TermosDeUsoModal';

import UsuarioService from 'services/UsuarioService';

class LoggedNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent',
    };

    this.usuarioService = new UsuarioService();

    this.logout = this.logout.bind(this);
    this.carregarUsuario = this.carregarUsuario.bind(this);
  }

  componentWillUnmount() {
    this.umounted = true;
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor);
  }

  componentDidUpdate = (e) => {
    if (
      window.outerWidth < 993
      && e.history.location.pathname !== e.location.pathname
      && document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: 'bg-white',
      });
    } else {
      this.setState({
        color: 'navbar-transparent',
      });
    }
  };

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState.color = 'bg-white';
    } else {
      newState.color = 'navbar-transparent';
    }
    this.setState(newState);
  };

  logout() {
    sessionStorage.removeItem('auth-token');
    this.props.history.push('/login');
  }

  async carregarUsuario() {
    const usuario = await this.usuarioService.carregarUsuario();
    const vinculos = await this.usuarioService.carregarVinculosUsuarioAtivo();
    Object.assign(usuario, { vinculos });

    try {
      this.props.history.push({
        pathname: '/login/escolhervinculo',
        state: {
          usuario,
        },
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Ocorreu um erro ao buscar vínculos do usuário.',
      });
    }
  }

  render() {
    return (
      <>
        <Navbar
          id="navbarsisaeduc"
          className={classnames('navbar-absolute fixed-top', this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames('navbar-toggle', {
                  toggled: this.state.sidebarOpen,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand onClick={(e) => e.preventDefault()}>
                <span className="d-none d-md-block">
                  {`${this.props.dadosUsuario.ente} - ${this.props.dadosUsuario.papel}`}
                </span>
                <span className="d-block d-md-none">{`${this.props.dadosUsuario.ente} - ${this.props.dadosUsuario.papel}`}</span>
              </NavbarBrand>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <UncontrolledDropdown>
                <DropdownToggle
                  aria-haspopup={true}
                  color="default"
                  data-toggle="collapse"
                  id="navbarDropdownMenuLink"
                  nav>
                  <Button className="btn-magnify" size="sm">
                    OPÇÕES <b className="caret" />
                  </Button>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem
                    onClick={() => { this.carregarUsuario(); }}>
                    Mudar Vínculo
                  </DropdownItem>
                  {
                    this.props.layout === '/gestor' && <DropdownItem
                      onClick={() => this.props.history.push(`${this.props.layout}/chamados`)}>
                      Chamados
                    </DropdownItem>
                  }
                  { /*
                    this.props.layout === '/gestor' && <DropdownItem
                      onClick={() => this.props.history.push(`${this.props.layout}/limites`)}>
                      Meu plano
                    </DropdownItem>
                  */ }
                  {
                    /*
                    this.props.layout !== '/admin' && this.props.layout !== '/aluno'
                      && this.props.layout !== '/pais' && <DropdownItem
                      onClick={() => this.props.history.push(`${this.props.layout}/depoimentos`)}>
                      Depoimentos
                    </DropdownItem>
                    */
                  }
                  {
                    this.props.layout !== '/admin' && <DropdownItem
                      onClick={() => this.props.history.push(`${this.props.layout}/ouvidoria/chamados`)}>
                      Ouvidoria da Secretaria de Educação
                    </DropdownItem>
                  }
                  { /*
                    (this.props.layout === '/gestor' || this.props.layout === '/professor'
                     || this.props.layout === '/diretor') && <DropdownItem
                      onClick={() => this.props.history.push(`${this.props.layout}/contato`)}>
                      Contato
                    </DropdownItem>
                  */ }
                  {
                    <DropdownItem
                      onClick={() => this.props.history.push(`${this.props.layout}/tutoriais`)}>
                      Tutoriais
                    </DropdownItem>
                  }
                  <DropdownItem
                    onClick={() => this.setState({ showTermosDeUsoModal: true })}>
                    Termos de uso
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.logout()}>
                    Sair
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Collapse>
          </Container>
        </Navbar>
        <TermosDeUsoModal
          isOpen={this.state.showTermosDeUsoModal}
          closeCallback={() => this.setState({ showTermosDeUsoModal: false })} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(LoggedNavbar);
