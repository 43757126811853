import BaseService from './BaseService';

class TurmasService extends BaseService {
  carregarTurma(idturma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idturma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmas(idAnoLetivo, idEscola, pagina, linhasPorPagina, like) {
    let url = `/turma/${idAnoLetivo}/escola/${idEscola}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarTurma(turma) {
    const requestInfo = {
      method: 'post',
      url: '/turma',
      data: JSON.stringify(turma),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarTurma(turma) {
    const requestInfo = {
      method: 'put',
      url: '/turma',
      data: JSON.stringify(turma),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarProfessores(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/professores`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarProfessores(idTurma, professores) {
    const requestInfo = {
      method: 'put',
      url: `/turma/${idTurma}/atualizar/professores`,
      data: JSON.stringify(professores),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarHorariosAulas(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/horarios/aulas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarHorariosAulas(idTurma, horarios, frequenciaRestrita) {
    const requestInfo = {
      method: 'put',
      url: `/turma/${idTurma}/atualizar/horarios/aulas/frequenciarestrita/${frequenciaRestrita}`,
      data: JSON.stringify(horarios),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  adicionarMatricula(matricula, data) {
    const requestInfo = {
      method: 'post',
      url: `/turma/adicionar/matricula/${data.replace(/\//gi, '-')}`,
      data: JSON.stringify(matricula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculas(idTurma, pagina, linhasPorPagina, like) {
    let url = `/turma/${idTurma}/matriculas/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatricula(idTurma, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculaPorId(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/matricula/id/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarSituacaoMatricula(idTurma, idMatricula, situacao, data, dependencias, observacoes) {
    const atualizacaoMatricula = {
      dependencias,
      observacoes,
    };
    
    const requestInfo = {
      method: 'put',
      url: `/turma/${idTurma}/matricula/${idMatricula}/situacao/${situacao}/data/${data.replace(/\//gi, '-')}`,
      data: JSON.stringify(atualizacaoMatricula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarMatricula(matricula, data) {
    const requestInfo = {
      method: 'put',
      url: `/turma/atualizar/matricula/${data.replace(/\//gi, '-')}`,
      data: JSON.stringify(matricula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerMatricula(idMatricula, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/turma/remover/matricula/${idMatricula}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerTurma(idturma, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/turma/${idturma}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarListaFrequencia(idTurma, idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/diario/${idDiario}/lista/frequencia`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarDiarios(idTurma, diarios) {
    const requestInfo = {
      method: 'put',
      url: `/turma/${idTurma}/diarios/aulasalancar`,
      data: JSON.stringify(diarios),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  consolidarTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/consolidar/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  reabrirTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/reabrir/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  processarTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/processar/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDescricoesAvaliacoes(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/descricoes/avaliacoes`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDescricoesConteudos(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/descricoes/conteudos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDescricoesRelatorios(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/descricoes/relatorios`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDependencias(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/dependencias/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasDoAluno(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/turma/matriculas/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasAtivasDoAluno(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/turma/matriculas/ativas/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTodasMatriculasDoAluno(idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/turma/matriculas/todas/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmaPorMatricula(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDadosAvaliacoes(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/dados/avaliacao/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirBoletimMatricula(idMatricula, tipoAssinatura) {
    const requestInfo = {
      method: 'get',
      url: `/turma/imprimir/boletim/${idMatricula}/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirBoletinsTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/imprimir/boletins`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasPorAnoLetivoEEscola(idAnoLetivo, idEscola,
    situacao, serie, pagina, linhasPorPagina, like) {
    let url = `/turma/matriculas/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (situacao !== undefined) {
      url += `/situacao/${situacao}`;
    }
    if (serie !== undefined) {
      url += `/serie/${serie}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasPorSerieSituacaoAnoLetivoEEscola(idAnoLetivo, idEscola,
    situacao, serie, pagina, linhasPorPagina, like) {
    let url = `/turma/matriculas/seriesituacao/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (situacao !== undefined) {
      url += `/situacao/${situacao}`;
    }
    if (serie !== undefined) {
      url += `/serie/${serie}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasForaDeFaixaPorAnoLetivoEEscola(idAnoLetivo, idEscola,
    situacao, serie, pagina, linhasPorPagina, like) {
    let url = `/turma/matriculasforadefaixa/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (situacao !== undefined) {
      url += `/situacao/${situacao}`;
    }
    if (serie !== undefined) {
      url += `/serie/${serie}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasPorSexoAnoLetivoEEscola(idAnoLetivo, idEscola,
    situacao, sexo, pagina, linhasPorPagina, like) {
    let url = `/turma/matriculasporsexo/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (situacao !== undefined) {
      url += `/situacao/${situacao}`;
    }
    if (sexo !== undefined) {
      url += `/sexo/${sexo}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasPorEtniaAnoLetivoEEscola(idAnoLetivo, idEscola,
    situacao, etnia, pagina, linhasPorPagina, like) {
    let url = `/turma/matriculasporetnia/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (situacao !== undefined) {
      url += `/situacao/${situacao}`;
    }
    if (etnia !== undefined) {
      url += `/etnia/${etnia}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmasPorAnoLetivoEEscola(idAnoLetivo, idEscola, situacao, serie,
    pagina, linhasPorPagina, like) {
    let url = `/turma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (situacao !== undefined) {
      url += `/situacao/${situacao}`;
    }
    if (serie !== undefined) {
      url += `/serie/${serie}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarGrupoMatriculasPorAnoLetivoEEscola(idAnoLetivo, idEscola,
    grupo, pagina, linhasPorPagina, like) {
    let url = `/turma/matriculas/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/grupo/${grupo}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarGrupoMatriculasPorDiario(idDiario, grupo, pagina, linhasPorPagina, like) {
    let url = `/turma/matriculas/diario/${idDiario}`
      + `/grupo/${grupo}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasAluno() {
    const requestInfo = {
      method: 'get',
      url: '/turma/matriculas/aluno',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasFilhos() {
    const requestInfo = {
      method: 'get',
      url: '/turma/matriculas/filhos',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarBoletim(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/boletim/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async carregarMateriasDaMatricula(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/materias/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirDeclaracaoMatricula(idEscola, idAluno, idTurma, tipoAssinatura) {
    const requestInfo = {
      method: 'get',
      url: `/turma/imprimir/declaracao/matricula/escola/${idEscola}/aluno/${idAluno}/turma/${idTurma}/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirFichaMatricula(idEscola, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/turma/imprimir/ficha/matricula/escola/${idEscola}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirHistoricoFichasMatricula(idEscola, idAluno) {
    const requestInfo = {
      method: 'get',
      url: `/turma/imprimir/historico/fichas/matricula/escola/${idEscola}/aluno/${idAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirFichaIndividual(idMatricula, tipoAssinatura) {
    const requestInfo = {
      method: 'get',
      url: `/turma/imprimir/ficha/individual/${idMatricula}/assinatura/${tipoAssinatura}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirFichasIndividuaisTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/imprimir/fichas/individuais`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async carregarTotalMatriculas(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/total/matriculas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async carregarTotalMatriculasAtivas(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/total/matriculas/ativas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmaMultiseriado(idturma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/${idturma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarTurmaMultiseriado(turma) {
    const requestInfo = {
      method: 'post',
      url: '/turma/multiseriado',
      data: JSON.stringify(turma),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarTurmaMultiseriado(turma) {
    const requestInfo = {
      method: 'put',
      url: '/turma/multiseriado',
      data: JSON.stringify(turma),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmasMultiseriado(idAnoLetivo, idEscola, pagina, linhasPorPagina, like) {
    let url = `/turma/multiseriado/${idAnoLetivo}/escola/${idEscola}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async carregarTotalMatriculasMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/${idTurma}/total/matriculas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async carregarTotalMatriculasAtivasMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/${idTurma}/total/matriculas/ativas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerTurmaMultiseriado(idturma, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/turma/multiseriado/${idturma}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarAulasALancarDiarioMultiseriado(idTurma, aulasALancar) {
    const requestInfo = {
      method: 'put',
      url: `/turma/multiseriado/${idTurma}/diarios/aulasalancar/${aulasALancar}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/${idTurma}/diarios`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarProfessoresTurmaMultiseriado(idTurma, professores) {
    const requestInfo = {
      method: 'put',
      url: `/turma/multiseriado/${idTurma}/atualizar/professores`,
      data: JSON.stringify(professores),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarHorariosAulasTurmaMultiseriado(idTurma, horarios) {
    const requestInfo = {
      method: 'put',
      url: `/turma/multiseriado/${idTurma}/atualizar/horarios/aulas`,
      data: JSON.stringify(horarios),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasTurmaMultiseriado(idTurma, pagina, linhasPorPagina, like) {
    let url = `/turma/multiseriado/${idTurma}/matriculas/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarListaFrequenciaTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/${idTurma}/lista/frequencia`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  consolidarTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/consolidar/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  reabrirTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/reabrir/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  processarTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/multiseriado/processar/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmasMultiseriadoDoProfessorPorAnoLetivo(idAnoLetivo, pagina, linhasPorPagina, like) {
    let url = `/turma/multiseriado/professor/anoletivo/${idAnoLetivo}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  reagruparDiarios(idTurma, diarios, senha) {
    let url = `/turma/${idTurma}/atualizardiarios`;

    const atualizacaoDiarios = {
      diarios,
      senha,
    }

    const requestInfo = {
      method: 'put',
      url,
      data: JSON.stringify(atualizacaoDiarios),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTurmaPermissaoAcessoTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/turma/${idTurma}/permissao/acesso`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default TurmasService;
