import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Redirect, Switch } from 'react-router-dom';

import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import LoggedNavbar from 'components/Navbars/LoggedNavbar';

let ps;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => (sessionStorage.getItem('auth-token') !== null ? (
        <Component {...props} role={rest.role} layout={rest.layout} />
      )
        : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      )}
  />
);

class SideBarLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'black',
      activeColor: 'info',
      sidebarMini: false,
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      if (this.refs.mainPanel) { ps = new PerfectScrollbar(this.refs.mainPanel); }
    }
  }

  componentWillUnmount = () => {
    if (navigator.platform.indexOf('Win') > -1) {
      if (ps) ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes, layout) => routes.map((prop, key) => {
    if (prop.collapse) {
      return this.getRoutes(prop.views, layout);
    }
    if (prop.layout === layout) {
      return (
        <PrivateRoute
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          role={prop.role}
          layout={prop.layout}
          key={key}
        />
      );
    }
    return null;
  });

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle('sidebar-mini');
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <LoggedNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>
            {this.getRoutes(this.props.routes.concat(this.props.routesProfile),
              this.props.layout)}
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf('full-screen-map')
              !== -1 ? null : (
                <Footer fluid />
              )}
        </div>
      </div>
    );
  }
}

export default SideBarLayout;
