import BaseService from './BaseService';

class ChamadosOuvidoriaService extends BaseService {

  carregarChamadoOuvidoria(idChamadoOuvidoria) {
    const requestInfo = {
      method: 'get',
      url: `/chamado/ouvidoria/${idChamadoOuvidoria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
  
  carregarChamadosOuvidoriaPorSituacao(situacao, pagina, linhasPorPagina, like) {
    let url = `/chamado/ouvidoria/situacao/${situacao}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarChamadosOuvidoriaPorEnteSituacao(situacao, pagina, linhasPorPagina, like) {
    let url = `/chamado/ouvidoria/ente/situacao/${situacao}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarChamadoOuvidoria(chamadoOuvidoria) {
    const requestInfo = {
      method: 'post',
      url: '/chamado/ouvidoria',
      data: JSON.stringify(chamadoOuvidoria),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarChamadoOuvidoria(chamadoOuvidoria) {
    const requestInfo = {
      method: 'put',
      url: '/chamado/ouvidoria',
      data: JSON.stringify(chamadoOuvidoria),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerChamadoOuvidoria(idChamadoOuvidoria) {
    const requestInfo = {
      method: 'delete',
      url: `/chamado/ouvidoria/${idChamadoOuvidoria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMensagens(idChamadoOuvidoria) {
    const requestInfo = {
      method: 'get',
      url: `/chamado/ouvidoria/${idChamadoOuvidoria}/mensagens`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAnexosMensagem(idMensagem) {
    const requestInfo = {
      method: 'get',
      url: `/chamado/ouvidoria/mensagem/${idMensagem}/anexos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  enviarResposta(idChamadoOuvidoria, resposta) {
    const requestInfo = {
      method: 'post',
      url: `/chamado/ouvidoria/${idChamadoOuvidoria}/resposta`,
      data: JSON.stringify(resposta),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarSituacao(idChamadoOuvidoria, situacao) {
    const requestInfo = {
      method: 'put',
      url: `/chamado/ouvidoria/${idChamadoOuvidoria}/atualizar/situacao/${situacao}`,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default ChamadosOuvidoriaService;
