import React from 'react';
import {
  Alert,
} from 'reactstrap';

class _Alert extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      const el = document.getElementById('navbarsisaeduc');
      if (el) {
        el.scrollIntoView();
      }
    }
  }

  render() {
    return (
      <Alert {...this.props}></Alert>
    );
  }
}

export default _Alert;
