import React from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Label,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import Quill from 'views/components/Quill';

import NoticiasService from 'services/NoticiasService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

import TipoNoticia from 'assets/csv/tipos-noticia.json';
import Papel from 'assets/csv/papeis.json';

class CadastroNoticia extends React.Component {
  constructor(props) {
    super(props);

    this.tipos = [
      TipoNoticia.INTERNA,
      TipoNoticia.PUBLICA,
    ];

    this.noticiasService = new NoticiasService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      tipoSelecionado: '',
      titulo: '',
      conteudo: '',

      gestores: this.props.role === Papel.GESTOR.value
        || this.props.role === Papel.DIRETOR.value
        || this.props.role === Papel.SECRETARIO.value,
      diretores: false,
      secretarios: false,
      professores: false,
      coordenadores: false,
      pais: false,
      alunos: false,

      loaderModalText: '',

      erro: null,

      viewDate: new Date(new Date().getFullYear(), 0, 1),
    };

    this.cadastrarNoticia = this.cadastrarNoticia.bind(this);
    this.atualizarNoticia = this.atualizarNoticia.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.noticiasService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      if (this.props.match.params.idNoticia !== undefined) {
        const noticia = await this.noticiasService
          .carregarNoticia(this.props.match.params.idNoticia);
        this.setState({
          noticiaSelecionada: noticia,
        });

        await this.contentLoad();
      }
    } catch (e) {
      console.log(e);
      this.setState({ erro: true });
    }
  }

  async contentLoad() {
    const papeis = await this.noticiasService
      .carregarPapeis(this.props.match.params.idNoticia);

    this.setState({
      titulo: this.state.noticiaSelecionada.titulo,
      conteudo: this.state.noticiaSelecionada.conteudo,
      tipoSelecionado: this.tipos[this.state.noticiaSelecionada.tipo],
      gestores: papeis
        .map((p) => p.papel).includes(Papel.GESTOR.value),
      diretores: papeis
        .map((p) => p.papel).includes(Papel.DIRETOR.value),
      secretarios: papeis
        .map((p) => p.papel).includes(Papel.SECRETARIO.value),
      professores: papeis
        .map((p) => p.papel).includes(Papel.PROFESSOR.value),
      pais: papeis
        .map((p) => p.papel).includes(Papel.PAIS.value),
      alunos: papeis
        .map((p) => p.papel).includes(Papel.ALUNO.value),
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.titulo === '') {
      this.setState({ tituloState: 'has-danger' });
      ret = false;
    }
    if (!this.state.tipoSelecionado) {
      this.setState({ tipoState: 'danger' });
      ret = false;
    }
    if (this.state.conteudo === '') {
      this.setState({ conteudoState: 'has-danger' });
      ret = false;
    }
    if (!this.state.gestores && !this.state.diretores && !this.state.secretarios && !this.state.coordenadores
      && !this.state.professores && !this.state.alunos
      && !this.state.pais) {
      this.setState({ papeisState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarNoticia() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando notícia...',
    });

    const papeis = [];
    if (this.state.gestores) papeis.push({ papel: Papel.GESTOR.value });
    if (this.state.diretores) papeis.push({ papel: Papel.DIRETOR.value });
    if (this.state.secretarios) papeis.push({ papel: Papel.SECRETARIO.value });
    if (this.state.coordenadores) papeis.push({ papel: Papel.COORDENADOR_PEDAGOGICO.value });
    if (this.state.professores) papeis.push({ papel: Papel.PROFESSOR.value });
    if (this.state.alunos) papeis.push({ papel: Papel.ALUNO.value });
    if (this.state.pais) papeis.push({ papel: Papel.PAIS.value });

    const noticia = {
      tipo: this.state.tipoSelecionado.value,
      titulo: this.state.titulo,
      conteudo: this.state.conteudo,
      papeis,
    };
    try {
      await this.noticiasService.cadastrarNoticia(noticia);
      this.limparFormulario();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Notícia cadastrada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar notícia',
      });
    }
  }

  async atualizarNoticia() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando notícia...',
    });

    const papeis = [];
    if (this.state.gestores) papeis.push({ papel: Papel.GESTOR.value });
    if (this.state.diretores) papeis.push({ papel: Papel.DIRETOR.value });
    if (this.state.secretarios) papeis.push({ papel: Papel.SECRETARIO.value });
    if (this.state.coordenadores) papeis.push({ papel: Papel.COORDENADOR_PEDAGOGICO.value });
    if (this.state.professores) papeis.push({ papel: Papel.PROFESSOR.value });
    if (this.state.alunos) papeis.push({ papel: Papel.ALUNO.value });
    if (this.state.pais) papeis.push({ papel: Papel.PAIS.value });

    const noticia = {
      id: this.state.noticiaSelecionada.id,
      tipo: this.state.tipoSelecionado.value,
      titulo: this.state.titulo,
      conteudo: this.state.conteudo,
      data: this.state.noticiaSelecionada.data,
      papeis,
    };

    try {
      await this.noticiasService.atualizarNoticia(noticia);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Notícia atualizada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar notícia',
      });
    }
  }

  limparFormulario() {
    this.setState({
      tipoSelecionado: '',
      titulo: '',
      conteudo: '',

      gestores: this.props.role === Papel.GESTOR.value
        || this.props.role === Papel.DIRETOR.value,
      diretores: false,
      secretarios: false,
      coordenadores: false,
      professores: false,
      pais: false,
      alunos: false,

      tipoState: '',
      tituloState: '',
      conteudoState: '',
      papeisState: '',

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.noticiaSelecionada) {
      this.cadastrarNoticia();
    } else {
      this.atualizarNoticia();
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idNoticia !== undefined
      && this.state.noticiaSelecionada === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/noticias`}>Notícias</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.noticiaSelecionada ? 'Atualização' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da notícia</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="9">
                    <FormGroup className={`has-label ${this.state.tituloState}`}>
                      <label>Título *</label>
                      <Input
                        type="text"
                        maxLength="200"
                        value={this.state.titulo}
                        onChange={(e) => this.setState({
                          titulo: e.target.value,
                          tituloState: 'has-success',
                        })}
                      />
                      {this.state.tituloState === 'has-danger' ? (
                        <label className="error">
                          Informe um título para a notícia.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Tipo *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoSelecionado}
                        onChange={(event) => {
                          if (event.value === TipoNoticia.PUBLICA.value) {
                            this.setState({
                              gestores: true,
                              diretores: true,
                              secretarios: true,
                              coordenadores: true,
                              professores: true,
                              pais: true,
                              alunos: true,
                            });
                          }
                          this.setState({ tipoState: '', tipoSelecionado: event });
                        }}
                        options={this.tipos}
                        placeholder="Tipo..."
                      />
                      {this.state.tipoState === 'danger' ? (
                        <label className="error">
                          Informe o tipo do notícia.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                {
                  <Row>
                    <Col md="12">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Vista por *</label>
                        <Row>
                          <Col md="2">
                            <FormGroup check style={{ marginTop: '20px' }}>
                              <Label className="form-check-label">
                                <Input
                                  disabled={this.props.role === Papel.GESTOR.value
                                    || this.props.role === Papel.DIRETOR.value
                                    || this.props.role === Papel.SECRETARIO.value
                                    || this.state.tipoSelecionado.value
                                    === TipoNoticia.PUBLICA.value}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={this.state.gestores}
                                  onChange={(e) => this.setState({
                                    diretores: e.target.checked,
                                    secretarios: e.target.checked,
                                  })} />
                                Gestores
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup check style={{ marginTop: '20px' }}>
                              <Label className="form-check-label">
                                <Input
                                  disabled={this.props.role === Papel.DIRETOR.value
                                    || this.state.tipoSelecionado.value
                                    === TipoNoticia.PUBLICA.value}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={this.state.diretores}
                                  onChange={(e) => this.setState({
                                    diretores: e.target.checked,
                                  })} />
                                Diretores
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup check style={{ marginTop: '20px' }}>
                              <Label className="form-check-label">
                                <Input
                                  disabled={this.props.role === Papel.SECRETARIO.value
                                    || this.state.tipoSelecionado.value
                                    === TipoNoticia.PUBLICA.value}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={this.state.secretarios}
                                  onChange={(e) => this.setState({
                                    secretarios: e.target.checked,
                                  })} />
                                Secretários
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup check style={{ marginTop: '20px' }}>
                              <Label className="form-check-label">
                                <Input
                                  disabled={this.props.role === Papel.DIRETOR.value
                                    || this.state.tipoSelecionado.value
                                    === TipoNoticia.PUBLICA.value}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={this.state.coordenadores}
                                  onChange={(e) => this.setState({
                                    coordenadores: e.target.checked,
                                  })} />
                                Coordenadores
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup check style={{ marginTop: '20px' }}>
                              <Label className="form-check-label">
                                <Input
                                  disabled={this.state.tipoSelecionado.value
                                    === TipoNoticia.PUBLICA.value}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={this.state.professores}
                                  onChange={(e) => this.setState({
                                    professores: e.target.checked,
                                  })} />
                                Professores
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                              </Label>
                            </FormGroup>
                            <label></label>
                          </Col>
                          <Col md="2">
                            <FormGroup check style={{ marginTop: '20px' }}>
                              <Label className="form-check-label">
                                <Input
                                  disabled={this.state.tipoSelecionado.value
                                    === TipoNoticia.PUBLICA.value}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={this.state.pais}
                                  onChange={(e) => this.setState({
                                    pais: e.target.checked,
                                  })} />
                                Pais
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                              </Label>
                            </FormGroup>
                            <label></label>
                          </Col>
                          <Col md="2">
                            <FormGroup check style={{ marginTop: '20px' }}>
                              <Label className="form-check-label">
                                <Input
                                  disabled={this.state.tipoSelecionado.value
                                    === TipoNoticia.PUBLICA.value}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={this.state.alunos}
                                  onChange={(e) => this.setState({
                                    alunos: e.target.checked,
                                  })} />
                                Alunos
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                              </Label>
                            </FormGroup>
                            <label></label>
                          </Col>
                        </Row>
                        {this.state.papeisState === 'has-danger' ? (
                          <label className="error">
                            Informe ao menos um grupo que visualizará a notícia
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md="12">
                    <FormGroup className={`has-label ${this.state.conteudoState}`}>
                      <label>Conteúdo *</label>
                      <Quill
                        state={this.state.conteudoState}
                        value={this.state.conteudo}
                        onChange={(e) => {
                          this.setState({ conteudo: e , conteudoState: '' });
                        }}
                        callbackMessage={(msg) => this.setState(msg)}
                        callbackUpload={async (arquivo) => {
                          const url = await this.noticiasService.uploadArquivo(arquivo);
                          return url;
                        }}
                      />
                      {this.state.conteudoState === 'has-danger' ? (
                        <label className="error">
                          Informe o conteúdo da notícia
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.push(`${this.props.layout}/noticias`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                      {!this.state.noticiaSelecionada ? 'Cadastrar' : 'Atualizar'}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroNoticia;
