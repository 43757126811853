import BaseService from './BaseService';

class ForumService extends BaseService {
  carregarMensagens(idTurma, idMateria, pagina, linhasPorPagina, like) {
    let url = '/forum/mensagens'
      + `/turma/${idTurma}/materia/${idMateria}`
      + `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarMensagem(idTurma, idMateria, mensagem) {
    const requestInfo = {
      method: 'post',
      url: `/forum/mensagem/turma/${idTurma}/materia/${idMateria}`,
      data: JSON.stringify(mensagem),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarMensagem(idTurma, idMateria, mensagem) {
    const requestInfo = {
      method: 'put',
      url: `/forum/mensagem/turma/${idTurma}/materia/${idMateria}`,
      data: JSON.stringify(mensagem),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerMensagem(idMensagem) {
    const requestInfo = {
      method: 'delete',
      url: `/forum/mensagem/${idMensagem}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default ForumService;
