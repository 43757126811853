import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Webcam from 'react-webcam';

class WebCamModal extends React.Component {
  constructor(props) {
    super(props);

    this.webcamRef = React.createRef();

    this.state = {
      isOpen: props.isOpen,
    };

    this.capturar = this.capturar.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  capturar() {
    const screenshot = this.webcamRef.current.getScreenshot();
    if (screenshot) {
      this.props.callback(screenshot.substring(screenshot.indexOf(',') + 1, screenshot.length));
    } else {
      this.props.callback(null);
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}>
        <ModalBody>
          <Webcam
            ref={this.webcamRef}
            audio={false}
            width={400}
            height={300}
            onUserMediaError={() => { this.props.callback(null); }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => this.props.callback(undefined)}>
            Cancelar
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={() => this.capturar()}>
            <i className="fa fa-picture-o"></i>
            Capturar
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal>
    );
  }
}

export default WebCamModal;
