function getCompetenciasEspecificasHabiliadesCreche1(curso, idMateria) {
  const obj = [
    {
      //CORPO GESTOS E MOVIMENTO
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI01CG01) Movimentar as partes do corpo para exprimir corporalmente emoções, necessidades e desejos.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI01CG02) Experimentar as possibilidades corporais nas brincadeiras e interações em ambientes acolhedores e desafiantes.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI01CG03) Imitar gestos e movimentos de outras crianças, adultos e animais.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI01CG04) Participar do cuidado do seu corpo e da promoção do seu bem-estar.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI01CG05) Utilizar os movimentos de preensão, encaixe e lançamento, ampliando suas possibilidades de manuseio de diferentes materiais e objetos.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //ESCUTA FALA PENSAMENTO E IMAGINACAO
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI01EF01) Reconhecer quando é chamado por seu nome e reconhecer os nomes de pessoas com quem convive.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI01EF02) Demonstrar interesse ao ouvir a leitura de poemas e a apresentação de músicas.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI01EF03) Demonstrar interesse ao ouvir histórias lidas ou contadas, observando ilustrações e os movimentos de leitura do adulto-leitor (modo de segurar o portador e de virar as páginas).',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI01EF04) Reconhecer elementos das ilustrações de histórias, apontando-os, a pedido do adulto-leitor.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI01EF05) Imitar as variações de entonação e gestos realizados pelos adultos, ao ler histórias e ao cantar.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI01EF06) Comunicar-se com outras pessoas usando movimentos, gestos, balbucios, fala e outras formas de expressão.',
          materia: { id: idMateria },
        },
        {
          ordem: 6,
          descricao: '(EI01EF07) Conhecer e manipular materiais impressos e audiovisuais em diferentes portadores (livro, revista, gibi, jornal, cartaz, CD, tablet etc.).',
          materia: { id: idMateria },
        },
        {
          ordem: 7,
          descricao: '(EI01EF08) Participar de situações de escuta de textos em diferentes gêneros textuais (poemas, fábulas, contos, receitas, quadrinhos, anúncios etc.).',
          materia: { id: idMateria },
        },
        {
          ordem: 8,
          descricao: '(EI01EF09) Conhecer e manipular diferentes instrumentos e suportes de escrita.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //ESPACOS TEMPOS QUANTIDADES RELACOES E TRANSFORMACOES
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI01ET01) Explorar e descobrir as propriedades de objetos e materiais (odor, cor, sabor, temperatura).',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI01ET02) Explorar relações de causa e efeito (transbordar, tingir, misturar, mover e remover etc.) na interação com o mundo físico.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI01ET03) Explorar o ambiente pela ação e observação, manipulando, experimentando e fazendo descobertas.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI01ET04) Manipular, experimentar, arrumar e explorar o espaço por meio de experiências de deslocamentos de si e dos objetos.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI01ET05) Manipular materiais diversos e variados para comparar as diferenças e semelhanças entre eles.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI01ET06) Vivenciar diferentes ritmos, velocidades e fluxos nas interações e brincadeiras (em danças, balanços, escorregadores etc.).',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //O EU O OUTRO E O NOS
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI01EO01) Perceber que suas ações têm efeitos nas outras crianças e nos adultos.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI01EO02) Perceber as possibilidades e os limites de seu corpo nas brincadeiras e interações das quais participa.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI01EO03) Interagir com crianças da mesma faixa etária e adultos ao explorar espaços, materiais, objetos, brinquedos.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI01EO04) Comunicar necessidades, desejos e emoções, utilizando gestos, balbucios, palavras.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI01EO05) Reconhecer seu corpo e expressar suas sensações em momentos de alimentação, higiene, brincadeira e descanso.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI01EO06) Interagir com outras crianças da mesma faixa etária e adultos, adaptando-se ao convívio social.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //TRACOS SONS CORES E FORMAS 
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI01TS01) Explorar sons produzidos com o próprio corpo e com objetos do ambiente.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI01TS02) Traçar marcas gráficas, em diferentes suportes, usando instrumentos riscantes e tintas.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI01TS03) Explorar diferentes fontes sonoras e materiais para acompanhar brincadeiras cantadas, canções, músicas e melodias.',
          materia: { id: idMateria },
        },
      ],
    },
  ];
  return obj[curso];
}

export default getCompetenciasEspecificasHabiliadesCreche1;
