import BaseService from './BaseService';

class DiariosService extends BaseService {
  carregarConteudosLecionados(idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/conteudo/lecionado/diario/${idDiario}/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarConteudosLecionados(idDiario, conteudosLecionados) {
    const requestInfo = {
      method: 'put',
      url: `/conteudo/lecionado/diario/${idDiario}`,
      data: JSON.stringify(conteudosLecionados),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarConteudosLecionadosTurmaMultiseriado(idTurma, conteudosLecionados) {
    const requestInfo = {
      method: 'put',
      url: `/conteudo/lecionado/turma/multiseriado/${idTurma}`,
      data: JSON.stringify(conteudosLecionados),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default DiariosService;
