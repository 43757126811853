import BaseService from './BaseService';

class ItensAteliesService extends BaseService {
  salvarItensAtelies(idMateria, itensAtelies) {
    const requestInfo = {
      method: 'post',
      url: `/atelie/materia/${idMateria}`,
      data: JSON.stringify(itensAtelies),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarItensAteliesPorMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/atelie/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoItemAtelieNaMatriculaMateriaDiarioEBimestre(idItemAtelie, idMatricula, idMateria, idDiario, bimestre) {
    const requestInfo = {
      method: 'get',
      url: `/atelie/${idItemAtelie}/matricula/${idMatricula}/materia/${idMateria}/diario/${idDiario}/bimestre/${bimestre}/situacao`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  getObservacoesAtelieNaMatriculaEMateria(idMatricula, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/atelie/matricula/${idMatricula}/materia/${idMateria}/observacoes`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarAvaliacaoAtelie(idMatricula, idMateria, avaliacaoAtelie) {
    const requestInfo = {
      method: 'post',
      url: `/atelie/matricula/${idMatricula}/materia/${idMateria}/avaliacao`,
      data: JSON.stringify(avaliacaoAtelie),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default ItensAteliesService;
