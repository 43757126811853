function getCompetenciasEspecificasHabiliadesPre2(curso, idMateria) {
  const obj = [
    {
      //CORPO GESTOS E MOVIMENTO
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI03CG01) Criar com o corpo formas diversificadas de expressão de sentimentos, sensações e emoções, tanto nas situações do cotidiano quanto em brincadeiras, dança, teatro, música.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI03CG02) Demonstrar controle e adequação do uso de seu corpo em brincadeiras e jogos, escuta e reconto de histórias, atividades artísticas, entre outras possibilidades.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI03CG03) Criar movimentos, gestos, olhares e mímicas em brincadeiras, jogos e atividades artísticas como dança, teatro e música.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI03CG04) Adotar hábitos de autocuidado relacionados a higiene, alimentação, conforto e aparência.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI03CG05) Coordenar suas habilidades manuais no atendimento adequado a seus interesses e necessidades em situações diversas.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //ESCUTA FALA PENSAMENTO E IMAGINACAO
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI03EF01) Expressar ideias, desejos e sentimentos sobre suas vivências, por meio da linguagem oral e escrita (escrita espontânea), de fotos, desenhos e outras formas de expressão.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI03EF02) Inventar brincadeiras cantadas, poemas e canções, criando rimas, aliterações e ritmos.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI03EF03) Escolher e folhear livros, procurando orientar-se por temas e ilustrações e tentando identificar palavras conhecidas.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI03EF04) Recontar histórias ouvidas e planejar coletivamente roteiros de vídeos e de encenações, definindo os contextos, os personagens, a estrutura da história.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI03EF05) Recontar histórias ouvidas para produção de reconto escrito, tendo o professor como escriba.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI03EF06) Produzir suas próprias histórias orais e escritas (escrita espontânea), em situações com função social significativa.',
          materia: { id: idMateria },
        },
        {
          ordem: 6,
          descricao: '(EI03EF07) Levantar hipóteses sobre gêneros textuais veiculados em portadores conhecidos, recorrendo a estratégias de observação gráfica e/ou de leitura.',
          materia: { id: idMateria },
        },
        {
          ordem: 7,
          descricao: '(EI03EF08) Selecionar livros e textos de gêneros conhecidos para a leitura de um adulto e/ou para sua própria leitura (partindo de seu repertório sobre esses textos, como a recuperação pela memória, pela leitura das ilustrações etc.).',
          materia: { id: idMateria },
        },
        {
          ordem: 8,
          descricao: '(EI03EF09) Levantar hipóteses em relação à linguagem escrita, realizando registros de palavras e textos, por meiode escrita espontânea.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //ESPACOS TEMPOS QUANTIDADES RELACOES E TRANSFORMACOES
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI03ET01) Estabelecer relações de comparação entre objetos, observando suas propriedades.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI03ET02) Observar e descrever mudanças em diferentes materiais, resultantes de ações sobre eles, em experimentos envolvendo fenômenos naturais e artificiais.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI03ET03) Identificar e selecionar fontes de informações, para responder a questões sobre a natureza, seus fenômenos, sua conservação.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI03ET04) Registrar observações, manipulações e medidas, usando múltiplas linguagens (desenho, registro por números ou escrita espontânea), em diferentes suportes.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI03ET05) Classificar objetos e figuras de acordo com suas semelhanças e diferenças.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI03ET06) Relatar fatos importantes sobre seu nascimento e desenvolvimento, a história dos seus familiares e da sua comunidade.',
          materia: { id: idMateria },
        },
        {
          ordem: 6,
          descricao: '(EI03ET07) Relacionar números às suas respectivas quantidades e identificar o antes, o depois e o entre em uma sequência.',
          materia: { id: idMateria },
        },
        {
          ordem: 7,
          descricao: '(EI03ET08) Expressar medidas (peso, altura etc.), construindo gráficos básicos.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //O EU O OUTRO E O NOS
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI03EO01) Demonstrar empatia pelos outros, percebendo que as pessoas têm diferentes sentimentos, necessidades e maneiras de pensar e agir.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI03EO02) Agir de maneira independente, com confiança em suas capacidades, reconhecendo suas conquistas e limitações.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI03EO03) Ampliar as relações interpessoais, desenvolvendo atitudes de participação e cooperação.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI03EO04) Comunicar suas ideias e sentimentos a pessoas e grupos diversos.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI03EO05) Demonstrar valorização das características de seu corpo e respeitar as características dos outros (crianças e adultos) com os quais convive.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI03EO06) Manifestar interesse e respeito por diferentes culturas e modos de vida.',
          materia: { id: idMateria },
        },
        {
          ordem: 6,
          descricao: '(EI03EO07) Usar estratégias pautadas no respeito mútuo para lidar com conflitos nas interações com crianças e adultos.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //TRACOS SONS CORES E FORMAS 
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI03TS01) Utilizar sons produzidos por materiais, objetos e instrumentos musicais durante brincadeiras de faz de conta, encenações, criações musicais, festas.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI03TS02) Expressar-se livremente por meio de desenho, pintura, colagem, dobradura e escultura, criando produções bidimensionais e tridimensionais.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI03TS03) Reconhecer as qualidades do som (intensidade, duração, altura e timbre), utilizando-as em suas produções sonoras e ao ouvir músicas e sons.',
          materia: { id: idMateria },
        },
      ],
    },
  ];
  return obj[curso];
}

export default getCompetenciasEspecificasHabiliadesPre2;
