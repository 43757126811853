import React from 'react';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import { connect } from 'react-redux';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';

import SituacaoAnoLetivo from 'assets/csv/situacoes-ano-letivo.json';
import Papel from 'assets/csv/papeis.json';

class AnosLetivos extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();

    this.situacoes = [
      SituacaoAnoLetivo.ABERTO,
      SituacaoAnoLetivo.CONSOLIDADO,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-anos-letivos`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      showAlert: false,

      anoLetivoSelecionado: null,

      showLoaderModal: false,
      showConfirmModal: false,

      dadosTabela: {
        header: [['Ano', '20%'], ['Início', '25%'], ['Término', '25%'], ['Situação', '20%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-left'],
        keys: ['ano', 'dataInicioBimestre1', 'dataFimBimestre4', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    };

    this.carregarAnosLetivos = this.carregarAnosLetivos.bind(this);
    this.editarAnoLetivo = this.editarAnoLetivo.bind(this);
    this.confirmarRemocaoAnoLetivo = this.confirmarRemocaoAnoLetivo.bind(this);
    this.removerAnoLetivo = this.removerAnoLetivo.bind(this);
    this.anoLetivoSelecionado = this.anoLetivoSelecionado.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    this.carregarAnosLetivos(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarAnosLetivos(pagina, linhasPorPagina, like) {
    try {
      const data = await this.anosLetivosService
        .carregarAnosLetivos(pagina, linhasPorPagina, like);

      data.anosLetivos.forEach((anoLetivo) => {
        Object.assign(anoLetivo, { situacao_str: this.situacoes[anoLetivo.situacao].label });
      });
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.anosLetivos, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar anos letivos cadastrados',
      });
    }
  }

  editarAnoLetivo(anoLetivo) {
    this.props.history.push({
      pathname: `${this.props.layout}/anosletivos/edicao/${anoLetivo.id}`,
      state: {
        anoLetivo,
      },
    });
  }

  confirmarRemocaoAnoLetivo(anoLetivo) {
    this.setState({
      anoLetivoSelecionado: anoLetivo,
      showConfirmModal: true,
      deletionText: `${anoLetivo.ano}`,
    });
  }

  async removerAnoLetivo(senha) {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.anosLetivosService
        .removerAnoLetivo(this.state.anoLetivoSelecionado.id, senha);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ano letivo removido com sucesso',
      });
      this.carregarAnosLetivos(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (err) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: err || 'Erro ao remover ano letivo',
      });
    }
  }

  anoLetivoSelecionado(anoLetivo) {
    this.props.history.push({
      pathname: `${this.props.layout}/anosletivos/${anoLetivo.id}`,
    });
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem active>Anos letivos</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Anos letivos cadastrados</CardTitle>
              </CardHeader>
              <CardBody>
                <AdaptiveTable
                  selectedPage={this.paginaSelecionada}
                  rowsPerPage={this.linhasPorPagina}
                  disablePrintAction
                  disableActions={this.props.role !== Papel.GESTOR.value}
                  editButtonCallback={(anoLetivo) => this.editarAnoLetivo(anoLetivo)}
                  removeButtonCallback={(anoLetivo) => this.confirmarRemocaoAnoLetivo(anoLetivo)}
                  rowsPerPageCallback={(info) => {
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.linhasPorPagina = info.rowsPerPage;
                    sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                    this.carregarAnosLetivos(this.paginaSelecionada, info.rowsPerPage, this.like);
                  }}
                  likeCallback={(text) => {
                    this.like = text;
                    this.paginaSelecionada = 1;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarAnosLetivos(this.paginaSelecionada, this.linhasPorPagina, text);
                  }}
                  paginatorCallback={(page) => {
                    this.paginaSelecionada = page;
                    sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                    this.carregarAnosLetivos(this.paginaSelecionada,
                      this.linhasPorPagina, this.like);
                  }}
                  rowCallback={(anoLetivo) => {
                    this.anoLetivoSelecionado(anoLetivo);
                  }}
                  data={this.state.dadosTabela}
                  clickRows />
              </CardBody>
              {
                this.props.role === Papel.GESTOR.value && <CardFooter className="text-right">
                  <Button color="primary"
                    onClick={() => {
                      this.props.history.push({ pathname: `${this.props.layout}/anosletivos/cadastro` });
                    }}>
                    Cadastrar ano letivo
                </Button>
                </CardFooter>
              }
            </Card>
          </Col>
        </Row>
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.removerAnoLetivo(senha);
            } else {
              this.setState({ anoLetivoSelecionado: null });
            }
          }}
          deletionInstruction='Confirme que você quer excluir o ano letivo digitando a sua senha no campo abaixo'
          deletionText={''}
          deletionWarning='A exclusão de um ano letivo acarretará a exclusão de todas as turmas do ano letivo, seus diários e demais dados relacionados' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(AnosLetivos);
