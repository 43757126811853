import React from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
} from 'reactstrap';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import ChamadosService from 'services/ChamadosService';

import TipoChamado from 'assets/csv/tipos-chamado.json';
import SituacaoChamado from 'assets/csv/situacoes-chamado.json';

class Chamados extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-chamados-${props.situacao}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.tipos = [
      TipoChamado.DUVIDA,
      TipoChamado.SUGESTAO,
      TipoChamado.ERRO,
    ];

    this.situacoes = [
      SituacaoChamado.ABERTO,
      SituacaoChamado.EM_ANDAMENTO,
      SituacaoChamado.FINALIZADO,
    ];

    this.state = {
      chamadoSelecionado: null,

      dadosTabela: {
        header: [['Título', '60%'], ['Data', '10%'], ['Tipo', '10%'], ['Protocolo', '10%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['titulo', 'data', 'tipo_str', 'protocolo'],
        rows: [],
        total: undefined,
      },
    };

    this.chamadosService = new ChamadosService();
  }

  componentWillUnmount() {
    this.chamadosService.abortPedingRequests();
  }

  async componentDidMount() {
    this.carregarChamados(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarChamados(pagina, linhasPorPagina, like) {
    try {
      const data = this.props.idEnte ? await this.chamadosService
        .carregarChamadosPorEnteSituacao(this.props.idEnte,
          this.props.situacao, pagina, linhasPorPagina, like)
        : await this.chamadosService
          .carregarChamadosPorSituacao(this.props.situacao, pagina, linhasPorPagina, like);

      data.chamados.forEach((chamado) => {
        Object.assign(chamado, {
          tipo_str: this.tipos[chamado.tipo].label,
          situacao_str: this.situacoes[chamado.situacao].label,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.chamados, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar chamados cadastrados',
      });
    }
  }

  editarChamado(chamado) {
    this.props.history.push({
      pathname: `/gestor/chamados/edicao/${chamado.id}`,
    });
  }

  confirmarRemocaoChamado(chamado) {
    this.setState({ chamadoSelecionado: chamado, showConfirmModal: true });
  }

  async removerChamado() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.chamadosService
        .removerChamado(this.state.chamadoSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Chamado removido com sucesso',
      });

      this.carregarChamados(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover chamado',
      });
    }
  }

  chamadoSelecionado(chamado) {
    if (this.props.idEnte) {
      this.props.history.push(`${this.props.layout}`
        + `/entes/${this.props.match.params.idEnte}/chamados/${chamado.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/chamados/${chamado.id}`);
    }
  }

  render() {
    return (
      <>
        <br />
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions={this.props.idEnte}
              disablePrintAction
              clickRows
              editButtonCallback={(chamado) => this.editarChamado(chamado)}
              removeButtonCallback={(chamado) => this
                .confirmarRemocaoChamado(chamado)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarChamados(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarChamados(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarChamados(this.paginaSelecionada,
                  this.linhasPorPagina, this.like);
              }}
              rowCallback={(chamado) => this.chamadoSelecionado(chamado)}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerChamado();
            } else {
              this.setState({ escolaSelecionada: null });
            }
          }}
          text='Confirme a exclusão do chamado' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Chamados);
