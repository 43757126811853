function getCamposAprendizagemPre1Pre2(curso, idMateria) {
  const obj = [
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos, experimentando marcas da cultura corporal nos cuidados pessoais, na dança, música, teatro, artes circenses, escuta de histórias e brincadeiras.\n\nBRINCAR utilizando criativamente o repertório da cultura corporal e do movimento.\n\nEXPLORAR amplo repertório de movimentos, gestos, olhares, produção de sons e de mímicas, descobrindo modos de ocupação e de uso do espaço com o corpo.\n\nPARTICIPAR de atividades que envolvem práticas corporais, desenvolvendo autonomia para cuidar de si.\n\nEXPRESSAR corporalmente emoções e representações tanto nas relações cotidianas como nas brincadeiras, dramatizações, danças, músicas e contação de histórias.\n\nCONHECER-SE nas diversas oportunidades de interações e explorações com seu corpo.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI03CG01) Criar com o corpo formas diversificadas de expressão de sentimentos, sensações e emoções, tanto nas situações do cotidiano quanto em brincadeiras, dança, teatro, música.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '119A Construir uma imagem positiva de si mesmo reconhecendo e valorizando suas características.',
              descricao2: '119B Valorizar suas características a partir de representações de auto retrato.',
            },
            {
              ordem: 1,
              descricao: '120A Representar-se em situações de brincadeiras ou teatro, apresentando suas características corporais, sentimentos, sensações ou emoções.',
              descricao2: '120B Falar positivamente sobre si mesmo, suas características, qualidades, sentimentos, etc.',
            },
            {
              ordem: 2,
              descricao: '121A Experimentar jogos de imitação de si mesmo e de outras pessoas.',
              descricao2: '121B Vivenciar brincadeiras diante do espelho ou tomando outra criança como referência.',
            },
            {
              ordem: 3,
              descricao: '122A Participar de situações em que possa cantar, gesticular e expressar suas emoções a partir de músicas e cantigas.',
              descricao2: '122B Demonstrar, a partir de expressões corporais, suas habilidades ou atitudes, características, a partir de gestos e movimentos que as representem.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI03CG02) Demonstrar controle e adequação do uso de seu corpo em brincadeiras e jogos, escuta e reconto de histórias, atividades artísticas, entre outras possibilidades.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '123A Experimentar diferentes movimentos juntamente a outras crianças, onde possam imitar, elaborar, recriar ações, utilizando diferentes materiais, como fitas, lençol, bolas, explorando os espaços e a qualidade do movimento a partir de orientações de adultos ou outras crianças.',
              descricao2: '123B Participar de situações de brincadeira com músicas de diferentes gêneros e ritmos, criando movimentos, gestos e expressões a partir delas.',
            },
            {
              ordem: 1,
              descricao: '124A Movimentar-se seguindo uma sequência de ações, considerando o compasso definido pela música ou por coordenadas.',
              descricao2: '124B Criar movimentos e expressões a partir de novos desafios.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI03CG03) Criar movimentos, gestos, olhares e mímicas em brincadeiras, jogos e atividades artísticas como dança, teatro e música.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '125A Apreciar e realizar apresentações de dança de diferentes gêneros, bem como expressões da cultura corporal (circo, esportes, mímica, teatro etc.).',
              descricao2: '125B Criar movimentos dançando ou dramatizando para expressar-se em suas brincadeiras, podendo combinar seus movimentos com os de outras crianças.',
            },
            {
              ordem: 1,
              descricao: '126A Apreciar e realizar encenações teatrais a partir de histórias de sua preferência, podendo fazer uso de fantoches, figuras de sombras, bonecos, etc.',
              descricao2: '126B Aprender novos movimentos e gestos a partir da observação, imitação e interação com os colegas, utilizando-os em suas apresentações teatrais.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI03CG04) Adotar hábitos de autocuidado relacionados a higiene, alimentação, conforto e aparência.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '127A Observar, participar e reconhecer as noções básicas de práticas de autocuidado, como por exemplo, agasalhar-se, limpar o nariz, guardar os seus pertences, limpar seu prato, etc.',
              descricao2: '127B Realizar ações independentes de autocuidado, como beber água, servir-se, alimentar-se, banhar-se, vestir-se, etc.',
            },
            {
              ordem: 1,
              descricao: '128A Conversar com crianças e adultos sobre práticas sociais de autocuidados nos momentos de alimentação, banho, etc.',
              descricao2: '128B Conversar com crianças e adultos sobre práticas sociais de autocuidados nos momentos de alimentação, banho, etc.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI03CG05) Coordenar suas habilidades manuais no atendimento adequado a seus interesses e necessidades em situações diversas.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '129A Manipular diferentes objetos com tamanhos e pesos diversificados, em situações em que as crianças possam: empilhar, encaixar, rosquear e pinçar, chutar, arremessar e receber.',
              descricao2: '129B Explorar materiais de diferentes dimensões e matéria, como barro, gelatina, massinha de modelar, argila, etc.',
            },
            {
              ordem: 1,
              descricao: '130A Criar e recriar objetos ou brinquedos a partir de materiais diversos encontrados na natureza.',
              descricao2: '130B Criar e recriar objetos ou brinquedos a partir de materiais diversos encontrados na natureza ou industrializados.',
            },
            {
              ordem: 2,
              descricao: '131A Ser capaz de pegar e guardar objetos utilizados, organizando e mantendo o ambiente educativo limpo.',
              descricao2: '131B Ser capaz de cuidar e manter o ambiente educativo e os materiais organizados e higienizados.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos em situações comunicativas cotidianas, constituindo modos de pensar, imaginar, sentir, narrar, dialogar e conhecer.\n\nBRINCAR com parlendas, trava-línguas, adivinhas, memória, rodas, brincadeiras cantadas, jogos e textos de imagens, escritos e outros, ampliando o repertório das manifestações culturais da tradição local e de outras culturas, enriquecendo sua linguagem oral, corporal, musical, dramática, escrita, dentre outras.\n\nPARTICIPAR de rodas de conversa, de relatos de experiências, de contação e leitura de histórias e poesias, de construção de narrativas, da elaboração, descrição e representação de papéis no faz de conta, da exploração de materiais impressos e de variedades linguísticas, construindo diversas formas de organizar o pensamento.\n\nEXPLORAR gestos, expressões, sons da língua, rimas, imagens, textos escritos, além dos sentidos das palavras nas poesias, parlendas, canções e nos enredos de histórias, apropriando-se desses elementos para criar novas falas, enredos, histórias e escritas, convencionais ou não.\n\nEXPRESSAR sentimentos, ideias, percepções, desejos, necessidades, pontos de vista, informações, dúvidas e descobertas, utilizando múltiplas linguagens, considerando o que é comunicado pelos colegas e adultos.\n\nCONHECER-SE e reconhecer suas preferências por pessoas, brincadeiras, lugares, histórias, autores, gêneros linguísticos e seu interesse em produzir com a linguagem verbal.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI03EF01) Expressar ideias, desejos e sentimentos sobre suas vivências, por meio da linguagem oral e escrita (escrita espontânea), de fotos, desenhos e outras formas de expressão.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '278A Comunicar-se com diferentes intenções, em diferentes contextos, com diferentes interlocutores, respeitando sua vez de falar e escutando o outro com atenção.',
              descricao2: '278B Comunicar-se com diferentes intenções, em diferentes contextos, com diferentes interlocutores, respeitando sua vez de falar e escutando o outro com atenção.',
            },
            {
              ordem: 1,
              descricao: '279A Participar de rodas de conversa onde discutem seus pontos de vista sobre um assunto.',
              descricao2: '279B Debater um assunto polêmico do cotidiano da unidade, por exemplo, como organizar o uso dos brinquedos do parque etc.',
            },
            {
              ordem: 2,
              descricao: '280A Descrever como foi feita uma produção individual ou coletiva de um texto, uma escultura, uma coreografia etc.',
              descricao2: '280B Organizar oralmente as etapas de uma tarefa, os passos de uma receita culinária, do preparo de uma tinta ou as regras para uma brincadeira, etc.',
            },
            {
              ordem: 3,
              descricao: '281A Expressar oralmente opinião sobre um relato apresentado por um colega ou pelo(a) professor(a).',
              descricao2: '281B Expressar oralmente ideias, opiniões e sentimentos relativos às diferentes situações vivenciadas no cotidiano.',
            },
            {
              ordem: 4,
              descricao: '282A Conversar sobre suas fotos, desenhos e outras formas de expressão.',
              descricao2: '282B Conversar sobre suas fotos, desenhos e outras formas de expressão.',
            },
            {
              ordem: 5,
              descricao: '283A Interessar-se por fazer uso da escrita espontânea para comunicar suas ideias e opiniões aos colegas, professores(as) e demais adultos.',
              descricao2: '283B Escrever umas às outras, aos seus familiares e a pessoas da comunidade escolar.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI03EF02) Inventar brincadeiras cantadas, poemas e canções, criando rimas, aliterações e ritmos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '284A Desenvolver o hábito e o prazer por escutar, recitar e ler textos poéticos.',
              descricao2: '284B Desenvolver o hábito e o prazer por escutar, recitar e ler textos poéticos.',
            },
            {
              ordem: 1,
              descricao: '285A Declamar poemas, parlendas e canções em suas brincadeiras.',
              descricao2: '285B Declamar seus poemas e parlendas preferidas fazendo uso de ritmo e entonação.',
            },
            {
              ordem: 2,
              descricao: '286A Divertir-se e interessar-se por brincar com os textos poéticos em suas brincadeiras livres com outras crianças.',
              descricao2: '286B Divertir-se conversando sobre as palavras rimadas ao brincar com seu ritmo, identificando rimas, assonâncias e aliterações.',
            },
            {
              ordem: 3,
              descricao: '287A Compreender as relações entre a oralidade e a escrita, participando de atividades em pequenos grupos nos quais buscam corresponder a leitura dos textos favoritos que já conhecem de memória com sua escrita.',
              descricao2: '287B Compreender as relações entre a oralidade e a escrita, fazendo correspondência entre a leitura dos textos que já conhecem de memória com sua escrita.',
            },
            {
              ordem: 4,
              descricao: '-',
              descricao2: '288B Identificar que os textos se dividem em partes e o verso corresponde a uma delas.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI03EF03) Escolher e folhear livros, procurando orientar-se por temas e ilustrações e tentando identificar palavras conhecidas.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '289A Construir um repertório de histórias conhecidas e memorizadas, participando de situações de leitura com os colegas.',
              descricao2: '289B Acompanhar a leitura de textos que sabem de cor, identificando palavras conhecidas, suas ilustrações, e a parte do texto escrito a que se referem.',
            },
            {
              ordem: 1,
              descricao: '290A Recontar narrativas, apoiadas nas ilustrações ou na identificação de partes do texto ou de palavras conhecidas.',
              descricao2: '290B Recontar narrativas, apoiadas nas ilustrações ou na identificação de partes do texto ou de palavras conhecidas.',
            },
            {
              ordem: 2,
              descricao: '291A Localizar no texto o nome dos personagens.',
              descricao2: '291B Ordenar ilustração e corresponder com o texto.',
            },
            {
              ordem: 3,
              descricao: '292A Recontar narrativas, apoiadas nas ilustrações ou na identificação de partes do texto ou de palavras conhecidas.',
              descricao2: '292B Recontar narrativas, apoiadas nas ilustrações ou na identificação de partes do texto ou de palavras conhecidas.',
            },
            {
              ordem: 4,
              descricao: '293A Localizar no texto o nome dos personagens.',
              descricao2: '293B Ordenar ilustração e corresponder com o texto.',
            },
            {
              ordem: 5,
              descricao: '294A Explorar e manusear livros fazendo suas investigações, brincando com seu enredo e criando contextos de leitura e dramatização em suas brincadeiras individuais ou em pequenos grupos.',
              descricao2: '294B Interessar-se por folhear livros e escolher aqueles que mais gostam para ler em momentos individuais.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI03EF04) Recontar histórias ouvidas e planejar coletivamente roteiros de vídeos e de encenações, definindo os contextos, os personagens, a estrutura da história.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '295A Apropriar-se das narrativas e se interessar por conversar e brincar com elas, desenvolvendo sua imaginação e sua criatividade, ao mesmo tempo em que se apropriam de noções da linguagem e da escrita.',
              descricao2: '295B Apropriar-se das narrativas e se interessar por conversar e brincar com elas, desenvolvendo sua imaginação e sua criatividade, ao mesmo tempo em que se apropriam de noções da linguagem e da escrita.',
            },
            {
              ordem: 1,
              descricao: '296A Construir e representar histórias, apropriando-se da estrutura da narrativa, identificando seus personagens e cenários, sua trama e sua sequência cronológica, em situações de brincadeiras e jogos teatrais.',
              descricao2: '296B Conversar acerca dos elementos da estrutura narrativa de histórias conhecidas, identificando personagens, cenários, trama e sequência cronológica, para a construção de roteiros de vídeos ou encenações.',
            },
            {
              ordem: 2,
              descricao: '297A Apreciar diversas situações de escuta de histórias, seja por meio da leitura ou contação pelo(a) professor(a), por outra criança, por dramatizações, filmes ou áudios.',
              descricao2: '297B Apreciar diversas situações de escuta de histórias, seja por meio da leitura ou contação pelo(a) professor(a), por outra criança, por dramatizações, filmes ou áudios.',
            },
            {
              ordem: 3,
              descricao: '298A Envolver-se em situações de pequenos grupos, contribuindo para a construção de roteiros de vídeos ou encenações coletivas.',
              descricao2: '298B Ditar partes da história ao participar da construção de roteiros de vídeos ou encenações.',
            },
            {
              ordem: 4,
              descricao: '-',
              descricao2: '299B Encontrar diálogos memorizados no texto escrito.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI03EF05) Recontar histórias ouvidas para produção de reconto escrito, tendo o professor como escriba.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '300A Desenvolver a capacidade de recuperar um texto de memória, de atentar para a sua linguagem, de controlar a velocidade da fala, de conscientizar-se sobre a estabilidade de um texto e sobre a diferença entre o texto escrito e aquilo que se fala sobre ele, ao recontar histórias tendo o(a) professor(a) como escriba.',
              descricao2: '300B Desenvolver a capacidade de recuperar um texto de memória, de atentar para a sua linguagem, de controlar a velocidade da fala, de conscientizar-se sobre a estabilidade de um texto e sobre a diferença entre o texto escrito e aquilo que se fala sobre ele, ao recontar histórias tendo o(a) professor(a) como escriba.',
            },
            {
              ordem: 1,
              descricao: '301A Interessar-se por participar de situações coletivas de criação ou reconto de histórias.',
              descricao2: '301B Criar e escrever suas próprias narrativas a partir de um bom repertório de narrações conhecidas e memorizadas.',
            },
            {
              ordem: 2,
              descricao: '302A Relatar aos colegas histórias lidas por alguém de sua família.',
              descricao2: '302B Relatar aos colegas histórias lidas por alguém de sua família.',
            },
            {
              ordem: 3,
              descricao: '303A Escolher e gravar poemas para enviar a outras crianças ou aos familiares e participar de sarau literário, narrando ou recitando seus textos favoritos.',
              descricao2: '303B Escolher e gravar poemas para enviar a outras crianças ou aos familiares e participar de sarau literário, narrando ou recitando seus textos favoritos.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI03EF06) Produzir suas próprias histórias orais e escritas (escrita espontânea), em situações com função social significativa.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '304A Envolver-se em situações de reconto de histórias expressando satisfação e gosto por suas conquistas.',
              descricao2: '304B Envolver-se em situações de reconto de histórias expressando satisfação e gosto por suas conquistas.',
            },
            {
              ordem: 1,
              descricao: '305A Memorizar partes dos textos para recontá-los em contextos de função social, como em saraus literários, em uma peça de teatro, na construção da narrativa de uma encenação, etc.',
              descricao2: '305B Recontar oralmente textos de histórias conhecidas em contextos de socialização.',
            },
            {
              ordem: 2,
              descricao: '306A Fazer uso de expressões da linguagem da narrativa, como "era uma vez", ao recontar ou criar suas próprias histórias.',
              descricao2: '306B Utilizar expressões da linguagem escrita em suas produções orais e escritas de histórias.',
            },
            {
              ordem: 3,
              descricao: '307A Criar e escrever histórias de aventuras, definindo o ambiente em que ela ocorre, as características e desafios de seus personagens.',
              descricao2: '307B Criar e escrever histórias de aventuras, definindo o ambiente em que ela ocorre, as características e desafios de seus personagens.',
            },
          ],
        },
        {
          ordem: 6,
          descricao: '(EI03EF07) Levantar hipóteses sobre gêneros textuais veiculados em portadores conhecidos, recorrendo a estratégias de observação gráfica e/ou de leitura.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '308A Escutar, explorar e conversar sobre diversos gêneros textuais em diferentes portadores.',
              descricao2: '308B Identificar e utilizar diferentes estruturas gráficas dos textos segundo seu gênero e seus portadores.',
            },
            {
              ordem: 1,
              descricao: '309A Desenvolver atitudes típicas de um leitor, como buscar informação de ingredientes em uma receita, buscar o título de uma história no índice do livro etc.',
              descricao2: '309B Desenvolver atitudes típicas de um leitor, como buscar informação de ingredientes em uma receita, buscar o título de uma história no índice do livro etc.',
            },
            {
              ordem: 2,
              descricao: '310A Buscar informações sobre algum tema a ser estudado em livros ou revistas com textos informativos, fazendo uso da leitura das fotos ou legendas para se apropriar de informações.',
              descricao2: '310B Buscar informações sobre algum tema a ser estudado em livros ou revistas com textos informativos, fazendo uso da leitura das fotos ou legendas para se apropriar de informações.',
            },
            {
              ordem: 3,
              descricao: '311A Experimentar ler textos diversos apoiada nas informações sobre o gênero, o conteúdo ou a autoria dos textos.',
              descricao2: '311B Experimentar ler textos diversos apoiada nas informações sobre o gênero, o conteúdo ou a autoria dos textos.',
            },
            {
              ordem: 4,
              descricao: '312A Experimentar ler textos digitais interagindo com os elementos disponíveis como mídias, imagens, gráficos, etc.',
              descricao2: '312B Experimentar ler textos digitais interagindo com os elementos disponíveis como mídias, imagens, gráficos, etc.',
            },
          ],
        },
        {
          ordem: 7,
          descricao: '(EI03EF08) Selecionar livros e textos de gêneros conhecidos para a leitura de um adulto e/ou para sua própria leitura (partindo de seu repertório sobre esses textos, como a recuperação pela memória, pela leitura das ilustrações etc.).',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '313A Conversar sobre os diferentes gêneros, criando o gosto pela leitura e construindo um repertório de textos e suportes conhecidos.',
              descricao2: '313B Identificar elementos gráficos, textuais e de conteúdo, desenvolvendo o gosto pessoal por alguns textos.',
            },
            {
              ordem: 1,
              descricao: '314A Identificar um livro pela leitura do título.',
              descricao2: '314B Apresentar uma história mostrando a capa do livro, o título e o nome do autor.',
            },
            {
              ordem: 2,
              descricao: '315A Ler o texto de um poema identificando as palavras que rimam etc.',
              descricao2: '315B Ler o texto de um poema identificando as palavras que rimam, os versos, etc.',
            },
            {
              ordem: 3,
              descricao: '316A Reconhecer o uso social de textos como convites para festas de aniversário, roteiro de atividades do dia, comunicados aos pais e listas variadas.',
              descricao2: '316B Reconhecer o uso social de textos como convites para festas de aniversário, roteiro de atividades do dia, comunicados aos pais e listas variadas, ativando estratégias de leitura na compreensão de seu conteúdo, objetivos e destinatários.',
            },
            {
              ordem: 4,
              descricao: '317A Interessar-se por ler diferentes gêneros textuais, apropriando-se dos usos e funções sociais da linguagem escrita.',
              descricao2: '317B Ampliar seu repertório cultural literário, desenvolvendo sensibilidade, criticidade, criatividade, gosto e prazer pela leitura e suas finalidades sociais.',
            },
            {
              ordem: 5,
              descricao: '318A Realizar a leitura de histórias compostas apenas de imagens como possibilidade de criação de narrativas.',
              descricao2: '318B Realizar a leitura de histórias compostas apenas de imagens como possibilidade de criação de narrativas.',
            },
          ],
        },
        {
          ordem: 8,
          descricao: '(EI03EF09) Levantar hipóteses em relação à linguagem escrita, realizando registros de palavras e textos, por meio de escrita espontânea.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '319A Escrever de seu próprio jeito atribuindo sentido à sua intenção de comunicação escrita.',
              descricao2: '319B Escrever de seu próprio jeito atribuindo sentido à sua intenção de comunicação escrita.',
            },
            {
              ordem: 1,
              descricao: '320A Refletir sobre o que escreveram enfrentando questões com as quais se deparam ao perceber que sua escrita não corresponde à escrita convencional.',
              descricao2: '320B Refletir sobre o que escreveram enfrentando questões com as quais se deparam ao perceber que sua escrita não corresponde à escrita convencional.',
            },
            {
              ordem: 2,
              descricao: '321A Escrever, de seu próprio jeito, histórias conhecidas ou criadas por elas, parlendas e cantigas, construindo uma coleção daquelas que são as suas preferidas, além de palavras que rimam, brincando com a linguagem e a escrita.',
              descricao2: '321B Escrever, de seu próprio jeito, histórias conhecidas ou criadas por elas, parlendas e cantigas, construindo uma coleção daquelas que são as suas preferidas, além de palavras que rimam, brincando com a linguagem e a escrita.',
            },
            {
              ordem: 3,
              descricao: '322A Escrever umas às outras por meio de cartas, recados ou diários.',
              descricao2: '322B Escrever umas às outras por meio de cartas, recados ou diários.',
            },
            {
              ordem: 4,
              descricao: '323A Escrever o nome sempre que for necessário reconhecendo a semelhança entre a letra inicial de seu nome e as iniciais dos nomes dos colegas que possuem a mesma letra.',
              descricao2: '323B Estabelecer relação entre grafema e fonema do nome próprio e de algumas palavras estáveis.',
            },
            {
              ordem: 5,
              descricao: '324A Elaborar convites, comunicados e listas, panfletos com as regras de um jogo, ainda que de modo não convencional.',
              descricao2: '324B Elaborar convites, comunicados e listas, panfletos com as regras de um jogo, ainda que de modo não convencional.',
            },
            {
              ordem: 6,
              descricao: '325A Levantar hipóteses sobre o que está escrito e sobre como se escreve utilizando conhecimentos sobre o sistema de escrita para localizar um nome específico em uma lista de palavras (ingredientes de uma receita culinária, peças do jogo, etc.) ou palavras em um texto que sabem de memória.',
              descricao2: '325B Levantar hipóteses sobre o que está escrito e sobre como se escreve utilizando conhecimentos sobre o sistema de escrita para localizar um nome específico em uma lista de palavras (ingredientes de uma receita culinária, peças do jogo, etc.) ou palavras em um texto que sabem de memória.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos e com eles investigar o mundo natural e social.\n\nBRINCAR com materiais, objetos e elementos da natureza e de diferentes culturas e perceber a diversidade de formas, texturas, cheiros, cores, tamanhos, pesos e densidades que apresentam.\n\nEXPLORAR características do mundo natural e social, nomeando-as, agrupando-as e ordenando-as segundo critérios relativos às noções de espaços, tempos, quantidades, relações e transformações.\n\nPARTICIPAR de atividades de investigação de características de elementos naturais, objetos, situações, espaços, utilizando ferramentas de exploração — bússola, lanterna, lupa — e instrumentos de registro e comunicação, como máquina fotográfica, filmadora, gravador, projetor e computador.\n\nEXPRESSAR suas observações, explicações e representações sobre objetos, organismos vivos, fenômenos da natureza, características do ambiente.\n\nCONHECER-SE e construir sua identidade pessoal e cultural, reconhecendo seus interesses na relação com o mundo físico e social.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI03ET01) Estabelecer relações de comparação entre objetos, observando suas propriedades.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '371A Brincar com objetos de vários tamanhos, observando suas grandezas (grande/pequeno/comprido/curto) ao falar sobre eles.',
              descricao2: '371B Observar e comparar com seus pares, as diferenças entre o tamanho dos pés e número dos sapatos, altura, peso.',
            },
            {
              ordem: 1,
              descricao: '372A Explorar materiais como argila e massa de modelar percebendo a transformação do espaço bidimensional e tridimensional a partir da construção e desconstrução',
              descricao2: '372B Montar mosaico, maquetes e torres com brinquedos de encaixe e materiais alternativos.',
            },
            {
              ordem: 2,
              descricao: '373A Levantar objetos de pesos diferentes.',
              descricao2: '373B Participar de atividades de culinária usando e produzindo receitas, observando medidas de peso, quantidade, capacidade.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI03ET02) Observar e descrever mudanças em diferentes materiais, resultantes de ações sobre eles, em experimentos envolvendo fenômenos naturais e artificiais.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '374A Observar a transformação da água e outras substâncias, no processo de manuseio, perguntando e levantando hipóteses sobre o fenômeno ocorrido.',
              descricao2: '374B Reunir informações de diferentes fontes para descobrir porque as coisas acontecem e como funcionam.',
            },
            {
              ordem: 1,
              descricao: '375A Explorar propriedades dos objetos como a de refletir, ampliar ou inverter as imagens.',
              descricao2: '375B Reunir informações de diferentes fontes e registrar/comunicar, de diferentes formas, suas descobertas sobre como as coisas acontecem (oralmente, por meio da escrita, através de encenações, etc).',
            },
            {
              ordem: 2,
              descricao: '376A Nomear ou descrever características ou semelhanças frente aos fenômenos da natureza estabelecendo algumas relações de causa e efeito.',
              descricao2: '376B Descrever características geográficas e paisagens de diferentes lugares destacando aqueles que são típicos de sua região.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI03ET03) Identificar e selecionar fontes de informações, para responder a questões sobre a natureza, seus fenômenos, sua conservação.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '378A Observar e criar explicações para fenômenos e elementos da natureza presentes no seu dia a dia.',
              descricao2: '378B Reunir informações de diferentes fontes, com ajuda do professor para explicar os fenômenos e elementos da natureza presentes no seu dia a dia, relacionando-os à necessidade dos humanos por abrigo e cuidados básicos.',
            },
            {
              ordem: 1,
              descricao: '379A Descrever os objetos utilizados em tempos de chuva e sol e a importância de seu uso.',
              descricao2: '379B Identificar os efeitos e causas de algumas mudanças de hábitos em animais ou plantas influenciadas por mudanças climáticas.',
            },
            {
              ordem: 2,
              descricao: '380A Ler, interpretar e produzir registros como desenhos, textos orais ou escritos (escrita espontânea) sobre os fenômenos naturais observados.',
              descricao2: '380B Ler, interpretar e produzir registros como desenhos, textos orais ou escritos (escrita espontânea) sobre os fenômenos naturais observados.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI03ET04) Registrar observações, manipulações e medidas, usando múltiplas linguagens (desenho, registro por números ou escrita espontânea), em diferentes suportes.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '381A Brincar livremente com objetos e ferramentas de medidas convencionais ou não a fim de estabelecer distância, comprimento, capacidade e massa.',
              descricao2: '381B Participar de situações do cotidiano, como, por exemplo, atividades de culinária, sendo instigadas a resolver problemas envolvendo unidades de medida, como quantidade de ingredientes ou tempo de cozimento.',
            },
            {
              ordem: 1,
              descricao: '382A Usar notas e moedas nos contextos de brincadeiras com desafio de pegar e dar troco.',
              descricao2: '382B Brincar livremente, tendo como recursos objetos e ferramentas de medidas, convencionais ou não, a fim de estabelecer distância, comprimento, capacidade (litro) e massa.',
            },
            {
              ordem: 2,
              descricao: '383A Representar a sua sala, o quarto onde dorme ou o pátio, com seus mobiliários ou plantas existentes, através do desenho ou outras formas de registro.',
              descricao2: '383B Encontrar objetos na sala e Instituição, a partir de orientação de mapas produzidos por ela ou pelo adulto, para esse fim.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI03ET05) Classificar objetos e figuras de acordo com suas semelhanças e diferenças.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '384A Observar e comentar sobre obras de arte a partir da exploração de suas formas simétricas',
              descricao2: '384B Utilizar materiais com formas semelhantes a figuras geométricas para construir imagens e objetos em espaços bidimensionais e tridimensionais.',
            },
            {
              ordem: 1,
              descricao: '385A Brincar com brinquedos que tenham formas semelhantes a figuras geométricas.',
              descricao2: '385B Identificar características geométricas dos objetos e suas formas (bidimensionais e tridimensionais) em situações de brincadeiras.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI03ET06) Relatar fatos importantes sobre seu nascimento e desenvolvimento, a história dos seus familiares e da sua comunidade.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '386A Relatar suas lembranças e vivências.',
              descricao2: '386B Identificar e comparar as fases da vida, descrevendo situações de mudanças ocorridas com seu próprio corpo de quando era bebê até os dias atuais, através de fotos e objetos e roupas.',
            },
            {
              ordem: 1,
              descricao: '387A Participar em eventos e celebrações como festas de aniversários, festa junina, viagens de férias e visitas a familiares.',
              descricao2: '387B Identificar costumes, tradições e acontecimentos significativos do presente e do passado usando frases que remetem às mudanças.',
            },
            {
              ordem: 2,
              descricao: '388A Marcar as datas significativas, sejam de seu grupo, de seus grupos familiares, como também da comunidade escolar, apoiadas no calendário e utilizando a unidade de tempo — dia, mês e ano.',
              descricao2: '388B Comemorar os aniversários e algumas passagens significativas do tempo, identificando-as apoiadas no calendário e utilizando a unidade de tempo — dia, mês e ano.',
            },
          ],
        },
        {
          ordem: 6,
          descricao: '(EI03ET07) Relacionar números às suas respectivas quantidades e identificar o antes, o depois e o entre em uma sequência.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '389A Participar nas brincadeiras em que possam organizar objetos em conjuntos ou grupos.',
              descricao2: '389B Brincar de faz de conta com materiais que convidem a pensar sobre os números, como brincar de comprar e vender, identificando notas e moedas do sistema monetário vigente.',
            },
            {
              ordem: 1,
              descricao: '390A Envolver-se em situações de contagem em contextos significativos da vida real como localizar número em álbum de figurinhas.',
              descricao2: '390B Pesquisar a localização — em uma régua, fita métrica ou calendário — de um número escrito em uma sequência.',
            },
            {
              ordem: 2,
              descricao: '391A Controlar materiais da sala, quantidade de crianças que vieram, que faltaram, que vão merendar, que vão a um passeio.',
              descricao2: '391B Contar e comparar quantidade de objetos nas coleções.',
            },
            {
              ordem: 3,
              descricao: '392A Participar de jogos que envolvam relacionar número à quantidades.',
              descricao2: '392B Comunicar oralmente e com marcas gráficas suas ideias, suas hipóteses e estratégias utilizadas em contextos de resolução de problemas matemáticos.',
            },
          ],
        },
        {
          ordem: 7,
          descricao: '(EI03ET08) Expressar medidas (peso, altura etc.), construindo gráficos básicos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '393A Usar instrumento de medida (convencional ou não) para comparar distâncias do espaço da sala ou tamanhos dos colegas.',
              descricao2: '393B Medir objetos observando-os, comparando-os, informando seus atributos.',
            },
            {
              ordem: 1,
              descricao: '394A Observar gráficos simples para comparar quantidades.',
              descricao2: '394B Construir gráficos básicos para representar medidas.',
            },
            {
              ordem: 2,
              descricao: '395A Comparar objetos buscando respostas sobre tamanho, peso e quantidades.',
              descricao2: '395B Interessar-se em participar de situações de resolução de problemas envolvendo medidas.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER com crianças e adultos em pequenos grupos, reconhecendo e respeitando as diferentes identidades e pertencimento étnico-racial, de gênero e religião de seus parceiros.\n\nBRINCAR com diferentes parceiros, desenvolvendo sua imaginação e solidariedade.\n\nEXPLORAR diferentes formas de interagir com parceiros diversos em situações variadas, ampliando sua noção de mundo e sua sensibilidade em relação aos outros.\n\nPARTICIPAR ativamente das situações do cotidiano, tanto daquelas ligadas ao cuidado de si e do ambiente como das relativas às atividades propostas pelo(a) professor(a).\n\nEXPRESSAR às outras crianças e/ou adultos suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões e oposições.\n\nCONHECER-SE e construir uma identidade pessoal e cultural, valorizando suas características e as das outras crianças e adultos, aprendendo a identificar e combater atitudes preconceituosas e discriminatórias',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI03EO01) Demonstrar empatia pelos outros, percebendo que as pessoas têm diferentes sentimentos, necessidades e maneiras de pensar e agir.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '55A Demonstrar respeito pelos seus colegas ouvindo-os e respeitando suas preferências.',
              descricao2: '55B Identificar e comunicar ao professor sentimentos de outras crianças',
            },
            {
              ordem: 1,
              descricao: '56A Acolher o colega com abraços, beijos, sorrisos ou outras expressões.',
              descricao2: '56B Manifestar-se frente a situações que avalia como injustas, como por exemplo, devolver um brinquedo que foi retirado das mãos de um colega sem o consentimento deste',
            },
            {
              ordem: 2,
              descricao: '57A Compartilhar brinquedos com outras crianças.',
              descricao2: '57B Convidar um colega que está sem brincar, para se juntar à brincadeira com ela',
            },
            {
              ordem: 3,
              descricao: '58A Compartilhar emoções e sentimentos com adultos ou crianças, sabendo identificar sentimentos de tristeza, alegria, surpresa, etc',
              descricao2: '58B Compartilhar emoções e sentimentos com adultos ou crianças, sabendo identificar sentimentos de tristeza, alegria, surpresa, etc',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI03EO02) Agir de maneira independente, com confiança em suas capacidades, reconhecendo suas conquistas e limitações.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '59A Demonstrar iniciativa na escolha de brincadeiras e atividades, na seleção de materiais e na escolha de parceiros',
              descricao2: '59B Oferecer-se para ajudar ou realizar determinadas ações no cotidiano de forma autônoma',
            },
            {
              ordem: 1,
              descricao: '60A Realizar, autonomamente, ações simples, como: colocar, recolher, guardar, tirar, desatar, comer sozinho ou colocar a mesa, etc',
              descricao2: '60B Realizar autonomamente ações cotidianas como: vestirse, banhar-se, alimentar-se, organizar materiais, etc',
            },
            {
              ordem: 2,
              descricao: '61A Participar de atividades desafiadoras como: movimentar-se em circuitos com obstáculos diversos, escalar, confeccionar, montar ou desmon',
              descricao2: '61B Aceitar e perseverar frente à desafios, como por exemplo, montar quebra-cabeças, jogos de encaixe, bambolear, pular corda, criar brinquedos, etc',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI03EO03) Ampliar as relações interpessoais, desenvolvendo atitudes de participação e cooperação.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '62A Participar de práticas coletivas como: brincadeiras de faz de conta, culinária, cuidar de um animalzinho, realizar pintura coletiva, organizar a mesa para o lanche, etc.',
              descricao2: '62B Participar de brincadeiras coletivas, como: o faz de conta, representando diferentes papéis e convidando outros colegas para entrarem na brincadeira.',
            },
            {
              ordem: 1,
              descricao: '63A Brincar compartilhando propósitos comuns com os colegas.',
              descricao2: '63B Considerar os interesses e desejos de seus colegas em situações de brincadeira.',
            },
            {
              ordem: 2,
              descricao: '64A Relacionar-se com crianças de diferentes idades em situações de interação e brincadeira.',
              descricao2: '64B Auxiliar os colegas na realização de ações que não estão conseguindo fazer sozinhos.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI03EO04) Comunicar suas ideias e sentimentos a pessoas e grupos diversos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '65A Comunicar-se com independência, expondo suas ideias, opiniões, relatando algo.',
              descricao2: '65B Identificar emoções e tentar regulá-las em determinadas situações.',
            },
            {
              ordem: 1,
              descricao: '66A Expressar-se por meio de gestos, movimentos, palavras, expressões corporais, etc.',
              descricao2: '66B Nomear diferentes emoções e sentimentos de si mesma e nos outros.',
            },
            {
              ordem: 2,
              descricao: '67A Expressar-se por meio de múltiplas linguagens, como: a música, a dança, a arte, a linguagem verbal, o desenho, etc.',
              descricao2: '67B Expor ideias, acontecimentos, preferências, opiniões para pequenos e grandes grupos em rodas de conversa.',
            },
          ],
        },
        {
          ordem: 4,
          descricao: '(EI03EO05) Demonstrar valorização das características de seu corpo e respeitar as características dos outros (crianças e adultos) com os quais convive.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '68A Identificar, nomear e localizar partes do seu corpo.',
              descricao2: '68B Perceber as mudanças ocorridas em suas características desde o seu nascimento.',
            },
            {
              ordem: 1,
              descricao: '69A Identificar características de seu corpo, como, por exemplo, perceber seus atributos corporais, expressando-os de diferentes maneiras, construindo sua imagem corporal.',
              descricao2: '69B Perceber e explorar os limites e possibilidades de seu corpo em situações de brincadeira e movimentos.',
            },
            {
              ordem: 2,
              descricao: '70A Valorizar a diversidade a partir do reconhecimento das diferentes características que cada um possui, como por exemplo, ser alto/baixo; ter cabelo curto/comprido/liso/de cachinhos/loiro/castanho/preto/ruivo; ter os olhos de cores diferentes; usar ou não óculos, etc.',
              descricao2: '70B Apreciar positivamente suas características e a do outro, respeitando suas diferenças e particularidades a partir de dinâmicas em que possa verbalizar o que gosta em si e no outro.',
            },
          ],
        },
        {
          ordem: 5,
          descricao: '(EI03EO06) Manifestar interesse e respeito por diferentes culturas e modos de vida.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '71A Ouvir relatos de familiares ou pessoas de seu convívio sobre os diferentes modos de vida de cada época.',
              descricao2: '71B Conhecer membros de sua comunidade próxima, conversar com elas sobre o que fazem, por exemplo, o padeiro, o fazendeiro, o pescador, os vizinhos, etc.',
            },
            {
              ordem: 1,
              descricao: '72A Conhecer e participar de manifestações culturais de sua localidade.',
              descricao2: '72B Realizar pesquisas e entrevistas com familiares sobre suas tradições, de modo a reconhecer elementos da sua identidade cultural, estabelecendo relações entre as características e diferentes modos de viver do seu grupo e o de outros.',
            },
            {
              ordem: 2,
              descricao: '73A Interessar-se por conhecer diferentes culturas e grupos sociais, respeitando seus diferentes modos de viver, ouvindo suas histórias, podendo recontá-las.',
              descricao2: '73B Conhecer outras culturas, por exemplo, a de seus colegas ou conhecidos, a partir de conversas, brincadeiras, passeios, convidados, etc.',
            },
            {
              ordem: 3,
              descricao: '74A Conhecer objetos antigos e/ou pertencentes a outras culturas.',
              descricao2: '74B Conhecer, comparar e experimentar vivências, costumes e brincadeiras de outras épocas e de diferentes culturas.',
            },
            {
              ordem: 4,
              descricao: '75A Ouvir e recontar histórias dos povos indígenas, africanos, asiáticos, europeus, de diferentes regiões do Brasil e de outros países da América.',
              descricao2: '75B Conhecer outros grupos sociais pessoalmente ou por outros meios de comunicação.',
            },
          ],
        },
        {
          ordem: 6,
          descricao: '(EI03EO07) Usar estratégias pautadas no respeito mútuo para lidar com conflitos nas interações com crianças e adultos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '76A Vivenciar situações de diálogo onde possa expressar e ouvir emoções e descontentamentos.',
              descricao2: '76B Dialogar para expressar emoções e descontentamentos, sabendo desculpar-se quando agir com desrespeito.',
            },
            {
              ordem: 1,
              descricao: '77A Propor e cumprir combinados.',
              descricao2: '77B Fazer combinados onde possa opinar nas decisões coletivas.',
            },
            {
              ordem: 2,
              descricao: '78A Utilizar estratégias pacíficas para resolver situações de conflito.',
              descricao2: '78B Ter atitude de iniciativa e respeito na busca por resolver os problemas relacionais.',
            },
          ],
        },
      ],
    },
    {
      campoExperiencia: {
        descricao: 'CONVIVER e fruir com os colegas e professores manifestações artísticas e culturais da sua comunidade e de outras culturas — artes plásticas, música, dança, teatro, cinema, folguedos e festas populares.\n\nBRINCAR com diferentes sons, ritmos, formas, cores, texturas, objetos e materiais, construindo cenários e indumentárias para brincadeiras de faz de conta, encenações ou para festas tradicionais.\n\nEXPLORAR variadas possibilidades de usos e combinações de materiais, substâncias, objetos e recursos tecnológicos para criar desenhos, modelagens, músicas, danças, encenações teatrais e musicais.\n\nPARTICIPAR de decisões e ações relativas à organização do ambiente (tanto o cotidiano quanto o preparado para determinados eventos), à definição de temas e à escolha de materiais a serem usados em atividades lúdicas e artísticas.\n\nEXPRESSAR suas emoções, sentimentos, necessidades e ideias cantando, dançando, esculpindo, desenhando, encenando.\n\nCONHECER-SE no contato criativo com manifestações artísticas e culturais locais e de outras comunidades.',
        materia: { id: idMateria },
      },
      objetivosAprendizagem: [
        {
          ordem: 0,
          descricao: '(EI03TS01) Utilizar sons produzidos por materiais, objetos e instrumentos musicais durante brincadeiras de faz de conta, encenações, criações musicais, festas.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '173A Produzir e apreciar sons explorando diferentes objetos sonoros e materiais encontrados no seu cotidiano.',
              descricao2: '173B Produzir criações musicais com diferentes objetos sonoros e materiais encontrados no seu cotidiano.',
            },
            {
              ordem: 1,
              descricao: '174A Cantar canções conhecidas acompanhando o ritmo com gestos ou com instrumentos musicais.',
              descricao2: '174B Experimentar produzir sons com fins de trilha sonora, participando da composição e escolha desses sons para narrativas, eventos culturais e festivos.',
            },
            {
              ordem: 2,
              descricao: '175A Confeccionar e experimentar instrumentos musicais de percussão, de sopro, de corda com materiais alternativos.',
              descricao2: '175B Confeccionar e experimentar instrumentos musicais de percussão, de sopro e de corda com materiais alternativos.',
            },
            {
              ordem: 3,
              descricao: '176A Utilizar brinquedos sonoros ou instrumentos musicais para participar de brincadeiras, encenações e vivências de dança.',
              descricao2: '176B Utilizar brinquedos sonoros ou instrumentos musicais para participar de encenações ou criações musicais.',
            },
            {
              ordem: 4,
              descricao: '177A Contar histórias usando modulações de voz e objetos sonoros.',
              descricao2: '177B Contar histórias usando modulações de voz, objetos sonoros e instrumentos musicais.v',
            },
            {
              ordem: 5,
              descricao: '178A Reconhecer canções características que marcam eventos específicos da cultura local.',
              descricao2: '178B Reconhecer e apreciar canções características da cultura local.',
            },
            {
              ordem: 6,
              descricao: '179A Desenvolver atitudes de apreciar e valorizar a escuta de obras musicais de diversos gêneros, estilos, épocas e culturas da produção musical brasileira e de outros povos.',
              descricao2: '179B Apreciar e valorizar a escuta de obras musicais de diversos gêneros, estilos, épocas e culturas da produção musical brasileira e de outros povos.',
            },
          ],
        },
        {
          ordem: 1,
          descricao: '(EI03TS02) Expressar-se livremente por meio de desenho, pintura, colagem, dobradura e escultura, criando produções bidimensionais e tridimensionais.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '180A Expressar-se, comunicar-se e divertir-se, ao mesmo tempo em que explora, investiga e faz descobertas e conexões por meio de desenhos, rabiscos, pinturas, construções, esculturas, colagens, dobraduras etc.',
              descricao2: '180B Expressar suas ideias, sentimentos e experiências e atribuir sentidos ao mundo experimentando pintar, desenhar, modelar, construir, fotografar à sua maneira.',
            },
            {
              ordem: 1,
              descricao: '181A Fazer desenhos de observação focando nos detalhes.',
              descricao2: '181B Utilizar a investigação que realiza sobre o espaço, as imagens, as coisas ao seu redor para significar e incrementar sua produção artística.',
            },
            {
              ordem: 2,
              descricao: '182A Construir brinquedos, potes, cestos ou adornos inspirados no artesanato indígena ou de outras tradições culturais.',
              descricao2: '182B Construir brinquedos, potes, cestos ou adornos inspirados no artesanato indígena ou de outras tradições culturais.',
            },
            {
              ordem: 3,
              descricao: '183A Construir casas ou castelos com cartas/cartões, madeira, panos e outros materiais.',
              descricao2: '183B Construir casas ou castelos com cartas/cartões, madeira, panos e outros materiais.',
            },
            {
              ordem: 4,
              descricao: '184A Fazer dobraduras com representações de objetos e animais.',
              descricao2: '184B Fazer dobraduras com representações de objetos e animais.',
            },
            {
              ordem: 5,
              descricao: '185A Produzir brinquedos com materiais alternativos, como bonecas de pano ou de espigas de milho, carrinhos de lata, petecas, entre outros características de seu grupo cultural.',
              descricao2: '185B Produzir brinquedos com materiais alternativos, como bonecas de pano ou de espigas de milho, carrinhos de lata, petecas, entre outros características de seu grupo cultural.',
            },
            {
              ordem: 6,
              descricao: '186A Construir estruturas com gravetos, folhas secas, blocos, copos plásticos, embalagens de papelão.',
              descricao2: '186B Construir estruturas com gravetos, folhas secas, blocos, copos plásticos, embalagens de papelão.',
            },
            {
              ordem: 7,
              descricao: '187A Experimentar e apreciar efeitos de luz e sombra sobre objetos ou espaços, utilizando velas ou lanternas.',
              descricao2: '187B Experimentar e apreciar efeitos de luz e sombra sobre objetos ou espaços, utilizando velas ou lanternas.',
            },
            {
              ordem: 8,
              descricao: '188A Pintar usando diferentes suportes (papéis, panos, telas, pedaços de metal ou acrílico) e materiais (aquarela, tinta guache, tinta feita com materiais da natureza, lápis de cor, canetas hidrográficas, esmalte de unhas).',
              descricao2: '188B Pintar usando diferentes suportes (papéis, panos, telas, pedaços de metal ou acrílico) e materiais (aquarela, tinta guache, tinta feita com materiais da natureza, lápis de cor, canetas hidrográficas, esmalte de unhas).',
            },
            {
              ordem: 9,
              descricao: '189A Explorar a diversidade de padrões de uso de cores em diferentes culturas e contextos de produção e usar esse conhecimento para fazer suas criações artísticas.',
              descricao2: '189B Explorar a diversidade de padrões de uso de cores em diferentes culturas e contextos de produção e usar esse conhecimento para fazer suas criações artísticas.',
            },
          ],
        },
        {
          ordem: 2,
          descricao: '(EI03TS03) Reconhecer as qualidades do som (intensidade, duração, altura e timbre), utilizando-as em suas produções sonoras e ao ouvir músicas e sons.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '190A Brincar com a música explorando objetos ou instrumentos musicais para acompanhar seu ritmo.',
              descricao2: '190B Explorar, em situações de brincadeira com música, variações de velocidade e intensidade na produção de sons.',
            },
            {
              ordem: 1,
              descricao: '191A Reconhecer, em situações de escuta de música, algumas características dos sons.',
              descricao2: '191B Experimentar, no contato com objetos e instrumentos musicais, os sons agudos e graves (altura), tocar forte ou fraco (intensidade) e produzir sons curtos ou longos (duração).',
            },
            {
              ordem: 2,
              descricao: '192A Explorar ambientes à sua volta procurando objetos e coisas que tenham sons diferentes dos que já conhecem.',
              descricao2: '192B Imitar, inventar e reproduzir criações musicais.',
            },
            {
              ordem: 3,
              descricao: '193A Dançar conforme a música e as diferentes manifestações sonoras encontrando movimentos diferentes para expressar cada uma delas.',
              descricao2: '193B Dançar conforme a música e as diferentes manifestações sonoras encontrando movimentos diferentes para expressar cada uma delas.',
            },
            {
              ordem: 4,
              descricao: '194A Descobrir a reação dos diferentes tipos de som no seu corpo.',
              descricao2: '194B Criar formas de se expressar por meio dos sons que seu corpo emite e que sua voz pode criar.',
            },
            {
              ordem: 5,
              descricao: '195A Apreciar canções, brincadeiras e instrumentos musicais típicos de sua cultura de outras tradições culturais.',
              descricao2: '195B Contextualizar e apreciar canções, brincadeiras e instrumentos musicais típicos de sua cultura de outras tradições culturais.',
            },
          ],
        },
        {
          ordem: 3,
          descricao: '(EI03TS04RN) Interagir com linguagens artísticas, conhecendo, apreciando e produzindo obras de arte em diferentes contextos.',
          materia: { id: idMateria },
          objetivosEspecificos: [
            {
              ordem: 0,
              descricao: '196A Apreciar e produzir obras de arte refletindo sobre os elementos que permitem sua concretização (forma, espaço, cor, luz, textura, volume, linhas, pontos, etc.) e sobre os suportes, materiais, instrumentos, técnicas e procedimentos utilizados em sua produção.',
              descricao2: '196B Apreciar e produzir obras de arte refletindo sobre os elementos que permitem sua concretização (forma, espaço, cor, luz, textura, volume, linhas, pontos, etc.) e sobre os suportes, materiais, instrumentos, técnicas e procedimentos utilizados em sua produção.',
            },
            {
              ordem: 1,
              descricao: '197A Criar, recriar e fazer releituras de obras de arte em diferentes linguagens (gráficas, plásticas, multimídia, etc.)',
              descricao2: '197B Criar, recriar e fazer releituras de obras de arte em diferentes linguagens (gráficas, plásticas, multimídia, etc.)',
            },
            {
              ordem: 2,
              descricao: '198A Construir uma atitude de autoconfiança por sua produção artística e de respeito pela produção dos colegas.',
              descricao2: '198B Construir uma atitude de autoconfiança por sua produção artística e de respeito pela produção dos colegas.',
            },
            {
              ordem: 3,
              descricao: '199A Construir repertórios visuais a partir da exploração das diversas formas, texturas e cores do mundo, do acesso a obras artísticas produzidas ao longo da história da humanidade e àquelas produzidas na sua comunidade e na sua cidade.',
              descricao2: '199B Construir repertórios visuais a partir da exploração das diversas formas, texturas e cores do mundo, do acesso a obras artísticas produzidas ao longo da história da humanidade e àquelas produzidas na sua comunidade e na sua cidade.',
            },
            {
              ordem: 4,
              descricao: '200A Apreciar produções artísticas (pintura, teatro, cinema, etc.) da cultura local, regional, nacional e internacional, expressando suas preferências por meio de sensações e sentidos.',
              descricao2: '200B Apreciar produções artísticas (pintura, teatro, cinema, etc.) da cultura local, regional, nacional e internacional, expressando suas preferências por meio de sensações e sentidos.',
            },
            {
              ordem: 5,
              descricao: '201A Contextualizar informações sobre aspectos da história da arte, a biografia e a produção artística de artistas locais, regionais, nacionais e internacionais.',
              descricao2: '201B Contextualizar informações sobre aspectos da história da arte, a biografia e a produção artística de artistas locais, regionais, nacionais e internacionais.',
            },
          ],
        },
      ],
    },
    
    
  ];
  return obj[curso];
}

export default getCamposAprendizagemPre1Pre2;
