import BaseService from './BaseService';

class MateriasService extends BaseService {
  carregarMateria(idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMateriasPorEstruturaCurricular(idEstruturaCurricular) {
    const requestInfo = {
      method: 'get',
      url: `/materia/estruturacurricular/${idEstruturaCurricular}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMaterias(pagina, linhasPorPagina, like) {
    let url = `/materia/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarMateria(materia) {
    const requestInfo = {
      method: 'post',
      url: '/materia',
      data: JSON.stringify(materia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarMateria(materia) {
    const requestInfo = {
      method: 'put',
      url: '/materia',
      data: JSON.stringify(materia),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerMateria(idMateria) {
    const requestInfo = {
      method: 'delete',
      url: `/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMateriasDoProfessor(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/materia/${idTurma}/professor`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default MateriasService;
