import BaseService from './BaseService';

class ConvitesService extends BaseService {
  carregarConvitesGestores(pagina, linhasPorPagina, like) {
    let url = `/convite/papel/gestor/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarConvitesGestoresPorEnte(idEnte, pagina, linhasPorPagina, like) {
    let url = `/convite/papel/gestor/ente/${idEnte}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarConvitesDiretoresProfessoresCoordenadores(pagina, linhasPorPagina, like) {
    let url = `/convite/papel/diretor/professor/coordenador/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarConviteGestor(convite) {
    const requestInfo = {
      method: 'post',
      url: '/convite/papel/gestor',
      data: JSON.stringify(convite),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarConviteDiretorProfessorCoordenador(convite) {
    const requestInfo = {
      method: 'post',
      url: '/convite/papel/diretor/professor/coordenador',
      data: JSON.stringify(convite),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerConvite(idConvite) {
    const requestInfo = {
      method: 'delete',
      url: `/convite/${idConvite}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default ConvitesService;
