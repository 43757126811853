import React from 'react';

import 'react-perfect-scrollbar/dist/css/styles.css';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Table } from 'reactstrap';

class AtaFinalNotas extends React.Component {
  constructor(props) {
    super(props);

    const avaliacoes = [];
    this.props.avaliacoes.forEach((avaliacoesDoAluno) => {
      avaliacoes.push(avaliacoesDoAluno.filter((avaliacao) => avaliacao
        .descricaoAvaliacao.id === this.props.idDescricaoAvaliacao));
    });

    this.state = {
      avaliacoes,
    };
  }

  render() {
    return (
      <PerfectScrollbar className="sisa-perfect-scrollbar">
      <Table>
        <thead className="text-primary">
          <tr>
            <th style={{ width: '14%' }}>Aluno</th>
            {
              this.props.materias
                .map((materia, idx) => <th style={{ width: '5%' }} className="text-center" key={idx}>{materia.nome}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            this.state.avaliacoes.map((avaliacoesAluno, idx) => <tr key={idx}>
              <td className="text-left">{this.props.matriculas[idx].usuario.nome}</td>
              {
                avaliacoesAluno.map((avaliacao, idx2) => <td className="text-center" key={idx2}>
                  {avaliacao.resultado}
                </td>)
              }
            </tr>)
          }
        </tbody>
      </Table>
      </PerfectScrollbar>
    );
  }
}

export default AtaFinalNotas;
