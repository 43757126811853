import React from 'react';
import {
  Modal,
  Button,
} from 'reactstrap';

class TextoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      titulo: props.titulo,
      conteudo: props.conteudo,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (this.state.titulo !== nextProps.titulo) {
      this.setState({ titulo: nextProps.titulo });
    }
    if (this.state.conteudo !== nextProps.conteudo) {
      this.setState({ conteudo: nextProps.conteudo });
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}
        size="xl">
        <div align="center" style={{ paddingTop: '40px', paddingBottom: '40px' }} className="modal-body">
          <h4>{this.state.titulo}</h4>
          <br /><br />
          <div className="preserve-format">{this.state.conteudo}</div>
          <br /><br />
          <Button type="button" color="primary" onClick={() => this.props.closeCallback()}>
            Fechar
          </Button>
        </div>
      </Modal>
    );
  }
}

export default TextoModal;
