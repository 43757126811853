import BaseService from './BaseService';

class VinculoService extends BaseService {
  atualizarVinculo(vinculo) {
    const requestInfo = {
      method: 'post',
      url: '/vinculo',
      data: JSON.stringify(vinculo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarMatricula(idUsuario, papel, matricula) {
    const requestInfo = {
      method: 'put',
      url: `/vinculo/matricula/usuario/${idUsuario}/papel/${papel}`,
      data: matricula,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarVinculosPorUsuario(idUsuario) {
    let url = `/vinculo/usuario/${idUsuario}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default VinculoService;
