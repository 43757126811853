import moment from 'moment';

import TipoFeriado from 'assets/csv/tipos-feriado.json';

import BaseService from './BaseService';

class FeriadosService extends BaseService {
  constructor() {
    super();
    this.feriados = {
      NACIONAIS: [
        { data: (ano) => `01/01/${ano}`, nome: 'Confraternização Universal', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        {
          data: (ano) => {
            const data = this.feriadosMoveis(ano).segundaCarnaval;
            return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
          },
          nome: 'Segunda-Feira de Carnaval',
          tipo: TipoFeriado.PONTO_FACULTATIVO.value,
        },
        {
          data: (ano) => {
            const data = this.feriadosMoveis(ano).tercaCarnaval;
            return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
          },
          nome: 'Terça-Feira de Carnaval',
          tipo: TipoFeriado.FERIADO_NACIONAL.value,
        },
        {
          data: (ano) => {
            const data = this.feriadosMoveis(ano).quartaCinzas;
            return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
          },
          nome: 'Quarta-Feira de Cinzas',
          tipo: TipoFeriado.PONTO_FACULTATIVO.value,
        },
        {
          data: (ano) => {
            const data = this.feriadosMoveis(ano).sextaSanta;
            return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
          },
          nome: 'Sexta-Feira Santa',
          tipo: TipoFeriado.FERIADO_NACIONAL.value,
        },
        { data: (ano) => `21/04/${ano}`, nome: 'Tiradentes', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        { data: (ano) => `01/05/${ano}`, nome: 'Dia do Trabalhador', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        {
          data: (ano) => {
            const data = this.feriadosMoveis(ano).corpusChristi;
            return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
          },
          nome: 'Corpus Christi',
          tipo: TipoFeriado.PONTO_FACULTATIVO.value,
        },
        { data: (ano) => `07/09/${ano}`, nome: 'Independência do Brasil', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        { data: (ano) => `12/10/${ano}`, nome: 'Nossa Senhora Aparecida', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        { data: (ano) => `15/10/${ano}`, nome: 'Dia do Professor', tipo: TipoFeriado.PONTO_FACULTATIVO.value },
        { data: (ano) => `28/10/${ano}`, nome: 'Dia do Servidor Público', tipo: TipoFeriado.PONTO_FACULTATIVO.value },
        { data: (ano) => `02/11/${ano}`, nome: 'Finados', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        { data: (ano) => `15/11/${ano}`, nome: 'Proclamação da República', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        { data: (ano) => `20/11/${ano}`, nome: 'Dia Nacional da Consciência Negra', tipo: TipoFeriado.FERIADO_NACIONAL.value },
        { data: (ano) => `25/12/${ano}`, nome: 'Natal', tipo: TipoFeriado.FERIADO_NACIONAL.value },
      ],
      ESTADUAIS: {
        AC: [
          { data: (ano) => `20/01/${ano}`, nome: 'Dia do católico', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `25/01/${ano}`, nome: 'Dia do evangélico', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `08/03/${ano}`, nome: 'Dia internacional da mulher', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `15/06/${ano}`, nome: 'Aniversário do Acre', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `06/08/${ano}`, nome: 'Revolução Acreana', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `05/09/${ano}`, nome: 'Dia da Amazônia', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `17/11/${ano}`, nome: 'Tratado de Petrópolis', tipo: TipoFeriado.PONTO_FACULTATIVO.value },
        ],
        AL: [
          { data: (ano) => `24/06/${ano}`, nome: 'Dia de São João', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `29/06/${ano}`, nome: 'Dia de São Pedro', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `16/09/${ano}`, nome: 'Emancipação de Alagoas', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `20/11/${ano}`, nome: 'Dia da Consciência Negra', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `30/11/${ano}`, nome: 'Dia do evangélico', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        AM: [
          { data: (ano) => `05/09/${ano}`, nome: 'Aniversário do Amazonas', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `20/11/${ano}`, nome: 'Dia da Consciência Negra', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `08/12/${ano}`, nome: 'Dia de Nossa Senhora da Conceição', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        AP: [
          { data: (ano) => `19/03/${ano}`, nome: 'Dia de São José', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `15/05/${ano}`, nome: 'Dia de Cabralzinho', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `25/07/${ano}`, nome: 'Dia de São Tiago', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `13/09/${ano}`, nome: 'Criação do Território Federal do Amapá', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `20/11/${ano}`, nome: 'Dia da Consciência Negra', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `30/11/${ano}`, nome: 'Dia do evangélico', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        BA: [
          { data: (ano) => `02/07/${ano}`, nome: 'Independência da Bahia', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        CE: [
          { data: (ano) => `19/03/${ano}`, nome: 'Dia de São José', tipo: TipoFeriado.PONTO_FACULTATIVO.value },
          { data: (ano) => `25/03/${ano}`, nome: 'Data Magna do Ceará', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        DF: [
          { data: (ano) => `30/11/${ano}`, nome: 'Dia do evangélico', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        ES: [],
        GO: [
          { data: (ano) => `24/05/${ano}`, nome: 'Dia de Nossa Senhora Auxiliadora', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `24/10/${ano}`, nome: 'Aniversário de Goiânia', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        MA: [
          { data: (ano) => `28/07/${ano}`, nome: 'Adesão do Maranhão à independência do Brasil', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `20/11/${ano}`, nome: 'Dia da Consciência Negra', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        MG: [],
        MS: [
          { data: (ano) => `11/10/${ano}`, nome: 'Aniversário do Mato Grosso do Sul', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        MT: [
          { data: (ano) => `20/11/${ano}`, nome: 'Dia da consciência negra', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        PA: [
          { data: (ano) => `15/08/${ano}`, nome: 'Adesão do Pará à independência do Brasil', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `13/10/${ano}`, nome: 'Dia de Nossa Senhora de Nazaré', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `08/12/${ano}`, nome: 'Dia de Nossa Senhora da Conceição', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        PB: [
          { data: (ano) => `05/08/${ano}`, nome: 'Nossa Senhora das Neves', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        PE: [
          { data: (ano) => `06/03/${ano}`, nome: 'Revolução Pernambucana de 1817', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `24/06/${ano}`, nome: 'Dia de São João', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        PI: [
          { data: (ano) => `19/10/${ano}`, nome: 'Dia do Piauí', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        PR: [],
        RJ: [
          {
            data: (ano) => {
              const data = this.feriadosMoveis(ano).tercaCarnaval;
              return `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
            },
            nome: 'Terça-Feira de Carnaval',
            tipo: TipoFeriado.FERIADO_ESTADUAL.value,
          },
          { data: (ano) => `23/04/${ano}`, nome: 'Dia de São Jorge', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `20/11/${ano}`, nome: 'Dia da Consciência Negra', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        RN: [
          { data: (ano) => `03/10/${ano}`, nome: 'Mártires de Cunhaú e Uruaçu', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        RO: [
          { data: (ano) => `04/01/${ano}`, nome: 'Aniversário de Rondônia', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `18/06/${ano}`, nome: 'Dia do evangélico', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        RR: [
          { data: (ano) => `05/10/${ano}`, nome: 'Aniversário de Roraima', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `20/11/${ano}`, nome: 'Dia da Consciência Negra', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        RS: [
          { data: (ano) => `20/09/${ano}`, nome: 'Proclamação da República Rio-Grandense', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        SC: [
          { data: (ano) => `11/08/${ano}`, nome: 'Dia de Santa Catarina', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        SE: [
          { data: (ano) => `08/07/${ano}`, nome: 'Aniversário de Sergipe', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        SP: [
          { data: (ano) => `09/07/${ano}`, nome: 'Revolução Constitucionalista de 1932', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
        TO: [
          { data: (ano) => `08/09/${ano}`, nome: 'Dia de Nossa Senhora da Natividade', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
          { data: (ano) => `05/10/${ano}`, nome: 'Aniversário de Tocantins', tipo: TipoFeriado.FERIADO_ESTADUAL.value },
        ],
      },
    };
  }

  subtrairDias = (data, dias) => new Date(data.getTime() - (dias * 24 * 60 * 60 * 1000))

  feriadosMoveis(ano) {
    let dia; let
      mes;

    const X = 24;
    const Y = 5;
    const a = ano % 19;
    const b = ano % 4;
    const c = ano % 7;
    const d = (19 * a + X) % 30;
    const e = (2 * b + 4 * c + 6 * d + Y) % 7;
    const soma = d + e;

    if (soma > 9) {
      dia = (d + e - 9);
      mes = 3;
    } else {
      dia = (d + e + 22);
      mes = 2;
    }

    const feriadosMoveis = {
      pascoa: new Date(ano, mes, dia),
      sextaSanta: this.subtrairDias(new Date(ano, mes, dia), 2),
      segundaCarnaval: this.subtrairDias(new Date(ano, mes, dia), 48),
      tercaCarnaval: this.subtrairDias(new Date(ano, mes, dia), 47),
      quartaCinzas: this.subtrairDias(new Date(ano, mes, dia), 46),
      corpusChristi: this.subtrairDias(new Date(ano, mes, dia), -60),
    };

    return feriadosMoveis;
  }

  carregarFeriados(ano, estado) {
    let feriadosNacionais = this.feriados.NACIONAIS.map(
      (feriado) => ({
        data: feriado.data(ano),
        nome: feriado.nome,
        tipo: feriado.tipo,
      }),
    );

    if (estado === 'RJ') {
      feriadosNacionais = feriadosNacionais.filter(
        (feriado) => feriado.nome !== 'Terça-Feira de Carnaval',
      );
    }

    const feriadosEstaduais = this.feriados.ESTADUAIS[estado].map(
      (feriado) => ({
        data: feriado.data(ano),
        nome: feriado.nome,
        tipo: feriado.tipo,
      }),
    );

    return feriadosNacionais.concat(feriadosEstaduais).sort(
      (d1, d2) => {
        const m1 = moment(d1.data, 'DD/MM/YYYY');
        const m2 = moment(d2.data, 'DD/MM/YYYY');
        if (m1.isSame(m2)) {
          return 0;
        } if (m1.isBefore(m2)) {
          return -1;
        }
        return 1;
      },
    );
  }

  carregarFeriadosPorAnoLetivo(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/feriados/anoletivo/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default FeriadosService;
