import React from 'react';

import { Route, Switch } from 'react-router-dom';

import PageNotFound from 'views/raiz/PageNotFound';

class RootLayout extends React.Component {
  getRoutes = (routesParam) => routesParam.map((prop, key) => {
    if (prop.layout === this.props.layout) {
      return (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    }
    return null;
  });

  render() {
    return (
      <Switch>
        {this.getRoutes(this.props.routes)}
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default RootLayout;
