import BaseService from './BaseService';

class MChatsService extends BaseService {

  carregarMChatsAdmin(idEnte) {
    const requestInfo = {
      method: 'get',
      url: `/mchat/ente/${idEnte}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarMChats(idEnte, mchats) {
    const requestInfo = {
      method: 'put',
      url: `/mchat/ente/${idEnte}`,
      data: JSON.stringify(mchats),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMChats() {
    const requestInfo = {
      method: 'get',
      url: '/mchat',
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarSituacaoMChatUsuarioEnte(idMChat, idUsuario) {
    const requestInfo = {
      method: 'get',
      url: `/mchat/${idMChat}/usuario/${idUsuario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarSituacoesMChats(idUsuario, situacoesMChat) {
    const requestInfo = {
      method: 'post',
      url: `/mchat/usuario/${idUsuario}/situacoes`,
      data: JSON.stringify(situacoesMChat),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default MChatsService;
