import React from 'react';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import CredenciaisService from 'services/CredenciaisService';
import LoginService from 'services/LoginService';

class MudarSenha extends React.Component {
  constructor(props) {
    super(props);

    this.senhaAtualRef = React.createRef();
    this.novaSenhaRef = React.createRef();
    this.confirmacaoNovaSenhaRef = React.createRef();

    this.credenciaisService = new CredenciaisService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      senhaAtualState: '',
      novaSenhaState: '',

      novaSenhaErro: '',
      confirmacaoNovaSenhaErro: '',
    };
  }

  componentWillUnmount() {
    this.credenciaisService.abortPedingRequests();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
  }

  validarCampos() {
    let ret = true;
    if (this.senhaAtualRef.current.value === '') {
      this.setState({ senhaAtualState: 'has-danger' });
      ret = false;
    }

    if (this.novaSenhaRef.current.value === '' || this.confirmacaoNovaSenhaRef.current.value === '') {
      if (this.novaSenhaRef.current.value === '') {
        this.setState({
          novaSenhaState: 'has-danger',
          novaSenhaErro: 'Informe a nova senha.',
        });
        ret = false;
      }
      if (this.confirmacaoNovaSenhaRef.current.value === '') {
        this.setState({
          confirmacaoNovaSenhaState: 'has-danger',
          confirmacaoNovaSenhaErro: 'Informe a confirmação da nova senha.',
        });
        ret = false;
      }
    } else if (this.novaSenhaRef.current.value !== this.confirmacaoNovaSenhaRef.current.value) {
      this.setState({
        novaSenhaState: 'has-danger',
        confirmacaoNovaSenhaState: 'has-danger',
        novaSenhaErro: 'A nova senha difere da cofirmação',
        confirmacaoNovaSenhaErro: 'A nova senha difere da cofirmação',
      });
      ret = false;
    } else if (this.novaSenhaRef.current.value.length < 6) {
      this.setState({
        novaSenhaState: 'has-danger',
        confirmacaoNovaSenhaState: 'has-danger',
        novaSenhaErro: 'A nova senha deve conter no mínimo seis caracteres',
        confirmacaoNovaSenhaErro: 'A nova senha deve conter no mínimo seis caracteres',
      });
      ret = false;
    } else if (this.novaSenhaRef.current.value.indexOf(' ') !== -1) {
      this.setState({
        novaSenhaState: 'has-danger',
        confirmacaoNovaSenhaState: 'has-danger',
        novaSenhaErro: 'A nova senha não pode conter espaços em branco',
        confirmacaoNovaSenhaErro: 'A nova senha não pode conter espaços em branco',
      });
      ret = false;
    } else {
      this.setState({
        senhaAtualState: 'has-success',
        novaSenhaState: 'has-success',
        confirmacaoNovaSenhaState: 'has-success',
      });
    }

    return ret;
  }

  async mudarSenha(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({ buttonDisabled: true, showAlert: false });

    const senhas = {
      senhaAtual: this.senhaAtualRef.current.value,
      novaSenha: this.novaSenhaRef.current.value,
    };

    try {
      await this.credenciaisService.mudarSenha(senhas);

      this.limparFormulario();
      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Senha alterada com sucesso',
      });
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao alterar senha',
      });
    }
  }

  limparFormulario() {
    this.senhaAtualRef.current.value = '';
    this.novaSenhaRef.current.value = '';
    this.confirmacaoNovaSenhaRef.current.value = '';

    this.setState({
      senhaAtualState: '',
      novaSenhaState: '',
      confirmacaoNovaSenhaState: '',

      buttonDisabled: false,
    });
  }

  render() {
    return (
      <div className="content">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem active>Mudar Senha</BreadcrumbItem>
        </Breadcrumb>
        <Form onSubmit={this.mudarSenha.bind(this)}>
          <Card>
            <Col md="4">
              <CardHeader>
                <CardTitle tag="h4">Alterar senha</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup className={`has-label ${this.state.senhaAtualState}`}>
                      <label>Senha atual *</label>
                      <Input
                        innerRef={this.senhaAtualRef}
                        type="password"
                        maxLength="200"
                        onChange={() => this.setState({ senhaAtualState: 'has-success' })}
                      />
                      {this.state.senhaAtualState === 'has-danger' ? (
                        <label className="error">
                          Informe a senha atual.
                                    </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup className={`has-label ${this.state.novaSenhaState}`}>
                      <label>Nova senha *</label>
                      <Input
                        innerRef={this.novaSenhaRef}
                        type="password"
                        maxLength="200"
                        onChange={() => this.setState({ novaSenhaState: 'has-success' })}
                      />
                      {this.state.novaSenhaState === 'has-danger' ? (
                        <label className="error">
                          {this.state.novaSenhaErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup className={`has-label ${this.state.confirmacaoNovaSenhaState}`}>
                      <label>Confirmação nova senha *</label>
                      <Input
                        innerRef={this.confirmacaoNovaSenhaRef}
                        type="password"
                        maxLength="200"
                        onChange={() => this.setState({ confirmacaoNovaSenhaState: 'has-success' })}
                      />
                      {this.state.confirmacaoNovaSenhaState === 'has-danger' ? (
                        <label className="error">
                          {this.state.confirmacaoNovaSenhaErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="category form-category">
                      * Campos obrigatórios
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  disabled={this.state.buttonDisabled}>
                  Alterar senha
                </Button>
              </CardFooter>
            </Col>
          </Card>
        </Form>
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text='Alterando senha...' />
      </div>
    );
  }
}

export default MudarSenha;
