import BaseService from './BaseService';

class ChamadosService extends BaseService {
  carregarChamado(idChamado) {
    const requestInfo = {
      method: 'get',
      url: `/chamado/${idChamado}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarChamados(pagina, linhasPorPagina, like) {
    let url = `/chamado/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarChamadosPorSituacao(situacao, pagina, linhasPorPagina, like) {
    let url = `/chamado/situacao/${situacao}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarChamado(chamado) {
    const requestInfo = {
      method: 'post',
      url: '/chamado',
      data: JSON.stringify(chamado),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarChamado(chamado) {
    const requestInfo = {
      method: 'put',
      url: '/chamado',
      data: JSON.stringify(chamado),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerChamado(idChamado) {
    const requestInfo = {
      method: 'delete',
      url: `/chamado/${idChamado}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMensagens(idChamado) {
    const requestInfo = {
      method: 'get',
      url: `/chamado/${idChamado}/mensagens`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAnexosMensagem(idMensagem) {
    const requestInfo = {
      method: 'get',
      url: `/chamado/mensagem/${idMensagem}/anexos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  enviarResposta(idChamado, resposta) {
    const requestInfo = {
      method: 'post',
      url: `/chamado/${idChamado}/resposta`,
      data: JSON.stringify(resposta),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerMensagem(idMensagem) {
    const requestInfo = {
      method: 'delete',
      url: `/chamado/mensagem/${idMensagem}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarChamadosPorEnteSituacao(idEnte, situacao, pagina, linhasPorPagina, like) {
    let url = `/chamado/ente/${idEnte}/situacao/${situacao}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarSituacao(idChamado, situacao) {
    const requestInfo = {
      method: 'put',
      url: `/chamado/${idChamado}/atualizar/situacao/${situacao}`,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default ChamadosService;
