import BaseService from './BaseService';

class AvaliacaoEspecialService extends BaseService {
  carregarAvaliacaoEspecialPorDiarioEMatricula(idDiario, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacaoespecial/diario/${idDiario}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarAvaliacaoEspecial(idDiario, idMatricula, avaliacaoEspecial) {
    const requestInfo = {
      method: 'put',
      url: `/avaliacaoespecial/diario/${idDiario}/matricula/${idMatricula}/atualizar`,
      data: JSON.stringify(avaliacaoEspecial),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default AvaliacaoEspecialService;
