import DashboardAdmin from 'views/admin/DashboardAdmin';
import Entes from 'views/admin/Entes';
import Ente from 'views/admin/Ente';
// import Convites from 'views/admin/ConvitesGestores';
import Gestores from 'views/admin/Gestores';
import Depoimentos from 'views/comum/Depoimentos';
import ModeracaoDepoimento from 'views/comum/CadastroDepoimento';
import DadosUsuario from 'views/comum/DadosUsuario';
import MudarSenha from 'views/comum/MudarSenha';
import PlanosAdmin from 'views/admin/PlanosAdmin';
import Plano from 'views/admin/Plano';

import Chamado from 'views/gestor/Chamado';

import Papel from 'assets/csv/papeis.json';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-line-chart',
    component: DashboardAdmin,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/planos',
    name: 'Planos',
    icon: 'fas fa-money-check-alt',
    component: PlanosAdmin,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/planos/:idPlano',
    component: Plano,
    layout: '/admin',
    redirect: true,
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/entes',
    name: 'Entes',
    icon: 'fa fa-building',
    component: Entes,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/entes/:idEnte',
    component: Ente,
    layout: '/admin',
    redirect: true,
    role: Papel.ADMINISTRADOR.value,
  },
  /* {
    path: '/convites',
    name: 'Convite',
    icon: 'fa fa-envelope-o',
    component: Convites,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  }, */
  {
    path: '/gestores',
    name: 'Gestores',
    icon: 'fa fa-user',
    component: Gestores,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/gestores/:idUsuario',
    component: DadosUsuario,
    layout: '/admin',
    redirect: true,
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/depoimentos',
    name: 'Depoimentos',
    icon: 'fas fa-comment-alt',
    component: Depoimentos,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/depoimentos/:idDepoimento',
    component: ModeracaoDepoimento,
    layout: '/admin',
    redirect: true,
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/entes/:idEnte/chamados/:idChamado',
    component: Chamado,
    layout: '/admin',
    redirect: true,
    role: Papel.ADMINISTRADOR.value,
  },
];

const routesProfile = [
  {
    path: '/dados',
    name: 'Meus Dados',
    mini: 'M',
    component: DadosUsuario,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  },
  {
    path: '/mudarsenha',
    name: 'Alterar senha',
    mini: 'A',
    component: MudarSenha,
    layout: '/admin',
    role: Papel.ADMINISTRADOR.value,
  },
];

export default routes;
export { routesProfile };
