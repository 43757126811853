function getCompetenciasEspecificasHabiliadesCreche2(curso, idMateria) {
  const obj = [
    {
      //CORPO GESTOS E MOVIMENTO
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI02CG01) Apropriar-se de gestos e movimentos de sua cultura no cuidado de si e nos jogos e brincadeiras.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI02CG02) Deslocar seu corpo no espaço, orientando-se por noções como em frente, atrás, no alto, embaixo, dentro, fora etc., ao se envolver em brincadeiras e atividades de diferentes naturezas.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI02CG03) Explorar formas de deslocamento no espaço (pular, saltar, dançar), combinando movimentos e seguindo orientações.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI02CG04) Demonstrar progressiva independência no cuidado do seu corpo.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI02CG05) Desenvolver progressivamente as habilidades manuais, adquirindo controle para desenhar, pintar, rasgar, folhear, entre outros.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //ESCUTA FALA PENSAMENTO E IMAGINACAO
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI02EF01) Dialogar com crianças e adultos, expressando seus desejos, necessidades, sentimentos e opiniões.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI02EF02) Identificar e criar diferentes sons e reconhecer rimas e aliterações em cantigas de roda e textos poéticos.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI02EF03) Demonstrar interesse e atenção ao ouvir a leitura de histórias e outros textos, diferenciando escrita de ilustrações, e acompanhando, com orientação do adulto-leitor, a direção da leitura (de cima para baixo, da esquerda para a direita).',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI02EF04) Formular e responder perguntas sobre fatos da história narrada, identificando cenários, personagens e principais acontecimentos.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI02EF05) Relatar experiências e fatos acontecidos, histórias ouvidas, filmes ou peças teatrais assistidos etc.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI02EF06) Criar e contar histórias oralmente, com base em imagens ou temas sugeridos.',
          materia: { id: idMateria },
        },
        {
          ordem: 6,
          descricao: '(EI02EF07) Manusear diferentes portadores textuais, demonstrando reconhecer seus usos sociais.',
          materia: { id: idMateria },
        },
        {
          ordem: 7,
          descricao: '(EI02EF08) Manipular textos e participar de situações de escuta para ampliar seu contato com diferentes gêneros textuais (parlendas, histórias de aventura, tirinhas, cartazes de sala, cardápios, notícias etc.).',
          materia: { id: idMateria },
        },
        {
          ordem: 8,
          descricao: '(EI02EF09) Manusear diferentes instrumentos e suportes de escrita para desenhar, traçar letras e outros sinais gráficos.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //ESPACOS TEMPOS QUANTIDADES RELACOES E TRANSFORMACOES
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI02ET01) Explorar e descrever semelhanças e diferenças entre as características e propriedades dos objetos (textura, massa, tamanho).',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI02ET02) Observar, relatar e descrever incidentes do cotidiano e fenômenos naturais (luz solar, vento, chuva etc.).',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI02ET03) Compartilhar, com outras crianças, situações de cuidado de plantas e animais nos espaços da instituição e fora dela.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI02ET04) Identificar relações espaciais (dentro e fora, em cima, embaixo, acima, abaixo, entre e do lado) e temporais (antes, durante e depois).',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI02ET05) Classificar objetos, considerando determinado atributo (tamanho, peso, cor, forma etc.).',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI02ET06) Utilizar conceitos básicos de tempo (agora, antes, durante, depois, ontem, hoje, amanhã, lento, rápido, depressa, devagar).',
          materia: { id: idMateria },
        },
        {
          ordem: 6,
          descricao: '(EI02ET07) Contar oralmente objetos, pessoas, livros etc., em contextos diversos.',
          materia: { id: idMateria },
        },
        {
          ordem: 7,
          descricao: '(EI02ET08) Registrar com números a quantidade de crianças (meninas e meninos, presentes e ausentes) e a quantidade de objetos da mesma natureza (bonecas, bolas, livros etc.).',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //O EU O OUTRO E O NOS
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI02EO01) Demonstrar atitudes de cuidado e solidariedade na interação com crianças e adultos.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI02EO02) Demonstrar imagem positiva de si e confiança em sua capacidade para enfrentar dificuldades e desafios.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI02EO03) Compartilhar os objetos e os espaços com crianças da mesma faixa etária e adultos.',
          materia: { id: idMateria },
        },
        {
          ordem: 3,
          descricao: '(EI02EO04) Comunicar-se com os colegas e os adultos, buscando compreendê-los e fazendo-se compreender.',
          materia: { id: idMateria },
        },
        {
          ordem: 4,
          descricao: '(EI02EO05) Perceber que as pessoas têm características físicas diferentes, respeitando essas diferenças.',
          materia: { id: idMateria },
        },
        {
          ordem: 5,
          descricao: '(EI02EO06) Respeitar regras básicas de convívio social nas interações e brincadeiras.',
          materia: { id: idMateria },
        },
        {
          ordem: 6,
          descricao: '(EI02EO07) Resolver conflitos nas interações e brincadeiras, com a orientação de um adulto.',
          materia: { id: idMateria },
        },
      ],
    },
    {
      //TRACOS SONS CORES E FORMAS 
      competenciasEspecificas: {
        descricao: 'CONVIVER com outras crianças e adultos, em pequenos e grandes grupos, utilizando diferentes linguagens, ampliando o conhecimento de si e do outro, o respeito em relação à cultura e às diferenças entre as pessoas.\n\nBRINCAR de diversas formas, em diferentes espaços e tempos, com diferentes parceiros (crianças e adultos), de forma a ampliar e diversificar suas possibilidades de acesso a produções culturais. A participação e as transformações introduzidas pelas crianças nas brincadeiras devem ser valorizadas, tendo em vista o estímulo ao desenvolvimento de seus conhecimentos, sua imaginação, criatividade, experiências emocionais, corporais, sensoriais, expressivas, cognitivas, sociais e relacionais.\n\nPARTICIPAR ativamente, com adultos e outras crianças, tanto do planejamento da gestão da escola e das atividades propostas pelo educador quanto da realização das atividades da vida cotidiana, tais como a escolha das brincadeiras, dos materiais e dos ambientes, desenvolvendo diferentes linguagens e elaborando conhecimentos, decidindo e se posicionando.\n\nEXPLORAR movimentos, gestos, sons, formas, texturas, cores, palavras, emoções, transformações, relacionamentos, histórias, objetos, elementos da natureza, na escola e fora dela, ampliando seus saberes sobre a cultura, em suas diversas modalidades: as artes, a escrita, a ciência e a tecnologia.\n\nEXPRESSAR, como sujeito dialógico, criativo e sensível, suas necessidades, emoções, sentimentos, dúvidas, hipóteses, descobertas, opiniões, questionamentos, por meio de diferentes linguagens.\n\nCONHECER-SE e construir sua identidade pessoal, social e cultural, constituindo uma imagem positiva de si e de seus grupos de pertencimento, nas diversas experiências de cuidados, interações, brincadeiras e linguagens vivenciadas na instituição escolar e em seu contexto familiar e comunitário.',
        materia: { id: idMateria },
      },
      habilidades: [
        {
          ordem: 0,
          descricao: '(EI02TS01) Criar sons com materiais, objetos e instrumentos musicais, para acompanhar diversos ritmos de música.',
          materia: { id: idMateria },
        },
        {
          ordem: 1,
          descricao: '(EI02TS02) Utilizar materiais variados com possibilidades de manipulação (argila, massa de modelar), explorando cores, texturas, superfícies, planos, formas e volumes ao criar objetos tridimensionais.',
          materia: { id: idMateria },
        },
        {
          ordem: 2,
          descricao: '(EI02TS03) Utilizar diferentes fontes sonoras disponíveis no ambiente em brincadeiras cantadas, canções, músicas e melodias.',
          materia: { id: idMateria },
        },
      ],
    },
  ];
  return obj[curso];
}

export default getCompetenciasEspecificasHabiliadesCreche2;
