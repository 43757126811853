import React from 'react';
import _ from 'lodash';
import env from 'conf/env.json';

import {
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';

import Quill from 'views/components/Quill';

import TipoNoticia from 'assets/csv/tipos-noticia.json';

import Loader from 'react-loader-spinner';
import Select from 'react-select';
import Paginator from './Paginator';

class AdaptiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.content = this.content.bind(this);

    this.options = [
      { value: 5, label: '5 Linhas' },
      { value: 10, label: '10 Linhas' },
      { value: 20, label: '20 Linhas' },
      { value: 30, label: '30 Linhas' },
    ];

    const selectedOption = this.options.find((option) => option.value
      === parseInt(this.props.rowsPerPage, 10));

    this.state = {
      rowsPerPage: selectedOption || { value: 5, label: '5 Linhas' },
      data: this.props.data,
      disableActions: this.props.disableActions,
      disablePrintAction: this.props.disablePrintAction,
      disableEditAction: this.props.disableEditAction,
      disableRemoveAction: this.props.disableRemoveAction,
      printTooltip: this.props.printTooltip ? this.props.printTooltip : 'Imprimir',
      clickRows: this.props.clickRows,
    };
  }

  componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      this.props.likeCallback(text.trim());
    }, 1500);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps.data) {
      this.setState({ data: nextProps.data });
    }
    if (this.state.clickRows !== nextProps.clickRows) {
      this.setState({ clickRows: nextProps.clickRows });
    }
  }

  content() {
    if (this.state.data.total === undefined) {
      return (
        <div align="center" style={{ padding: 30 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      );
    } if (this.state.data.total === -1) {
      return (<div align="center" style={{ marginTop: 20, marginBottom: 20 }}>Falha ao obter dados</div>);
    } if (this.state.data.total === 0) {
      return (<div align="center" style={{ marginTop: 20, marginBottom: 20 }}>Nenhum dado cadastrado</div>);
    }
    return (
      <div className={'noticias'}>
        {
          this.state.data.rows.map(
            (row) => (
              <div key={row.id} className={'noticia'} >
                <Row>
                  <Col>
                    <div className={'titulo'}>
                      <p onClick={() => this.props.rowCallback(row)}>{row.titulo}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-left">
                    {
                      row.tipo === TipoNoticia.PUBLICA.value
                        ? <div className="social-icons">
                          <a className="whatsapp" target="_blank" alt="Compartilhar no WhatsApp" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?text=${row.titulo} | ${env.SYSNAME} - ${env.BASE_ADDRESS}/noticias/${row.cidade}/${row.id}`}>
                            <i className="fab fa-whatsapp fa-2x"></i>
                          </a>&nbsp;&nbsp;&nbsp;&nbsp;
                          <a className="facebook" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${env.BASE_ADDRESS}/noticias/${row.cidade}/${row.id}&src=sdkpreparse`}>
                            <i className="fab fa-facebook fa-2x"></i>
                          </a>&nbsp;&nbsp;&nbsp;&nbsp;
                          <a className="twitter" target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?url=${env.BASE_ADDRESS}/noticias/${row.cidade}/${row.id}`}>
                            <i className="fab fa-twitter fa-2x"></i>
                          </a>
                        </div> : <div className="social-icons">
                          <i className="fab fa-whatsapp fa-2x disabled"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                          <i className="fab fa-facebook fa-2x disabled"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                          <i className="fab fa-twitter fa-2x disabled"></i>
                        </div>
                    }
                  </Col>
                  <Col className="text-right">
                    <p className="data">{row.data}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Quill
                      noBorder
                      readOnly
                      value={row.conteudo} />
                  </Col>
                </Row>
                {
                  !this.props.disableButtons && <Row>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => { this.props.editButtonCallback(row); }}>
                        Editar
                      </Button>
                      <Button
                        color="danger"
                        type="button"
                        onClick={() => { this.props.removeButtonCallback(row); }}>
                        Excluir
                      </Button>&nbsp;&nbsp;&nbsp;
                    </Col>
                  </Row>
                }

              </div>
            ),
          )
        }
        <div className="col-md-4 offset-md-4" style={{ marginTop: 20 }}>
          <Paginator
            callback={this.props.paginatorCallback}
            selectedPage={this.props.selectedPage}
            rowsPerPage={this.state.rowsPerPage.value}
            total={this.state.data.total} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <Row>
          <Col md={8}>
            {
              !this.props.disableSearchField && <form>
                <FormGroup>
                  <InputGroup >
                    <Input placeholder="Buscar..." type="text" onChange={(event) => {
                      this.debouncedFunction(event.target.value);
                    }} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText >
                        <i className="nc-icon nc-zoom-split" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </form>
            }
          </Col>
          <Col md={{ offset: 1, size: 3 }}>
            <Select
              noOptionsMessage={() => 'Nenhuma entrada'}
              className="react-select primary"
              classNamePrefix="react-select"
              value={this.state.rowsPerPage}
              onChange={(event) => {
                this.setState({ rowsPerPage: event });
                this.props.rowsPerPageCallback({
                  rowsPerPage: event.value, total: this.state.data.total,
                });
              }}
              options={this.options}
              placeholder="Número de linhas"
            />
          </Col>
        </Row>
        {this.content()}
      </>
    );
  }
}

export default AdaptiveTable;
