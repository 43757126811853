import React from 'react';
import { connect } from 'react-redux';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoTurma from 'assets/csv/situacoes-turma.json';
import Turno from 'assets/csv/turnos.json';

import TurmasService from 'services/TurmasService';

import Papel from 'assets/csv/papeis.json';

class Turmas extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-`
      + `turmas-escola-turmas${this.props.match.params.idAnoLetivo}`
      + `-${this.props.match.params.idEscola}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.situacoesTurma = [
      SituacaoTurma.ABERTA,
      SituacaoTurma.CONSOLIDADA,
    ];

    const curso = this.props.dadosUsuario.ente !== 'Patos/PB' ? 'Curso' : 'Série';

    this.state = {
      turmaSelecionada: null,

      dadosTabela: {
        header: [['Nome', '20%'], [curso, '25%'], ['Turno', '25%'], ['Situação', '15%'], ['Matrículas', '10%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center', 'text-center'],
        keys: ['nome', 'serie_str', 'turno_str', 'situacao_str', 'total_matriculas'],
        rows: [],
        total: undefined,
      },
    };

    this.turmasService = new TurmasService();
  }

  componentWillUnmount() {
    this.turmasService.abortPedingRequests();
  }

  async componentDidMount() {
    this.carregarTurmas(this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  async carregarTurmas(pagina, linhasPorPagina, like) {
    try {
      const data = await this.turmasService
        .carregarTurmas(this.props.match.params.idAnoLetivo,
          this.props.match.params.idEscola, pagina, linhasPorPagina, like);

      const fn = (turma) => new Promise((resolve) => resolve(
        this.turmasService.carregarTotalMatriculasAtivas(turma.id),
      ));

      const actions = data.turmas.map(fn);

      const totalMatriculas = await Promise.all(actions);

      data.turmas.forEach((turma, idx) => {
        Object.assign(turma, {
          serie_str: turma.curso.nome,
          turno_str: this.turnos[turma.turno].label,
          situacao_str: this.situacoesTurma[turma.situacao].label,
          total_matriculas: totalMatriculas[idx],
        });
      });

      this.setState({
        dadosTabela: Object.assign(
          this.state.dadosTabela,
          { rows: data.turmas, total: data.total },
        ),
      });
    } catch (e) {
      if (this.umounted) return;

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar turmas cadastradas',
      });
    }
  }

  editarTurma(turma) {
    this.props.history.push(`${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turmas/edicao/${turma.id}`);
  }

  confirmarRemocaoTurma(turma) {
    this.setState({ turmaSelecionada: turma, showConfirmModal: true });
  }

  async removerTurma(senha) {
    this.setState({ showLoaderModal: true });

    try {
      await this.turmasService.removerTurma(this.state.turmaSelecionada.id, senha);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma removida com sucesso',
      });

      this.carregarTurmas(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (e) {
      if (this.umounted) return;

      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao remover turma',
      });
    }
  }

  render() {
    return (
      <>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          clickRows
          disablePrintAction
          disableActions={this.props.role !== Papel.GESTOR.value
            && this.props.role !== Papel.DIRETOR.value
            && this.props.role !== Papel.SECRETARIO.value
          }
          editButtonCallback={(turma) => this.editarTurma(turma)}
          removeButtonCallback={(turma) => this.confirmarRemocaoTurma(turma)}
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarTurmas(this.paginaSelecionada, info.rowsPerPage, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarTurmas(this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarTurmas(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          rowCallback={(turma) => {
            this.props.history.push({ pathname: `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.props.match.params.idEscola}/turma/${turma.id}` });
          }}
          data={this.state.dadosTabela} />
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.removerTurma(senha);
            } else {
              this.setState({ turmaSelecionada: null });
            }
          }}
          deletionInstruction='Confirme que você quer excluir a turma digitando a sua senha no campo abaixo'
          deletionText={''}
          deletionWarning='A exclusão de uma turma acarretará a exclusão de todos os seus diários e matrículas, e demais dados relacionados' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo...' />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Turmas);
