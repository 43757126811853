import React from 'react';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap';

import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';

class AmbienteVirtual extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
    };
  }

  componentDidMount() {
    this.loginService.abortPedingRequests();
  }

  conteudoPagina() {
    return <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem active>Ambiente Virtual</BreadcrumbItem>
        </Breadcrumb>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Ambiente virtual</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="ava">
              <Row>
                <Col className="text-center">
                  <Button
                    className="square_button"
                    color="primary"
                    onClick={() => this.props.history.push(`${this.props.layout}/ava/aulas`)}>
                      Aulas
                  </Button>
                  <Button
                    className="square_button"
                    color="primary"
                    onClick={() => this.props.history.push(`${this.props.layout}/ava/turmasvirtuais`)}>
                      Turmas<br/>Virtuais
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default AmbienteVirtual;
