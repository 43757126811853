import BaseService from './BaseService';

class EstruturasCurricularesService extends BaseService {
  carregarEstruturaCurricular(idEstruturaCurricular) {
    const requestInfo = {
      method: 'get',
      url: `/estruturacurricular/${idEstruturaCurricular}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarEstruturasCurriculares(idCurso, pagina, linhasPorPagina, like) {
    let url = `/estruturacurricular/${idCurso}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarEstruturaCurricular(estruturaCurricular) {
    const requestInfo = {
      method: 'post',
      url: '/estruturacurricular',
      data: JSON.stringify(estruturaCurricular),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarEstruturaCurricular(estruturaCurricular) {
    const requestInfo = {
      method: 'put',
      url: '/estruturacurricular',
      data: JSON.stringify(estruturaCurricular),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarEstruturaCurricularComTurmas(estruturaCurricular) {
    const requestInfo = {
      method: 'put',
      url: '/estruturacurricular/com/turmas',
      data: JSON.stringify(estruturaCurricular),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerEstruturaCurricular(idEstruturaCurricular) {
    const requestInfo = {
      method: 'delete',
      url: `/estruturacurricular/${idEstruturaCurricular}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default EstruturasCurricularesService;
