import BaseService from './BaseService';

class EducaCensoService extends BaseService {
  exportarDados(idAnoLetivo,idEscola) {
    const requestInfo = {
      method: 'get',
      url: `/educacenso/anoletivo/${idAnoLetivo}/escola/${idEscola}/exportar/dados`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default EducaCensoService;
