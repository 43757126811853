import BaseService from './BaseService';

class AnosLetivosService extends BaseService {
  carregarAnoLetivo(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAnosLetivos(pagina, linhasPorPagina, like) {
    let url = `/anoletivo/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarAnoLetivo(anoLetivo) {
    const requestInfo = {
      method: 'post',
      url: '/anoletivo',
      data: JSON.stringify(anoLetivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarAnoLetivo(anoLetivo) {
    const requestInfo = {
      method: 'put',
      url: '/anoletivo',
      data: JSON.stringify(anoLetivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerAnoLetivo(idAnoLetivo, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/anoletivo/${idAnoLetivo}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  consolidarAnoLetivo(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/consolidar/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  reabrirAnoLetivo(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/reabrir/${idAnoLetivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarArquivoAnoLetivo(idAnoLetivo, arquivo) {
    const url = `/anoletivo/${idAnoLetivo}/upload/arquivo`;
    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarArquivosAnoLetivo(idAnoLetivo, pagina, linhasPorPagina, like) {
    let url = `/anoletivo/${idAnoLetivo}/arquivos/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerArquivoAnoLetivo(idArquivo) {
    const requestInfo = {
      method: 'delete',
      url: `/anoletivo/arquivo/${idArquivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarArquivoAnoLetivoEscola(idAnoLetivo, idEscola, arquivo) {
    const url = `/anoletivo/${idAnoLetivo}/escola/${idEscola}/upload/arquivo`;
    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarArquivosAnoLetivoEscola(idAnoLetivo, idEscola, pagina, linhasPorPagina, like) {
    let url = `/anoletivo/${idAnoLetivo}/escola/${idEscola}/arquivos/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerArquivoAnoLetivoEscola(idArquivo) {
    const requestInfo = {
      method: 'delete',
      url: `/anoletivo/escola/arquivo/${idArquivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCargaHoraria(idCargaHoraria) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/cargahoraria/${idCargaHoraria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCargaHorariaPorMatricula(idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/cargahoraria/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarCargasHoraria(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/${idAnoLetivo}/cargashoraria`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarCargaHoraria(cargaHoraria) {
    const requestInfo = {
      method: 'post',
      url: '/anoletivo/cargahoraria',
      data: JSON.stringify(cargaHoraria),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarCargaHoraria(cargaHoraria) {
    const requestInfo = {
      method: 'put',
      url: '/anoletivo/cargahoraria',
      data: JSON.stringify(cargaHoraria),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerCargaHoraria(idCargaHoraria) {
    const requestInfo = {
      method: 'delete',
      url: `/anoletivo/cargahoraria/${idCargaHoraria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiasUteisAnoLetivo(idAnoLetivo) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/${idAnoLetivo}/diasuteis`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiasUteisAnoLetivoProfessor(idAnoLetivo, idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/anoletivo/${idAnoLetivo}/diasuteis/professor/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async carregarTotalAlunosAtivosPorAnoLetivoEEscola(idAnoLetivo, idEscola, like) {
    let url = `/anoletivo/${idAnoLetivo}/total/alunos/ativos`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAlunosAtivosPorAnoLetivoEEscola(idAnoLetivo, idEscola, pagina, linhasPorPagina, like) {
    let url = `/anoletivo/${idAnoLetivo}/alunos/ativos`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default AnosLetivosService;
