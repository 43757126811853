/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Button,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

class RelatoriosRegistrosAula extends React.Component {
  render = () => <div className="content">
    <Row>
      <Col md="12">
        <Breadcrumb>
          <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
          <BreadcrumbItem active>Relatórios de registros de aula</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle></CardTitle>
          </CardHeader>
          <CardBody>
            <PerfectScrollbar className="sisa-perfect-scrollbar">
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th>Relatórios de registros de aula</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/registrosaula/porescola`)}>
                  <td>Relatório de registros de aula por escola</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/registrosaula/porturma`)}>
                  <td>Relatório de registros de aula por turma</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/registrosaula/porprofessor`)}>
                  <td>Relatório de registros de aula por professor</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/quantidaderegistrosaula/porescola`)}>
                  <td>Relatório de quantidade de registros de aula por escola</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/quantidaderegistrosaula/porturma`)}>
                  <td>Relatório de quantidade de registros de aula por turma</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/quantidaderegistrosaula/porprofessor`)}>
                  <td>Relatório de quantidade de registros de aula por professor</td>
                </tr>
                <tr
                  className='tr-clickable'
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios/diariosregistroaulasematraso/porescola`)}>
                  <td>Relatório de diários com registros de aula em atraso</td>
                </tr>
              </tbody>
            </Table>
            </PerfectScrollbar>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="6" className="text-left">
                <Button
                  color="primary"
                  onClick={() => this.props.history.push(`${this.props.layout}/relatorios`)} >
                  Voltar
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </div>
}

export default RelatoriosRegistrosAula;
