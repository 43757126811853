function getListaMChatPadrao() {
  return [
    {
      ordem: 0,
      descricao: 'Se você apontar para qualquer coisa do outro lado da sala, a criança olha para o que está a apontar?',
      exemplo: 'Por exemplo: se você aponta para um brinquedo ou um animal, a criança olha para o brinquedo ou animal?'
    },
    {
      ordem: 1,
      descricao: 'Já alguma vez se interrogou se a sua criança poderia ser surda?',
      exemplo: '',
    },
    {
      ordem: 2,
      descricao: 'A sua criança brinca ou faz-de-conta?',
      exemplo: 'Por exemplo: fingindo que está a beber de um copo vazio, a falar ao telefone ou a fingir que dá a papa a uma boneca ou a um peluche?',
    },
    {
      ordem: 3,
      descricao: 'A sua criança gosta de trepar para as coisas?',
      exemplo: 'Por exemplo: mobília, escorregas no recreio ou escadas?',
    },
    {
      ordem: 4,
      descricao: 'A sua criança faz movimentos invulgares com os dedos perto dos olhos?',
      exemplo: 'Por exemplo, abana os dedos perto dos olhos?',
    },
    {
      ordem: 5,
      descricao: 'A sua criança aponta com um dedo para pedir algo ou para conseguir ajuda?',
      exemplo: 'Por exemplo: aponta para um alimento ou brinquedo que está fora do seu alcance?',
    },
    {
      ordem: 6,
      descricao: 'A sua criança aponta com o um dedo para mostrar-lhe algo interessante?',
      exemplo: 'Por exemplo: aponta para um avião no céu ou um caminhão grande na estrada?',
    },
    {
      ordem: 7,
      descricao: 'A sua criança está interessada noutras crianças?',
      exemplo: 'Por exemplo: a sua criança observa outras crianças, sorri para elas ou aproxima-se delas?',
    },
    {
      ordem: 8,
      descricao: 'A sua criança mostra-lhe coisas trazendo-as até si ou levantando-as para que as veja - não para obter ajuda, mas apenas para as partilhar?',
      exemplo: 'Por exemplo: mostrar uma flor, um peluche ou um camião de brincar?',
    },
    {
      ordem: 9,
      descricao: 'A sua criança responde quando a chama pelo nome?',
      exemplo: 'Por exemplo: olha, fala ou balbucia, ou para o que está a fazer quando a chama pelo seu nome?',
    },
    {
      ordem: 10,
      descricao: 'Quando sorri para a sua criança, ela sorri de volta para si?',
      exemplo: '',
    },
    {
      ordem: 11,
      descricao: 'A sua criança fica incomodada com os ruídos do quotidiano?',
      exemplo: 'Por exemplo: a sua criança grita ou chora para ruídos como aspirador ou música alta?',
    },
    {
      ordem: 12,
      descricao: 'A sua criança já anda?',
      exemplo: '',
    },
    {
      ordem: 13,
      descricao: 'A sua criança olha-o nos olhos quando você fala com ela, brinca com ela ou veste-a?',
      exemplo: '',
    },
    {
      ordem: 14,
      descricao: 'A sua criança tenta imitar aquilo que você faz?',
      exemplo: 'Por exemplo: acena adeus, bate palmas ou faz sons engraçados quando você os faz?',
    },
    {
      ordem: 15,
      descricao: 'Se você virar a sua cabeça para olhar para alguma coisa, a sua criança olha em volta para ver o que é que você está a olhar?',
      exemplo: '',
    },
    {
      ordem: 16,
      descricao: 'A sua criança tenta que você olhe para ela?',
      exemplo: 'Por exemplo: a sua criança olhar para si para um elogio ou diz-lhe "olha" ou "olha para mim"?',
    },
    {
      ordem: 17,
      descricao: 'A sua criança compreende quando você lhe diz para fazer alguma coisa?',
      exemplo: 'Por exemplo: se você não apontar, ela consegue compreender "põe o livro na cadeira" ou "traz-me o cobertor"?',
    },
    {
      ordem: 18,
      descricao: 'Quando acontece alguma coisa nova, a sua criança olha para a sua cara para ver a sua reação?',
      exemplo: 'Por exemplo: se ela ouve um ruído estranho ou engraçado, ou vê um brinquedo novo, ela olha para a sua cara?',
    },
    {
      ordem: 19,
      descricao: 'A sua criança gosta de atividades com movimento?',
      exemplo: 'Por exemplo: ser embalada ou balançada no seu joelho?',
    },
  ];
}

export default getListaMChatPadrao;